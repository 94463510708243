import { useState, useEffect, useMemo } from "react";
import styles from "./aauTeamsDropdown.module.scss";
import Header from "../../header/header";
import { FaSortAmountDownAlt } from "react-icons/fa";
import AddModal from "./addModal";
import EditModal from "./editModal";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { CustomSpinner } from "../../shared/Spinner";
import { MdModeEdit, MdRemoveRedEye, MdRestoreFromTrash } from "react-icons/md";
import DeleteModal from "../../shared/DeleteModal";
import DeleteCollectionModal from "../../shared/DeleteCollection";
import { Button, Select } from "antd";

const { Option } = Select;

export default function AauTeamsDropdown() {
  // Modal and loading state
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // Data states (raw data from Firestore)
  const [AAUTeamData, setAAUTeamsData] = useState(null);
  const [aauSuggestedTeams, setAAUSuggestedTeams] = useState([]);

  // Filter states (exact match)
  const [filterAffiliationValue, setFilterAffiliationValue] = useState("");

  // Modal and deletion states
  const [selectedDeleteSuggestedAAUTeam, setSelectedDeleteSuggestedAAUTeam] = useState(null);
  const [editModelToggle, setEditModelToggle] = useState(false);
  const [selectedDeleteAAUTeams, setSelectedDeleteAAUTeams] = useState(false);
  const [showDeleteCollectionModal, setShowDeleteCollectionModal] = useState(false);
  const [currentCollection, setCurrentCollection] = useState(null);

  // Compute the displayed AAU team data based on the Affiliation filter.
  // If no filter is applied, displayAAUTeamData equals AAUTeamData.
  const displayAAUTeamData = useMemo(() => {
    if (!AAUTeamData) return [];
    let data = [...AAUTeamData];
    if (filterAffiliationValue) {
      data = data.filter((item) => item.aauAffilation === filterAffiliationValue);
    }
    return data;
  }, [AAUTeamData, filterAffiliationValue]);

  // Compute unique options for Affiliation from the raw data.
  const affiliationOptions = useMemo(() => {
    if (!AAUTeamData) return [];
    const affiliations = AAUTeamData.map((item) => item.aauAffilation).filter(Boolean);
    const uniqueAffiliations = Array.from(new Set(affiliations));
    uniqueAffiliations.sort();
    return uniqueAffiliations;
  }, [AAUTeamData]);

  useEffect(() => {
    getAAUTeamsData();
    getAAUSuggestedTeams();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    db.collection("AAUTeam-dropdown")
      .doc(id)
      .delete()
      .then(() => {
        setLoading(false);
        toast.error("AAU Team Item Deleted Successfully", { theme: "colored" });
        getAAUTeamsData();
      })
      .catch((error) => {
        toast.error("Error Deleting AAU Team Item", { theme: "colored" });
      });
  };

  const handleDeleteTeam = (id) => {
    setLoading(true);
    db.collection("aau-suggestion")
      .doc(id)
      .delete()
      .then(() => {
        setLoading(false);
        toast.error("AAU Team Suggestion Deleted Successfully", { theme: "colored" });
        getAAUSuggestedTeams();
      })
      .catch((error) => {
        toast.error("Error Deleting AAU Team Suggestion", { theme: "colored" });
      });
  };

  const getAAUTeamsData = async () => {
    setLoading(true);
    try {
      const response = await db.collection("AAUTeam-dropdown").get();
      const data = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setAAUTeamsData(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const getAAUSuggestedTeams = async () => {
    setLoading(true);
    try {
      const response = await db.collection("aau-suggestion").get();
      const data = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setAAUSuggestedTeams(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  // Export the currently displayed (filtered) data to CSV.
  const exportData = () => {
    if (!displayAAUTeamData || displayAAUTeamData.length === 0) {
      toast.error("No data available for export.");
      return;
    }

    // Sort the filtered data alphabetically by AAU team name.
    const sortedData = [...displayAAUTeamData].sort((a, b) => {
      const aName = a.name || "";
      const bName = b.name || "";
      return aName.localeCompare(bName);
    });

    // Escape CSV field values
    const escapeCsvField = (value) => {
      if (value == null) return '';
      const stringValue = String(value);
      return `"${stringValue.replace(/"/g, '""')}"`;
    };

    const headers = ["AAU Name", "Affiliation", "Coach Name", "Phone", "Email"]
      .map(escapeCsvField)
      .join(',');

    const rows = sortedData.map((item) => {
      const aauName = item.name || '';
      const affiliation = item.aauAffilation || '';
      const coachName = [item.aauCoach, item.aauCoachLast].filter(Boolean).join(' ');
      const phone = item.aauCoachInfo || '';
      const email = item.aauCoachEmail || '';
      
      return [aauName, affiliation, coachName, phone, email]
        .map(escapeCsvField)
        .join(',');
    }).join('\n');

    const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'aauTeams.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (AAUTeamData === null && !loading) {
    return <div>Error loading AAUTeam data</div>;
  }

  // Sort the displayed data for rendering in the table
  const sortedAAUTeamData = [...displayAAUTeamData].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const sortedAAUSuggestedTeams = aauSuggestedTeams
    ? [...aauSuggestedTeams].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <div className={styles.title}>
          <h4>AAU Team</h4>
          <p>This section is used to edit the AAU Teams dropdown menu</p>
        </div>
        <hr />

        {sortedAAUSuggestedTeams.length > 0 && (
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div>
                <span>Total</span>
                <span>{aauSuggestedTeams ? aauSuggestedTeams.length : "0"}</span>
              </div>
            </div>
            <div className={styles.row}>
              <div>AAU Teams</div>
              <div>Affiliation</div>
              <div>CoachFN</div>
              <div>CoachLN</div>
              <div>Phone</div>
              <div>Email</div>
              <div>Actions</div>
            </div>
            {sortedAAUSuggestedTeams.map((item) => (
              <div key={item.id} className={styles.row}>
                <div>{item.name}</div>
                <div>{item.aauAffilation}</div>
                <div>{item.aauCoach}</div>
                <div>{item.aauCoachLast}</div>
                <div>{item.aauCoachInfo}</div>
                <div>{item.aauCoachEmail}</div>
                <div>
                  <MdModeEdit
                    onClick={() => {
                      setEditModelToggle(item);
                      setCurrentCollection("aau-suggestion");
                    }}
                    //style={{ cursor: "pointer", color: "black" }}
                  />
                  <MdRestoreFromTrash
                    onClick={() => {
                      setSelectedDeleteSuggestedAAUTeam(item.id);
                    }}
                   // style={{ cursor: "pointer", color: "black" }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div>
              <span>Total</span>
              <span>{AAUTeamData ? AAUTeamData.length : "0"}</span>
            </div>
            <div>
              <button
                style={{ backgroundColor: "black" }}
                onClick={exportData}
                className={styles.exportButton}
              >
                Export Data
              </button>
            </div>
            <button onClick={() => setOpenEditModal(true)}>Add Item</button>
          </div>
          {/* Table header row with a searchable, scrollable Select filter for Affiliation */}
          <div className={styles.row}>
            <div>AAU Teams</div>
            <div>
              <Select
                bordered={false}
                showSearch
                allowClear
                style={{ width: 150 }}
                placeholder="Affiliation"
                optionFilterProp="children"
                onChange={(value) => setFilterAffiliationValue(value || "")}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {affiliationOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
            <div>CoachFN</div>
            <div>CoachLN</div>
            <div>Phone</div>
            <div>Email</div>
            <div>Actions</div>
          </div>
          {sortedAAUTeamData.map((item) => (
            <div key={item.id} className={styles.row}>
              <div>{item.name}</div>
              <div>{item.aauAffilation}</div>
              <div>{item.aauCoach}</div>
              <div>{item.aauCoachLast}</div>
              <div>{item.aauCoachInfo}</div>
              <div>{item.aauCoachEmail}</div>
              <div>
                <MdModeEdit
                  onClick={() => {
                    setEditModelToggle(item);
                    setCurrentCollection("AAUTeam-dropdown");
                  }}
                 // style={{ cursor: "pointer", color: "black" }}
                />
                <MdRestoreFromTrash
                  onClick={() => {
                    setSelectedDeleteAAUTeams(item.id);
                  }}
                  // style={{ cursor: "pointer", color: "black" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Delete Modals */}
      {/* Uncomment or adjust DeleteCollectionModal if needed */}
      {/* <DeleteCollectionModal
        handleClose={() => setShowDeleteCollectionModal(false)}
        show={showDeleteCollectionModal}
        handleConfirm={deleteCollection}
        collectionName="AAUTeam-dropdown"
      /> */}

      <DeleteModal
        handleClose={() => setSelectedDeleteAAUTeams(false)}
        show={selectedDeleteAAUTeams}
        handleConfirm={() => handleDelete(selectedDeleteAAUTeams)}
        title="Delete Item"
        message="Are you sure you want to delete the item? This action cannot be undone."
      />

      <DeleteModal
        handleClose={() => setSelectedDeleteSuggestedAAUTeam(false)}
        show={selectedDeleteSuggestedAAUTeam}
        handleConfirm={() => handleDeleteTeam(selectedDeleteSuggestedAAUTeam)}
        title="Delete Suggested Item"
        message="Are you sure you want to delete the item? This action cannot be undone."
      />

      {openEditModal && (
        <AddModal open={openEditModal} setOpen={setOpenEditModal} reloadData={getAAUTeamsData} />
      )}
      {editModelToggle && (
        <EditModal
          data={editModelToggle}
          setOpen={setEditModelToggle}
          reloadData={() => {
            getAAUTeamsData();
            getAAUSuggestedTeams();
          }}
          collection={currentCollection}
        />
      )}
    </div>
  );
}
