import React, { useState, useEffect } from "react";
import { Col, Container, Row, Alert, Button } from "react-bootstrap";

// router
import { useParams, useHistory } from "react-router-dom";

// firebase
import { db } from "../../firebase";

import { CustomSpinner } from "../shared/Spinner";
import Header from "../header/header";

import { PreviewDetails } from "./preview-details";

export const PlayerPreviewComponents = () => {
  const history = useHistory();
  const [playerProfileData, setPlayerProfileData] = useState(null);
  const [bgOverviewData, setBgOverviewData] = useState(null);
  const [scoutingOverviewData, setScoutingOverviewData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [compareProfile, setCompareProfile] = useState(null);
  const [compareBgOverview, setCompareBgOverview] = useState(null);
  const [compareScoutingOverview, setCompareScoutingOverview] = useState(null);

  const { id, compareId } = useParams();

  useEffect(() => {
    if (id) {
      getPlayerProfile(id);
    }

    if (compareId) {
      getPlayerCompareProfile(compareId);
      console.log(compareId, "compareId");
    }

    return () => {};
  }, [id, compareId]); 


  const getPlayerCompareProfile = async () => {
    setLoading(true);
    try {
      const response = await db
        .collection("player-profile")
        .doc(compareId)
        .get();
      const data = response.data();
      setCompareProfile(data);

      const bgDoc = await db
        .collection("background-overview")
        .where("playerProfile", "==", compareId)
        .get();
      if (bgDoc.docs.length > 0) setCompareBgOverview(bgDoc.docs[0].data());
      const scoutingDoc = await db
        .collection("scouting-overview")
        .where("playerProfile", "==", compareId)
        .get();
      if (scoutingDoc.docs.length > 0)
        setCompareScoutingOverview(
          getScoutingOverviewDetails(scoutingDoc.docs[0].data())
        );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getPlayerProfile = async () => {
    setLoading(true);
    try {
      const response = await db.collection("player-profile").doc(id).get();
      const data = response.data();
      setPlayerProfileData(data);
      const bgDoc = await db
        .collection("background-overview")
        .where("playerProfile", "==", id)
        .get();
      if (bgDoc.docs.length > 0) setBgOverviewData(bgDoc.docs[0].data());
      const scoutingDoc = await db
        .collection("scouting-overview")
        .where("playerProfile", "==", id)
        .get();
      if (scoutingDoc.docs.length > 0)
        setScoutingOverviewData(
          getScoutingOverviewDetails(scoutingDoc.docs[0].data())
        );
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CustomSpinner />;
  }
  if (playerProfileData === null && loading === false) {
    return <div>Loading Data</div>;
  }

  return (
    <>
      <Header />

      <Alert show={compareProfile} variant="danger">
        <div className="w-100 d-flex justify-content-between">
          <p>Close Compare window.</p>
          <Button
            onClick={() => {
              history.push(`/player-profile/preview/${id}`);
              window.location.reload();
            }}
            variant="outline-danger"
          >
            X
          </Button>
        </div>
      </Alert>
      <Container fluid>
        <Row>
          <Col md={compareProfile ? 6 : 12} className="p-0">
            <PreviewDetails
              playerProfileData={playerProfileData}
              bgOverviewData={bgOverviewData}
              scoutingOverviewData={scoutingOverviewData}
              id={id}
            />
          </Col>
          {compareProfile && (
            <Col md={6} className="p-0">
              <PreviewDetails
                compare
                playerProfileData={compareProfile}
                bgOverviewData={compareBgOverview}
                scoutingOverviewData={compareScoutingOverview}
                id={compareId}
              />
            </Col>
          )}{" "}
        </Row>
      </Container>
    </>
  );
};

const getScoutingOverviewDetails = (scoutingOverviewData) => {
  let data = {
    strengths: {
      Strengths: {
        list: scoutingOverviewData.strengths,
      },
    },
    projections: {
      Projections: {
        title: "Projections",
        list: [],
      },
    },
    weaknesses: {
      Weaknesses: {
        list: scoutingOverviewData.weaknesses,
      },
    },
    questions: {
      Questions: {
        list: scoutingOverviewData.questions,
      },
    },
    roles: {
      Roles: {
        title: "Roles",
        list: scoutingOverviewData.roles,
      },
    },
    bottomLine: scoutingOverviewData.bottomLine,
  };
  scoutingOverviewData.collegeLevel !== "" &&
    data.projections.Projections.list.push(
      `College Level: ${scoutingOverviewData.collegeLevel}`
    );
  scoutingOverviewData.collegeCeiling !== "" &&
    data.projections.Projections.list.push(
      `College Ceiling: ${scoutingOverviewData.collegeCeiling}`
    );
  scoutingOverviewData.nbaDraft !== "" &&
    data.projections.Projections.list.push(
      `NBA Draft: ${scoutingOverviewData.nbaDraft}`
    );
  scoutingOverviewData.nbaCeiling !== "" &&
    data.projections.Projections.list.push(
      `NBA Ceiling: ${scoutingOverviewData.nbaCeiling}`
    );

  return data;
};
