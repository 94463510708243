import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {cloneDeep} from 'lodash';
import { CSVLink } from "react-csv";
import csvtojson from "csvtojson";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
//import { Toast } from "primereact/toast";

// components
import { TeamHistories } from "./background-overview/TeamHistory";
import { NationalTeamExperience } from "./background-overview/NationalTeamExperience";
import { Awards } from "./background-overview/Awards";
import { Recruitment } from "./background-overview/Recruitment";
import { Personal } from "./background-overview/Personal";
import { SocialMedia } from "./background-overview/SocialMedia";
import { BuyOutInfo } from "./background-overview/BuyOutInfo";
import { IBOTeamHistories } from "./background-overview/IBOTeamHistory";
import Preview from "./background-overview/Preview";

// modal
import DeleteModal from '../shared/DeleteModal'
// react icons
import { BsArrowRightCircle } from 'react-icons/bs'

// React Autocomplete
import { AutoComplete } from 'primereact/autocomplete';

// css for removing focus blue glow
import './index.css'

// react bootstrap
import Form from 'react-bootstrap/Form';
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { Button as PButton } from "primereact/button";

// components
import styles from "./Form.module.scss";

// header
import Header from "../header/header";

// forms
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
//  toast
import { toast } from "react-toastify";

// firebase
import { db } from "../../firebase";
import log_activity from "../activity/logger";
import { useAuth } from "../../Context/AuthContext";

const teamHistoryFields = { year: "", school: "", state: "", coachFirstName: "", coachLastName: "", phone: "", email: "" };
const IBOteamHistoryFields = { year: "", team: "", country: "", coachFirstName: "", coachLastName: "", phone: "", email: "" };

export default function BackgroundOverviewForm({formData, bgFormID, closeEditModal}) {
  const [loading, setLoading] = useState(false);
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState(null);
  const playerProfileNames = useRef([]);
  const [years, setYears] = useState([]);
  const [rangeYears, setRangeYears] = useState([]);
  const [typeConfirm, setTypeConfirm] = useState(false);
  const [update, setUpdate] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  
  const [csvReport, setCsvReport] = useState(null);
  const { isSuperAdmin } = useAuth();
  const selectCSV = useRef(null);

  const { userInfo } = useAuth();

  const getRangeYears = async () => {
    try {
      const response = await db.collection("year-range-dropdown").get();
      const data = response.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
 
      data.sort((a, b) => a.order - b.order);

      const dropdownRangeValues = data.map(item => item.name);
     
      setRangeYears(dropdownRangeValues);
    } catch (error) {
      console.error("Error fetching dropdown values:", error);
    }
  };
  useEffect(() => {
    getRangeYears();
  }, [])


  const getYears = async () => {
    try {
      const response = await db.collection("year-dropdown").get();
      const data = response.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
    
      data.sort((a, b) => a.order - b.order);
      
      const dropdownValues = data.map(item => item.name);
      
      setYears(dropdownValues);
    } catch (error) {
      console.error("Error fetching dropdown values:", error);
    }
  };
  
  useEffect(() => {
    getYears();
  }, []);
  
  
  useEffect(() => {
    getExportBackgroundOverviewData();
    db.collection("player-profile").get().then((allDocs) => {
      const allPlayerProfiles = [];
      allDocs.forEach(doc => {
        allPlayerProfiles.push({ fullName: `${doc.data().FirstName} ${doc.data().LastName}`, id: doc.id, dob: doc.data().DOB });
        playerProfileNames.current.push(`${doc.data().FirstName} ${doc.data().LastName}`);
      });
      setPlayerProfiles(allPlayerProfiles);
      // getRolesData();
    });
  }, [])
  const searchProfile = (event) => {
    let _filteredProfiles = [...playerProfiles];
    if (event.query.trim().length > 0) {
      _filteredProfiles = _filteredProfiles.filter((p) => {
        return p.fullName.toLowerCase().includes(event.query.toLowerCase());
      });
    }
    setFilteredProfiles(_filteredProfiles);
  }
  const UpdateDoc = (values, resetForm) => {
    db.collection("background-overview").doc(update)
      .update({
        ...values,
        playerProfile: values.playerProfile.id,
        cheatSheet: {
          nuggets: values.cheatSheet.nuggets.trim(), // Store as string
          playerContact: values.cheatSheet.playerContact,
        },
      })
      .then((docRef) => {
        console.log("Document successfully written!");
        if(formData) closeEditModal();
        setUpdate(false);
        resetForm({});
        toast.info("Background Overview Updated Successfully", {
          theme: "colored",
        });
        log_activity(
          values.playerProfile.fullName, 
          "Background Overview Was Edited",
          userInfo.firstName + " " + userInfo.lastName)
      })
      .catch((error) => {
        toast.error("Error Updating Background Overview", {
          theme: "colored",
        });
        console.error("Error writing document: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const CreateDoc = (values, resetForm) => {

    db.collection("background-overview")
      .add({
        ...values,
        playerProfile: values.playerProfile.id,
        cheatSheet: {
          nuggets: values.cheatSheet.nuggets.trim(), // Store as string
          playerContact: values.cheatSheet.playerContact,
        },
      })
      .then((docRef) => {
        console.log("Document successfully written!");
        resetForm({});
        toast.info("Background Overview Created Successfully", {
          theme: "colored",
        });
        log_activity(
          values.playerProfile.fullName, 
          "New Background Overview Added",
          userInfo.firstName + " " + userInfo.lastName
          )
      })
      .catch((error) => {
        toast.error("Error Creating Background Overview", {
          theme: "colored",
        });
        console.error("Error writing document: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const formik = useFormik({
    initialValues: {
      playerProfile: "",
      IBO: false,
      teamHistory: [{ year: "", school: "", state: "", coachFirstName: "", coachLastName: "", phone: "", email: "" }],
      nationalTeamExperience: {
        country: "",
        experiences: [{ year: "", experience: "" }]
      },
      awards: [{ year: "", awards: "" },],
      recruitment: {
        commitment: "",
        month: "",
        year: "",
        notableVisits: [""],
        notableOffers: [""],
        notableInterests: [""],
        notes: "",
      },
      buyOutInfo: { terms: [""] },
      personal: {
        nuggets: [""],
        familyCircleMembers: [{ member: "", note: "" },]
      },
      cheatSheet: {
        nuggets: "", // Always initialized as an empty string
        playerContact: { phone: "", email: "" }, // Always initialized
      },
      socialMedia: {
        twitter: { account: "", dms: "", nuggets: [""] },
        instagram: { account: "", nuggets: [""] },
      },
      drafted: true,
      additionalNotes: "",
      createdAt: moment().unix(),
    },
    validationSchema: validationSchema(playerProfileNames),
    onSubmit: (values, { resetForm }) => {
      let _values = removeEmptyFields(values);
      // console.log(values, "values");
      // return;
      setLoading(true);
      update ? UpdateDoc(_values, resetForm) : CreateDoc(_values, resetForm);
    },
  });

  const check = (data, compareObject) =>{
    if(compareObject){
      data = data.filter(item=>JSON.stringify(item) !== JSON.stringify(compareObject))
    }
    else{
      data = data.filter(item => item !== "")
    }
    return data;
  }
  const removeEmptyFields = (data) => {
    let values = cloneDeep(data)
    values.teamHistory = values.teamHistory.filter(th => JSON.stringify(th) !== JSON.stringify(teamHistoryFields) && JSON.stringify(th) !== JSON.stringify(IBOteamHistoryFields))
    values.awards = check(values.awards, { year: "", awards: "" })
    values.nationalTeamExperience.experiences = check(values.nationalTeamExperience.experiences, { year: "", experience: "" })
    values.buyOutInfo.terms = check(values.buyOutInfo.terms)
    values.personal.familyCircleMembers = check(values.personal.familyCircleMembers, { member: "", note: "" })
    values.personal.nuggets =check( values.personal.nuggets)
    values.socialMedia.twitter.nuggets = check(values.socialMedia.twitter.nuggets)
    values.socialMedia.instagram.nuggets =check( values.socialMedia.instagram.nuggets)
    values.recruitment.notableOffers = check(values.recruitment.notableOffers)
    values.recruitment.notableInterests = check(values.recruitment.notableInterests)
    values.recruitment.notableVisits = check(values.recruitment.notableVisits)
    
    return values;
  }
  const itemTemplate = (item) => {
    return (
      <div>
        <b>{item.fullName}</b><br />
        <code>DOB: {moment(item.dob).format('MM/DD/YYYY')}</code>
      </div>
    );
  }


    //
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
  
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const csv = e.target.result;
          const jsonArray = await csvtojson().fromString(csv);
          console.log(jsonArray, "jsonArray");
          
          confirm1(jsonArray);
        };
        reader.readAsText(file);
      }
    };
    const confirm1 = (payload) => {
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        // handle acccept
        accept: () => {
          handleAcceptFileUpload(payload);
        },
  
        reject,
      });
    };
    const confirm2 = () => {
      console.log("Confirmation dialog opened");
      confirmDialog({
        message: "Do you want to delete this record?",
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept: () => {
          handleDeleteAllData();
        },
        reject,
      });
    };
    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "Rejected",
        detail: "You have rejected",
        life: 3000,
      });
    };
  
    const handleDeleteAllData = async () => {
      console.log("try to delete");
      let response = await db.collection("background-overview").get();
      console.log(response.docs);
      let data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      let response2 = data.map(async (item) => {
        let response = await db.collection("background-overview").doc(item.id).delete();
        return response;
      });
      response2 = await Promise.all(response2);
      if (response2.length > 0) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Reports Deleted Successfully",
          life: 3000,
        });
        // getPlayerProfiles();
        getExportBackgroundOverviewData();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    };
    const handleAcceptFileUpload = async (payload) => {
      // upload all the data Array multiple objects to firebase
      let response = payload.map(async (item) => {
        let backgroundRef = db.collection("background-overview").doc();
  
        let backgroundOBJ = {
          title: item.Title,
          playerProfile: item.playerProfile,
          teamHistory: [
            { year: item.thYear1, school: item.thSchool1, state: item.thState1, coachFirstName: item.thCoach, coachLastName: item.thCoachLast1, phone: item.thCoachInfo, email: item.coachEmail1 },
            { year: item.thYear2, school: item.thSchool2, state: item.thState2, coachFirstName: item.thCoach2, coachLastName: item.thCoachLast2, phone: item.thCoachInfo2, email: item.coachEmail2 },
            { year: item.thYear3, school: item.thSchool3, state: item.thState3, coachFirstName: item.thCoach3, coachLastName: item.thCoachLast3, phone: item.thCoachInfo3, email: item.coachEmail3 },
            { year: item.thYear4, school: item.thSchool4, state: item.thState4, coachFirstName: item.thCoach4, coachLastName: item.thCoachLast4, phone: item.thCoachInfo4, email: item.coachEmail4 }
          ].filter(obj => Object.values(obj).some(val => val !== "")),
          nationalTeamExperience: {
            country: item.nationalCountry,
            experiences: [
              { year: item.nationalYear1, experience: item.nationalTeam1 },
              { year: item.nationalYear2, experience: item.nationalTeam2 },
              { year: item.nationalYear3, experience: item.nationalTeam3 },
              { year: item.nationalYear4, experience: item.nationalTeam4 },
            ].filter(obj2 => Object.values(obj2).some(val2 => val2 !== ""))
          },
          awards: [
            { year: item.awardYear1, awards: item.awardNote1},
            { year: item.awardYear2, awards: item.awardNote2},
            { year: item.awardYear3, awards: item.awardNote3},
            { year: item.awardYear4, awards: item.awardNote4},
          ].filter(obj3 => Object.values(obj3).some(val3 => val3 !== "")),
          recruitment: {
            commitment: item.commit,
            month: item.commitMonth,
            year: item.commitYear,
            notableVisits: [
              item.visit1,
              item.visit2,
              item.visit3,
              item.visit4,
              item.visit5,     
            ].filter((v) => !!v),
            notableOffers: [
              item.offer1,
              item.offer2,
              item.offer3,
              item.offer4,
              item.offer5,
            ].filter((o) => !!o),
            notableInterests: [
              item.interest1,
              item.interest2,
              item.interest3,
              item.interest4,
              item.interest5,
            ].filter((n) => !!n),
            notes: item.recruitmentNote,
          },
          personal: {
            familyCircleMembers: [
              { member: item.fam1, note: item.famNote1 },
              { member: item.fam2, note: item.famNote2 },
              { member: item.fam3, note: item.famNote3 },
              { member: item.fam4, note: item.famNote4 },
              { member: item.fam5, note: item.famNote5 },
              { member: item.fam6, note: item.famNote6 },
            ].filter(obj4 => Object.values(obj4).some(val4 => val4 !== "")),
            nuggets: [
              item.nugget1,
              item.nugget2,
              item.nugget3,
              item.nugget4,
              item.nugget5,
              item.nugget6,
              item.nugget7,
              item.nugget8,
              item.nugget9,
              item.nugget10,
            ].filter((u) => !!u),
          },
          socialMedia: {
            twitter: { 
              account: item.twPrivacy, 
              dms: item.twDM, 
              nuggets: [
                item.twNugget1,
                item.twNugget2,
              ].filter((tw) => !!tw), 
            },
            instagram: { 
              account: item.igPrivacy, 
              nuggets: [
                item.igNugget,
                item.igNugget2,
              ].filter((ig) => !!ig),
            },
          },
          IBO: item.IBO.toUpperCase() === "TRUE",
          buyOutInfo: { 
            terms: [
              {terms: item.buyOutTerm1},
              {terms: item.buyOutTerm2},
              {terms: item.buyOutTerm3},
              {terms: item.buyOutTerm4},
            ].filter(obj5 => Object.values(obj5).some(val5 => val5 !== "")) 
          },
          additionalNotes: item.playerNote,
          createdAt: moment().unix(),
          drafted: false,
          lastEdited: item.editor,

        };
        let response = await backgroundRef.set(backgroundOBJ);
        return response;
      });
  
      response = await Promise.all(response);
  
      if (response.length > 0) {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Background Overviews Added Successfully",
            life: 3000,

        });
        console.log("File import successful!");
        // getPlayerProfiles();
        getExportBackgroundOverviewData();
    } else {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
        });
    }
};

    
const getExportBackgroundOverviewData = async () => {
  try {
    // Fetch background overview data
    const response = await db.collection("background-overview").get();
    const data = response.docs.map((doc) => {
      const backgroundOverview = doc.data();

      // Flatten team history data into a single object
      const flattenedTeamHistory = backgroundOverview.teamHistory.map((historyItem) => ({
        id: doc.id,
        playerProfile: backgroundOverview.playerProfile || "",
        IBO: backgroundOverview.IBO || false,
        additionalNotes: backgroundOverview.additionalNotes || "",
        year: historyItem.year || "",
        school: historyItem.school || "",
        state: historyItem.state || "",
        coachFirstName: historyItem.coachFirstName || "",
        coachLastName: historyItem.coachLastName || "",
        phone: historyItem.phone || "",
        email: historyItem.email || ""
      }));

      return flattenedTeamHistory;
    }).flat();

    // Define headers for the CSV file
    const headers = [
      { label: "ID", key: "id" },
      { label: "title", key: "title" },
      { label: "Player Profile", key: "playerProfile" },
     // { label: "IBO", key: "IBO" },
      { label: "Additional Notes", key: "additionalNotes" },
      
     // { label: "Year", key: "year" },
      //{ label: "School", key: "school" },
     // { label: "State", key: "state" },
      //{ label: "Coach First Name", key: "coachFirstName" },
      //{ label: "Coach Last Name", key: "coachLastName" },
      //{ label: "Phone", key: "phone" },
      //{ label: "Email", key: "email" }
      // Add more headers if needed
    ];

    const csvReport = {
      data: data,
      headers: headers,
      filename: `background-overview-${new Date().toDateString()}-${new Date().toTimeString()}.csv`,
    };

    setCsvReport(csvReport);
  } catch (error) {
    console.log(error, "error");
  }
};


    //

    // for editing on preview page
    useEffect(() => {
      if (formData && playerProfiles.length > 0) {
        // make sure teamHistory has at least one entry when loading existing data
        const initialTeamHistory = formData.teamHistory.length > 0 
          ? formData.teamHistory 
          : [formData.IBO ? {...IBOteamHistoryFields} : {...teamHistoryFields}];
    
        formik.setValues({
          ...formData,
          teamHistory: initialTeamHistory, // Add default entry if empty
          playerProfile: playerProfiles.find((p) => p.id === formData.playerProfile) || "",
          cheatSheet: {
            nuggets: formData.cheatSheet?.nuggets ?? "",
            playerContact: {
              phone: formData.cheatSheet?.playerContact?.phone ?? "",
              email: formData.cheatSheet?.playerContact?.email ?? "",
            },
          },
        });
        setUpdate(bgFormID);
      }
    }, [formData, playerProfiles, bgFormID]);
    
    
    
    
    


  return (
    <div>
      <ConfirmDialog />
      {(formData === null || formData === undefined) && <Header /> }
      {/* ------------- BACKGROUND OVERVIEW FORM BEGINS -------------------*/}

      <div className={styles.container}>
        <div className={styles.title}>
          <div className="w-100">
            {previewOpen && <Preview open={previewOpen} setOpen={setPreviewOpen} bgData={removeEmptyFields({...formik.values})} />}
            {/* confirmation dialog before changing type */}
            <DeleteModal
              show={typeConfirm}
              handleClose={() => { setTypeConfirm(false) }}
              handleConfirm={() => {
                formik.setFieldValue("IBO", typeConfirm === "IBO");
                formik.setFieldValue("teamHistory", typeConfirm === "IBO" ? [{ ...IBOteamHistoryFields }] : [{ ...teamHistoryFields }])
                setTypeConfirm(false);
              }}
              label={`Are you sure you want to change the type ? Some of the previous data will be lost`}
              title="Confirmation"
              variant="warning"
            />
            <Row>
              <Col md={6}>
                <h4>{formik.values.IBO ? "INT'L Background Overview" : "Background Overview"}</h4>
              </Col>
              <Col md={6} >
                <div className="py-2 d-flex justify-content-md-end">
                  <Form.Check
                    className={styles.switch}
                    type="switch"
                    id="switch"
                    label="INT'L Overview"
                    style={{ fontWeight: "bold", }}
                    onChange={(e) => {
                      setTypeConfirm(e.target.checked ? "IBO" : "BO")
                    }}
                    checked={formik.values.IBO}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </Col>
            </Row>
            <subtitle>
              This section is used to create {formik.values.IBO && "INT’L"} background overview. Please fill as many sections as possible.{" "}
            </subtitle>
            
            {/* {isSuperAdmin() && (
            <div>
              {/* <Button
              label="Update Age from DOB"
              onClick={handleUpdateAgefromDOB}
              ></Button> */}
              {/* <input
                type="file"
                ref={selectCSV}
                // only accept .csv files
                accept=".csv"
                style={{
                  display: "none",
                }}
                onChange={handleFileUpload}
              />
              <PButton
                onClick={() => selectCSV.current.click()}
                icon="pi pi-check"
                label="Import"
                severity="success"
                // style={{
                //   backgroundColor: "green",
                //   borderColor: "green",
                // }}
                size="small"
              ></PButton>
              {csvReport && (
                <CSVLink {...csvReport}>
                  <PButton
                    size="small"
                    icon="pi pi-times"
                    label="Export"
                    severity="danger"
                    style={{
                      marginLeft: "10px",
                    }}
                  ></PButton>
                </CSVLink>
              )}{" "}
              <PButton
                onClick={confirm2}
                icon="pi pi-times"
                label="Delete"
                severity="danger"
                style={{
                  marginLeft: "10px",
                  backgroundColor: "red",
                  borderColor: "red",
                }}
                size="small"
              ></PButton>
            </div>
            )} */} 

          </div>


        </div>
        <Row>
          <Col md={6}>
            <div className={styles.field}>
              <p>Player Profile</p>
              <AutoComplete
                disabled={formData !== undefined && formData !== null}
                className={styles.fullWidth}
                value={formik.values.playerProfile}
                suggestions={filteredProfiles}
                completeMethod={searchProfile}
                field="fullName"
                itemTemplate={itemTemplate}
                name="playerProfile"
                dropdown
                onChange={formik.handleChange}
                onSelect={(e) => {
                  db.collection("background-overview")
                    .where("playerProfile", "==", e.value.id).get()
                    .then(querySnap => {
                      if (querySnap.docs.length === 0) {
                        setUpdate(false);
                        formik.setValues({ ...formik.initialValues, playerProfile: e.value })
                        return;
                      };
                      formik.setValues({ ...querySnap.docs[0].data(), playerProfile: e.value });
                      setUpdate(querySnap.docs[0].id);
                    })
                }}
                onBlur={formik.handleBlur} />

              {formik.errors.playerProfile
                && formik.touched.playerProfile
                ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.playerProfile + " "}
                    {formik.errors.playerProfile === "This player profile doesn't exist" &&
                      <Link className={styles.createProfLink} to="/player-profile/create" clas>
                        Create Player Profile <BsArrowRightCircle />
                      </Link>}
                  </div>
                ) : null}
            </div>
          </Col>
        </Row>

      </div>
      {
        formik.values.IBO ?
          <IBOTeamHistories
            setFieldValue={formik.setFieldValue}
            formikTeamHistory={formik.values.teamHistory}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            years={rangeYears}
          />
          :
          <TeamHistories
            setFieldValue={formik.setFieldValue}
            formikTeamHistory={formik.values.teamHistory}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            years={rangeYears}
          />

      }

      <NationalTeamExperience
        setFieldValue={formik.setFieldValue}
        formikNationalTeamExperience={formik.values.nationalTeamExperience}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        years={years}
      />
      <Awards
        setFieldValue={formik.setFieldValue}
        awards={formik.values.awards}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        years={rangeYears}
      />
      {
        formik.values.IBO ?
          <BuyOutInfo
            setFieldValue={formik.setFieldValue}
            buyOutInfo={formik.values.buyOutInfo}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
          :
          <Recruitment
            setFieldValue={formik.setFieldValue}
            recruitment={formik.values.recruitment}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            years={years}
          />

      }

      <Personal
        setFieldValue={formik.setFieldValue}
        personal={formik.values.personal}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
      />

      <SocialMedia
        setFieldValue={formik.setFieldValue}
        socialMedia={formik.values.socialMedia}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
      />

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Notes</h4>
            <subtitle>
              This section is used to add any additional notes.
            </subtitle>
          </div>
        </div>
        <div className={styles.field}>
          <textarea name="additionalNotes" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.additionalNotes} rows={6}></textarea>
        </div>
      </div>


      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Cheat Sheet Data Only!</h4>
            <subtitle>
              This section is used to add any Nuggets and Player Contact.
            </subtitle>
          </div>
        </div>

      {/* Nuggets Input */}
        <div className={styles.field}>
          <p>Nuggets</p>
          <textarea
            name="cheatSheet.nuggets"
            placeholder="Enter nuggets here..."
            value={formik.values.cheatSheet?.nuggets ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={styles.textarea}
            rows={5} // Adjust rows based on preference
          />
        </div>

        {/* Player Contact Input */}
        <div className={styles.field}>
          <p>Player Contact</p>
          <Row>
            <Col md={6}>
              <input
                type="text"
                name="cheatSheet.playerContact.phone"
                placeholder="Please format like this: +1 (123) 456-7890"
                value={formik.values.cheatSheet?.playerContact?.phone ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.input}
              />
            </Col>
            <Col md={6}>
              <input
                type="email"
                name="cheatSheet.playerContact.email"
                placeholder="Email"
                value={formik.values.cheatSheet?.playerContact?.email ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.input}
              />
            </Col>
          </Row>
        </div>


      </div>



      <div className={styles.buttonContainer}>
        <Row>
          {/*
          <Col md={4}>
            <Button className={styles.secondary}
              onClick={() => setPreviewOpen(true)} >Preview</Button>
          </Col>
          
          <Col md={4}>
            <Button
              disabled={loading}
              className={styles.secondary}
              onClick={() => {
                formik.setFieldValue("drafted", true);
                formik.handleSubmit();
              }}
            >
              {update ? "Update Draft" : "Save Draft"}
            </Button>
          </Col>
           */}
          <Col md={4}>
            <Button
              disabled={loading}
              type="submit"
              onClick={() => {
                formik.setFieldValue("drafted", false);
                formik.handleSubmit();
              }}
            >
              {loading ? <Spinner animation="border" /> : update ? "Update" : "Publish"}
            </Button>

           

          </Col>
          
        </Row>
      </div>
      {/* ------------- BACKGROUND OVERVIEW ENDS -------------------*/}
    </div>
  );
}



const validationSchema = (allPlayerProfileNames) => {
  return Yup.object({}).shape({
    playerProfile: Yup.lazy((value) => {
      if (value === undefined) {
        return Yup.string().required("Required");
      }
      else if (value.constructor === String) {
        return Yup.string().required("Required").oneOf(allPlayerProfileNames.current, "This player profile doesn't exist")
          .test(
            "ExistingProfile",
            `Profile/s with this name exists. Please Select`,
            async (value2) => await allPlayerProfileNames.current.filter(p => p.fullName === value2).length > 0);
      }
      else {
        return Yup.object().required("Required");
      }
    }),
  })
}