import React, { useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styles from "./CheatSheetPage.module.scss";
import Header from "../../header/header";
import { db } from "../../../firebase";
import { CustomSpinner } from "../../shared/Spinner";
import { CSVLink } from "react-csv";
import { FiDownload, FiX, FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";

const DraggableRow = ({ player, background, index, moveRow, removePlayer }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "ROW",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // In DraggableRow component
console.log('Background data for', player.id, ':', background);

  const [, drop] = useDrop({
    accept: "ROW",
    hover: (draggedItem, monitor) => {
      if (draggedItem.index === index) return;

      const hoverBoundingRect = document
        .querySelector(`[data-row-id="${player.id}"]`)
        ?.getBoundingClientRect();
      if (!hoverBoundingRect) return;

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (draggedItem.index < index && hoverClientY < hoverMiddleY) return;
      if (draggedItem.index > index && hoverClientY > hoverMiddleY) return;

      moveRow(draggedItem.index, index);
      draggedItem.index = index;
    },
    
  });

  return (
    <tr
      ref={(node) => drag(drop(node))}
      className={`${styles.draggableRow} ${isDragging ? styles.dragging : ""}`}
      data-row-id={player.id}
    >
      <td>{player.FirstName} {player.LastName}</td>
      <td>{player.Position || "--"}</td>
      <td>{player.Year || "--"}</td>
      <td>{player.Age || "--"}</td>
      <td>{player.Team || "--"}</td>
      <td>{background.cheatSheet?.playerContact?.phone || "--"}</td>
      <td>{background.cheatSheet?.nuggets || "--"}</td>
      
      <td>
        <FiX
          className={styles.removeIcon}
          onClick={() => removePlayer(player.id)}
        />
      </td>
    </tr>
  );
};

export default function CheatSheetGenerator() {
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [playersData, setPlayersData] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [backgroundData, setBackgroundData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all player profiles
        const playersSnapshot = await db.collection("player-profile").get();
        const players = playersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
  
        // Fetch all background overviews in a single query
        const backgroundSnapshot = await db.collection("background-overview").get();
        const backgrounds = {};
        
        backgroundSnapshot.forEach(doc => {
          const bgData = doc.data();
          // Handle both string IDs and DocumentReferences
          const playerId = bgData.playerProfile?.id || bgData.playerProfile;
          if (playerId) {
            backgrounds[playerId] = bgData;
          }
        });
  
        setPlayersData(players);
        setBackgroundData(backgrounds);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error loading player data");
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (e.target.closest(`.${styles.searchContainer}`) === null) {
        setSearchQuery("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const addPlayer = (playerId) => {
    if (!selectedPlayers.includes(playerId)) {
      setSelectedPlayers([...selectedPlayers, playerId]);
      setSearchQuery("");
    }
  };

  const removePlayer = (playerId) => {
    setSelectedPlayers(selectedPlayers.filter(id => id !== playerId));
  };

  const moveRow = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= selectedPlayers.length) return;
    
    const updatedPlayers = [...selectedPlayers];
    const [movedPlayer] = updatedPlayers.splice(fromIndex, 1);
    updatedPlayers.splice(toIndex, 0, movedPlayer);
    setSelectedPlayers(updatedPlayers);
  };

  const formatDOB = (dobString) => {
    if (!dobString) return "";
    const parts = dobString.split('-');
    if (parts.length !== 3) return dobString; // Fallback for invalid formats
    return `${parts[1]}.${parts[2]}.${parts[0]}`; // MM/DD/YYYY
  };

  const getCSVData = () => {
    // Only escape double quotes by replacing " with ""
    const escapeQuotes = (value) => {
      if (typeof value !== 'string') value = String(value || '');
      return value.replace(/"/g, '""');
    };
  
    return selectedPlayers.map(playerId => {
      const player = playersData.find(p => p.id === playerId);
      const background = backgroundData[playerId] || {};
  
      // Get latest team history entry
      const teamHistory = background.teamHistory || [];
      let latestTeamHistory = teamHistory.length > 0 
        ? [...teamHistory].sort((a, b) => b.year.localeCompare(a.year))[0]
        : null;
      
        
        const status = player.Status || "";
    const team = player.committedTeam || "";
    const recruitment = status === "Undecided" 
      ? status 
      : team 
        ? `${status}: ${team}`
        : status;
  
      // Process values without adding quotes
      const fullName = escapeQuotes(`${player.FirstName} ${player.LastName}`);
      //const recruitment = escapeQuotes(`${player.Status}: ${player.committedTeam}`);
      const nuggets = escapeQuotes(background.cheatSheet?.nuggets);
  
      return {
        "Industry Ranking Average": escapeQuotes(player.rankAverage),
        "Player Name" : fullName,
        "Recruitment" : escapeQuotes(recruitment),
        "Player Contact" : escapeQuotes(background.cheatSheet?.playerContact?.phone),
        "HT." : escapeQuotes(player.Height),
        "WT." : escapeQuotes(player.Weight),
        "WS." : escapeQuotes(player.Wingspan),
        "Hand" : escapeQuotes(player.Hand),
        "Pos." : escapeQuotes(player.Position),
        "Birthday" : escapeQuotes(formatDOB(player.DOB)),
        "Hometown" : escapeQuotes(`${player.hometown_City}, ${player.hometown_State_Country}`),
        "Team": escapeQuotes(latestTeamHistory?.school + ' (' +latestTeamHistory?.state + ')'|| ''),
        "HS Coach": escapeQuotes(latestTeamHistory ? 
          `${latestTeamHistory.coachFirstName} ${latestTeamHistory.coachLastName}` : ''),
        "HS Coach Contact" : escapeQuotes(latestTeamHistory?.phone || ''),
        "AAU Team" : escapeQuotes(player.aauTeam),
        "AAU Coach Name" : escapeQuotes(`${player.aauCoach} ${player.aauCoachLast}`),
        "AAU Contact": escapeQuotes(player.aauCoachInfo),
        "Instagram": escapeQuotes(player.Instagram),
        "Nuggets" : nuggets
      };
    });
  };

  const filteredPlayers = playersData.filter(player =>
    `${player.FirstName} ${player.LastName} ${player.Team} ${player.Position}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  if (loading) return <CustomSpinner />;

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <div className={styles.headerRow}>
          <h4>Player Cheat Sheet Generator</h4>
          <div className={styles.controls}>
            <div className={styles.searchContainer}>
              <input
                type="text"
                placeholder="Search players..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {searchQuery && (
                <div className={styles.searchResults}>
                  {filteredPlayers.map(player => (
                    <div
                      key={player.id}
                      className={styles.searchResult}
                      onClick={() => addPlayer(player.id)}
                    >
                      {player.FirstName} {player.LastName} ({player.Year})
                      <FiPlus />
                    </div>
                  ))}
                </div>
              )}
            </div>
            {selectedPlayers.length > 0 && (
              <CSVLink
              data={getCSVData()}
              filename="player-cheat-sheet.csv"
              className={styles.exportButton}
              options={{
                quotes: false, // Let library handle quotes automatically
                escapeFormulaCharacters: false // Disable if you don't need formula protection
              }}
            >
              <FiDownload /> Export CSV
            </CSVLink>
            )}
          </div>
        </div>

        <DndProvider backend={HTML5Backend}>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Year</th>
                  <th>Age</th>
                  <th>Team</th>
                  <th>Personal Number</th>
                  <th>Nuggets</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedPlayers.length === 0 ? (
                  <tr className={styles.emptyRow}>
                    <td colSpan="6">No players selected - search above to add players</td>
                  </tr>
                ) : (
                  selectedPlayers.map((playerId, index) => {
                    const player = playersData.find(p => p.id === playerId);
                    const background = backgroundData[playerId] || {};
                    return (
                      <DraggableRow
                        key={playerId}
                        index={index}
                        player={player}
                        background={background}
                        moveRow={moveRow}
                        removePlayer={removePlayer}
                      />
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </DndProvider>
      </div>
    </div>
  );
}