import React, { useState, useEffect } from "react";
import Header from "../header/header";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import styles from "./stats.module.scss";
import { MdModeEdit, MdRestoreFromTrash } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import { db } from "../../firebase";
import { collection, getDocs, addDoc, query, where,updateDoc, doc, deleteDoc } from "firebase/firestore";

const initialGameLog = {
  date: "",
  type: "",
  team: "",
  opponent: "",
  result: "",
  min: "",
  fgFga: "",
  fgPct: "",
  threePThreePA: "",
  threeFgPct: "",
  ftFta: "",
  ftPct: "",
  orb: "",
  drb: "",
  trb: "",
  ast: "",
  blk: "",
  stl: "",
  to: "",
  pf: "",
  pts: "",
  isEditing: true,
};

const columnOrder = [
  'date', 'type', 'team', 'opponent', 'result', 'min', 
  'fgFga', 'fgPct', 'threePThreePA', 'threeFgPct', 'ftFta', 
  'ftPct', 'orb', 'drb', 'trb', 'ast', 'blk', 'stl', 
  'to', 'pf', 'pts'
];

const headerLabels = {
  date: "Date",
  type: "Type",
  team: "Team",
  opponent: "Opponent",
  result: "Result",
  min: "MIN",
  fgFga: "FG-FGA",
  fgPct: "FG%",
  threePThreePA: "3P-3PA",
  threeFgPct: "3FG%",
  ftFta: "FT-FTA",
  ftPct: "FT%",
  orb: "ORB",
  drb: "DRB",
  trb: "TRB",
  ast: "AST",
  blk: "BLK",
  stl: "STL",
  to: "TO",
  pf: "PF",
  pts: "PTS"
};

const GAME_TYPES = ["HS", "AAU", "CAMP", "INTL", "OTHER"];
const RESULT_OUTCOMES = ["W", "L"];


export const AddPage = () => {
  const [statsTableData, setStatsTableData] = useState([{ ...initialGameLog }]);
  const [show, setShow] = useState({ status: false, index: null });
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [selectedPlayerProfile, setSelectedPlayerProfile] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const fetchPlayerProfiles = async () => {
      const querySnapshot = await getDocs(collection(db, "player-profile"));
      const profiles = [];
      querySnapshot.forEach((doc) => {
        profiles.push({ id: doc.id, ...doc.data() });
      });
      setPlayerProfiles(profiles);
    };
    fetchPlayerProfiles();
  }, []);

  const handleOpen = (index) => {
    setShow({ status: true, index });
  };

  useEffect(() => {
    const fetchGameLogs = async () => {
      if (!selectedPlayerProfile) return;
      try {
        const q = query(
          collection(db, "game-log-stats"),
          where("playerProfileId", "==", selectedPlayerProfile.id)
        );
        const querySnapshot = await getDocs(q);
        const logs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          isEditing: false,
        }));
        setStatsTableData(logs.length > 0 ? logs : [{ ...initialGameLog }]);
      } catch (error) {
        console.error("Error fetching game logs:", error);
      }
    };
    fetchGameLogs();
  }, [selectedPlayerProfile]);

  const filteredProfiles = playerProfiles.filter((profile) =>
    profile.Title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSubmit = async () => {
    if (!selectedPlayerProfile) {
      toast.error("Please select a player profile");
      return;
    }
  
    try {
      const results = await Promise.allSettled(
        statsTableData.map(async (gameLog) => {
          const { isEditing, id, ...logData } = gameLog;
          if (id) {
            const docRef = doc(db, "game-log-stats", id);
            await updateDoc(docRef, logData);
          } else {
            await addDoc(collection(db, "game-log-stats"), {
              playerProfileId: selectedPlayerProfile.id,
              ...logData
            });
          }
        })
      );
  
      // Check for any rejected promises
      const hasErrors = results.some(result => result.status === 'rejected');
      
      if (!hasErrors) {
        toast.success("All stats submitted successfully!");
        // Refresh data
        if (selectedPlayerProfile) {
          const q = query(
            collection(db, "game-log-stats"),
            where("playerProfileId", "==", selectedPlayerProfile.id)
          );
          const querySnapshot = await getDocs(q);
          const logs = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            isEditing: false,
          }));
          setStatsTableData(logs.length > 0 ? logs : [{ ...initialGameLog }]);
        }
      }
    } catch (error) {
      console.error("Error submitting stats:", error);
      toast.error(`Failed to submit stats: ${error.message}`);
    }
  };


  const handleConfirm = async () => {
    try {
      const gameLogToDelete = statsTableData[show.index];
      
      if (gameLogToDelete.id) {
        await deleteDoc(doc(db, "game-log-stats", gameLogToDelete.id));
        toast.success("Game log deleted successfully");
      }
  
      const newData = [...statsTableData];
      newData.splice(show.index, 1);
      setStatsTableData(newData);
    } catch (error) {
      console.error("Error deleting document:", error);
      toast.error(`Failed to delete game log: ${error.message}`);
    } finally {
      setShow({ status: false, index: null });
    }
  };

  const handleCellChange = (index, field, value) => {
    const newData = [...statsTableData];
    newData[index][field] = value;
  
    // Auto-calculate percentages
    if (field === "fgFga") {
      const [fgMade, fgAttempted] = value.split("-").map(Number);
      newData[index].fgPct = fgAttempted ? ((fgMade / fgAttempted) * 100).toFixed(1) + "%" : "0.0%";
    } 
    else if (field === "threePThreePA") {
      const [threePMade, threePAttempted] = value.split("-").map(Number);
      newData[index].threeFgPct = threePAttempted ? ((threePMade / threePAttempted) * 100).toFixed(1) + "%" : "0.0%";
    } 
    else if (field === "ftFta") {
      const [ftMade, ftAttempted] = value.split("-").map(Number);
      newData[index].ftPct = ftAttempted ? ((ftMade / ftAttempted) * 100).toFixed(1) + "%" : "0.0%";
    }
  
    setStatsTableData(newData);
  };
  

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.title}>
          <h4>Add Game Log Stats</h4>
        </div>

        <Row>
          <Col md={4}>
            <div className={styles.field}>
              <p>Player Profile</p>
              <div className={styles.autocomplete}>
                <Form.Control
                  type="text"
                  placeholder="Search player..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setShowSuggestions(true)}
                />
                {showSuggestions && (
                  <div className={styles.suggestions}>
                    {filteredProfiles.map((profile) => (
                      <div
                        key={profile.id}
                        className={styles.suggestionItem}
                        onClick={() => {
                          setSelectedPlayerProfile(profile);
                          setSearchQuery(profile.Title);
                          setShowSuggestions(false);
                        }}
                      >
                        {profile.Title}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Col>

          <Col md={12}>
          <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
              {columnOrder.map((field) => (
                <th key={field} className={styles[field]}>
                  {headerLabels[field]}
                </th>
              ))}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
  {statsTableData.map((gameLog, index) => (
    <tr key={index}>
      {columnOrder.map((field) => (
        <td key={field} className={`${styles[field]} ${!gameLog.isEditing ? styles[field] : ''}`}>
          {/* Always render percentage fields as read-only */}
          {['fgPct', 'threeFgPct', 'ftPct'].includes(field) ? (
            <span>{gameLog[field]}</span>
          ) : gameLog.isEditing ? (
            <>
              {field === 'type' ? (
                <select
                  value={gameLog[field]}
                  onChange={(e) => handleCellChange(index, field, e.target.value)}
                  className={styles.editableInput}
                >
                  <option value="">Type</option>
                  {GAME_TYPES.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              ) : field === 'result' ? (
                <div className={styles.resultInput}>
                  <select
                    value={gameLog.result.split(' ')[0] || ''}
                    onChange={(e) =>
                      handleCellChange(
                        index,
                        'result',
                        `${e.target.value} ${gameLog.result.split(' ')[1] || ''}`.trim()
                      )
                    }
                    className={styles.editableInput}
                    style={{ width: '60px', marginRight: '5px' }}
                  >
                    <option value="">-</option>
                    {RESULT_OUTCOMES.map((outcome) => (
                      <option key={outcome} value={outcome}>
                        {outcome}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    value={gameLog.result.split(' ')[1] || ''}
                    onChange={(e) =>
                      handleCellChange(
                        index,
                        'result',
                        `${gameLog.result.split(' ')[0] || ''} ${e.target.value}`.trim()
                      )
                    }
                    className={styles.editableInput}
                    style={{ width: '80px' }}
                    placeholder="Score"
                  />
                </div>
              ) : ['fgFga', 'threePThreePA', 'ftFta'].includes(field) ? (
                <div className={styles.splitInput}>
                  <input
                    value={(gameLog[field] || '').split('-')[0] || ''}
                    onChange={(e) => {
                      const parts = (gameLog[field] || '').split('-');
                      parts[0] = e.target.value;
                      handleCellChange(index, field, parts.join('-'));
                    }}
                    className={styles.editableInput}
                    placeholder={field === 'fgFga' ? 'FGM' : field === 'threePThreePA' ? '3PM' : 'FTM'}
                  />
                  <span>-</span>
                  <input
                    value={(gameLog[field] || '').split('-')[1] || ''}
                    onChange={(e) => {
                      const parts = (gameLog[field] || '').split('-');
                      parts[1] = e.target.value;
                      handleCellChange(index, field, parts.join('-'));
                    }}
                    className={styles.editableInput}
                    placeholder={field === 'fgFga' ? 'FGA' : field === 'threePThreePA' ? '3PA' : 'FTA'}
                  />
                </div>
              ) : (
                <input
                  value={gameLog[field]}
                  onChange={(e) => handleCellChange(index, field, e.target.value)}
                  className={styles.editableInput}
                />
              )}
            </>
          ) : (
            <span>{gameLog[field]}</span>
          )}
        </td>
      ))}
      <td>
        <div className={styles.actionIcon}>
          <MdModeEdit
            style={{ color: 'green', cursor: 'pointer', marginRight: 10 }}
            onClick={() => {
              const newData = [...statsTableData];
              newData[index].isEditing = !newData[index].isEditing;
              setStatsTableData(newData);
            }}
          />
          <MdRestoreFromTrash
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => handleOpen(index)}
          />
        </div>
      </td>
    </tr>
  ))}
  <tr>
    <td colSpan={columnOrder.length + 1}>
      <button
        className={styles.buttonAdd}
        onClick={() =>
          setStatsTableData([...statsTableData, { ...initialGameLog, isEditing: true }])
        }
      >
        <AiOutlinePlus />
      </button>
    </td>
  </tr>
</tbody>

          </table>
          </div>
        </Col>

          <Col md={12}>
            <div className={styles.buttonContainer}>
              <Row>
                <Col md={6}>
                  <Button className={styles.secondaryButton}>Cancel</Button>
                </Col>
                <Col md={6}>
                  <Button onClick={handleSubmit}>Upload</Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />

      <ConfirmModal
        show={show}
        handleClose={() => setShow({ status: false, index: null })}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

const ConfirmModal = ({ show, handleClose, handleConfirm }) => (
  <Modal show={show.status} onHide={handleClose} backdrop="static">
    <Modal.Header closeButton>
      <Modal.Title>Confirm Delete</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to delete this game log?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="danger" onClick={handleConfirm}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

export default AddPage;