import React from "react";
import styles from './statsTable.module.scss';
import { Button } from "react-bootstrap";

export const StatsTable = ({ formik }) => {
  const { values, handleChange, handleBlur, setFieldValue } = formik;

  if (!formik) {
    console.error('Formik instance is undefined.');
    return null;
  }

  const addStatsLine = () => {
    const newStats = [
      ...values.typeSpecificFields.stats,
      {
        team: "",
        opponent: "",
        score: [],
        min: "",
        fg: [],
        "3fg": [],
        ft: [],
        or: "",
        tr: "",
        ast: "",
        to: "",
        blk: "",
        stl: "",
        pf: "",
        pts: "",
      }
    ];
    setFieldValue("typeSpecificFields.stats", newStats);
  };

  const removeStatsLine = (index) => {
    const newStats = values.typeSpecificFields.stats.filter((_, i) => i !== index);
    setFieldValue("typeSpecificFields.stats", newStats);
  };

  return (
    <div className="table-responsive">
      <table className={`table-sm ${styles.table}`}>
        <thead className="text-center">
          <tr>
            <th className={styles.team}>Team</th>
            <th>vs</th>
            <th className={styles.team}>Opponent</th>
            <th className={styles.doubleInputs}>Score</th>
            <th>MIN</th>
            <th className={styles.doubleInputs}>FG</th>
            <th className={styles.doubleInputs}>3FG</th>
            <th className={styles.doubleInputs}>FT</th>
            <th>OR</th>
            <th>TR</th>
            <th>AST</th>
            <th>TO</th>
            <th>BLK</th>
            <th>STL</th>
            <th>PF</th>
            <th>PTS</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {values.typeSpecificFields.stats.map((stat, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '160px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.team}
                  name={`typeSpecificFields.stats[${index}].team`}
                />
              </td>
              <td>vs</td>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '160px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.opponent}
                  name={`typeSpecificFields.stats[${index}].opponent`}
                />
              </td>
              <td className={styles.doubleInputs}>
                <div className={styles.doubleInputsContainer}>
                  <input
                    type="text"
                    className="border"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={stat.score[0] || ''}
                    name={`typeSpecificFields.stats[${index}].score[0]`}
                  />
                  <span>-</span>
                  <input
                    type="text"
                    className="border"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={stat.score[1] || ''}
                    name={`typeSpecificFields.stats[${index}].score[1]`}
                  />
                </div>
              </td>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '40px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.min}
                  name={`typeSpecificFields.stats[${index}].min`}
                />
              </td>
              <td className={styles.doubleInputs}>
                <div className={styles.doubleInputsContainer}>
                  <input
                    type="text"
                    className="border"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={stat.fg[0] || ''}
                    name={`typeSpecificFields.stats[${index}].fg[0]`}
                  />
                  <span>-</span>
                  <input
                    type="text"
                    className="border"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={stat.fg[1] || ''}
                    name={`typeSpecificFields.stats[${index}].fg[1]`}
                  />
                </div>
              </td>
              <td className={styles.doubleInputs}>
              <div className={styles.doubleInputsContainer}>
                <input
                  type="text"
                  className="border"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat["3fg"][0] || ''}
                  name={`typeSpecificFields.stats[${index}]['3fg'][0]`}
                />
                <span>-</span>
                <input
                  type="text"
                  className="border"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat["3fg"][1] || ''}
                  name={`typeSpecificFields.stats[${index}]['3fg'][1]`}
                />
                </div>
              </td>
              <td className={styles.doubleInputs}>
              <div className={styles.doubleInputsContainer}>
                <input
                  type="text"
                  className="border"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.ft[0] || ''}
                  name={`typeSpecificFields.stats[${index}].ft[0]`}
                />
                <span>-</span>
                <input
                  type="text"
                  className="border"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.ft[1] || ''}
                  name={`typeSpecificFields.stats[${index}].ft[1]`}
                />
              </div>
              </td>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '40px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.or}
                  name={`typeSpecificFields.stats[${index}].or`}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '40px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.tr}
                  name={`typeSpecificFields.stats[${index}].tr`}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '40px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.ast}
                  name={`typeSpecificFields.stats[${index}].ast`}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '40px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.to}
                  name={`typeSpecificFields.stats[${index}].to`}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '40px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.blk}
                  name={`typeSpecificFields.stats[${index}].blk`}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '40px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.stl}
                  name={`typeSpecificFields.stats[${index}].stl`}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '40px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.pf}
                  name={`typeSpecificFields.stats[${index}].pf`}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="border"
                  style={{ width: '40px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={stat.pts}
                  name={`typeSpecificFields.stats[${index}].pts`}
                />
              </td>
              <td>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => removeStatsLine(index)}
                  disabled={values.typeSpecificFields.stats.length === 1}
                >
                  ×
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-2">
        <Button variant="primary" size="sm" onClick={addStatsLine}>
          Add Stats Line
        </Button>
      </div>
    </div>
  );
};