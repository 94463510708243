import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Table, Button } from "react-bootstrap";
import Header from "../header/header";
import styles from "./subs.module.scss";
import AddMemberForm from "./add-member";
import { auth, db } from "../../firebase";
import { CustomSpinner } from "../shared/Spinner";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Timestamp } from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";
import EditMemberForm from "./edit-member"; // Update path as needed
import { functions } from "../../firebase";

export const MembersPage = () => {
  const [showForm, setShowForm] = useState(false);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const handleToggleForm = useCallback(() => {
    setShowForm((prev) => !prev);
  }, []);

  const getMembers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await db.collection("Accounts").get();
      const data = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setMembers(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleEditMember = (member) => {
    setSelectedMember(member);
    setShowEditForm(true);
  };

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  const handleBlockMember = async (id, isBlocked, email) => {
    try {
      const toggleBlock = functions.httpsCallable('toggleBlockStatus');
      await toggleBlock({ uid: id, isBlocked });
      getMembers();
      toast.success(`User ${!isBlocked ? 'blocked' : 'unblocked'} successfully`);
    } catch (error) {
      console.log("Error blocking member", error);
      toast.error(`Error ${!isBlocked ? 'blocking' : 'unblocking'} user`);
    }
  };

// Modified handleDeleteMember function
const handleDeleteMember = async (id, email) => {
  try {
    const deleteUser = functions.httpsCallable('deleteUser');
    await deleteUser({ uid: id });
    toast.success("Member deleted successfully");
    getMembers();
  } catch (error) {
    console.log("Error deleting member", error);
    toast.error("Error deleting member");
  }
};

  const sortedMembers = useMemo(() => {
    if (sortConfig.key) {
      return [...members].sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (
          sortConfig.key === "lastLogin" ||
          sortConfig.key === "subStart" ||
          sortConfig.key === "subEnd"
        ) {
          aValue = aValue ? aValue.toDate() : null;
          bValue = bValue ? bValue.toDate() : null;
        } else if (typeof aValue === "string") {
          aValue = aValue ? aValue.toLowerCase() : "";
          bValue = bValue ? bValue.toLowerCase() : "";
        } else {
          aValue = aValue ? aValue : "";
          bValue = bValue ? bValue : "";
        }

        if (aValue === "" || aValue === null || aValue === undefined) return 1;
        if (bValue === "" || bValue === null || bValue === undefined) return -1;

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return members;
  }, [members, sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "▲" : "▼";
    }
    return null;
  };

  const renderMembers = useMemo(
    () =>
      sortedMembers.map((el, index) => {
        const {
          firstName,
          lastName,
          orgTitle,
          org,
          memberType,
          email,
          isBlocked,
          subStart,
          subEnd,
          lastLogin,
        } = el;
        return (
          <tr key={el.id}>
            <td style={{ whiteSpace: 'nowrap' }}>{`${firstName} ${lastName}`}</td>
            <td style={{ whiteSpace: 'nowrap' }}>{orgTitle}</td>
            <td>{org}</td>
            <td style={{ whiteSpace: 'nowrap' }}>{memberType}</td>
            <td>{lastLogin ? moment(lastLogin.toDate()).calendar() : "-"}</td>
            <td>{email}</td>
            <td>
              <span style={{ color: isBlocked ? "red" : "lightgreen" }}>
                {isBlocked ? "Blocked" : "Active"}
              </span>
            </td>
            <td>
              {/* {subStart ? moment(subStart?.toDate()).format("MM/DD/YYYY") : "-"} */}
              {/* {JSON.stringify(subStart)} */}
              {subStart
                ? moment(
                    new Timestamp(
                      subStart.seconds,
                      subStart.nanoseconds
                    ).toDate()
                  ).format("MM/DD/YYYY")
                : "-"}
            </td>
            <td>
              {subEnd
                ? moment(
                    new Timestamp(subEnd.seconds, subEnd.nanoseconds).toDate()
                  ).format("MM/DD/YYYY")
                : "-"}
            </td>
            <td>
              <div className="d-flex">
              <Button
                variant="primary"
                size="sm"
                onClick={() => handleEditMember(el)}
                style={{ marginRight: "10px" }}
              >
                Edit
              </Button>

              <Button
                  variant={isBlocked ? "success" : "danger"}
                  size="sm"
                  onClick={() => {
                    const action = isBlocked ? 'unblocking' : 'blocking';
                    if (window.confirm(`You are ${action} user ${email}. Press OK to confirm.`)) {
                      handleBlockMember(el.id, isBlocked, email);
                    }
                  }}
                  style={{ marginRight: "10px" }}
                >
                  {isBlocked ? "Unblock" : "Block"}
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => {
                    if (window.confirm(`You are deleting user ${email}. This action cannot be undone. Press OK to confirm.`)) {
                      handleDeleteMember(el.id, email);
                    }
                  }}
                >
                  Delete
                </Button>
              </div>
            </td>
          </tr>
        );
      }),
    [sortedMembers]
  );

  if (loading) {
    return <CustomSpinner />;
  }

  if (error) {
    return <div>Error loading member data: {error.message}</div>;
  }

  return (
    <>
      <Header />
      <ToastContainer />
      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Subscription Members</h4>
            <subtitle>
              This Section is used to monitor subscription members
            </subtitle>
          </div>
          <Button onClick={handleToggleForm}>Add Member</Button>
        </div>
        <div className={styles.contentBody}>
          <Table responsive>
            <thead>
              <tr>
                <th onClick={() => requestSort("firstName")}>
                  Name {getSortIndicator("firstName")}
                </th>
                <th onClick={() => requestSort("orgTitle")}>
                  Title {getSortIndicator("orgTitle")}
                </th>
                <th onClick={() => requestSort("org")}>
                  Organization {getSortIndicator("org")}
                </th>
                <th onClick={() => requestSort("memberType")}>
                  Account Type {getSortIndicator("memberType")}
                </th>
                <th onClick={() => requestSort("lastLogin")}>
                  Last Login {getSortIndicator("lastLogin")}
                </th>
                <th onClick={() => requestSort("email")}>
                  Account Email {getSortIndicator("email")}
                </th>
                <th onClick={() => requestSort("isBlocked")}>
                  Status {getSortIndicator("isBlocked")}
                </th>
                <th onClick={() => requestSort("subStart")}>
                  Start Date {getSortIndicator("subStart")}
                </th>
                <th onClick={() => requestSort("subEnd")}>
                  Renewal Date {getSortIndicator("subEnd")}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{renderMembers}</tbody>
          </Table>
        </div>
      </div>
      <AddMemberForm
        open={showForm}
        handleToggle={handleToggleForm}
        refreshMembers={getMembers}
      />
      <EditMemberForm
        open={showEditForm}
        handleToggle={() => setShowEditForm(false)}
        refreshMembers={getMembers}
        member={selectedMember}
      />
    </>
  );
};
