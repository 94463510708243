import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.scss";
import Header from "../header/header";
import StatsWidgets from "./statsWidgets";
import ActivityFeed from "./activityFeed";
import { useAuth } from "../../Context/AuthContext";
import WatchList from "./watchlist";
import RecentActivity from "./recentActivity";

export default function Dashboard() {
  const { isAdmin, isSuperAdmin } = useAuth();
  const [loadingStats, setLoadingStats] = useState(true); // Track loading state

  useEffect(() => {
    const fetchData = async () => {
      // Simulate async data fetching for StatsWidgets
      await fetchCounts(); // This will be your data fetching logic for StatsWidgets
      setLoadingStats(false); // Once data is fetched, set loading to false
    };
    fetchData();
  }, []);

  // Fetching logic for StatsWidgets (for example)
  const fetchCounts = async () => {
    // Simulate async work, replace this with your actual data fetching
    await new Promise(resolve => setTimeout(resolve, 2000)); // Simulate delay
  };

  return (
    <div className={styles.container}>
      <Header />
      {!isAdmin() && !isSuperAdmin() && (
        <>
          <WatchList />
          <RecentActivity />
        </>
      )}
      {(isAdmin() || isSuperAdmin()) && (
        <>
          {/* StatsWidgets only shown when data is ready */}
          {loadingStats ? (
            <div>Loading...</div> // Placeholder while StatsWidgets load
          ) : (
            <>
              <StatsWidgets /> {/* StatsWidgets load first */}
              <ActivityFeed />
              <RecentActivity /> {/* RecentActivity load after StatsWidgets */}
            </>
          )}
        </>
      )}
    </div>
  );
}
