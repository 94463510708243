import React, { useState, useEffect } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { AutoComplete } from "primereact/autocomplete";
import Header from "../header/header";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./Score.module.scss";
import moment from "moment";
import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";

export default function PIScoreForm() {
  const [loading, setLoading] = useState(false);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [selectedDOB, setSelectedDOB] = useState("");
  const [autoCompleteInput, setAutoCompleteInput] = useState("");
  const [position, setPosition] = useState("");
  const [existingDocId, setExistingDocId] = useState(null); // Track existing document ID



  const scoreOptions = [
    { value: "10", label: "Elite" },
    { value: "9.5", label: "" },
    { value: "9", label: "" },
    { value: "8.5", label: "" },
    { value: "8", label: "" },
    { value: "7.5", label: "Above Average" },
    { value: "7", label: "" },
    { value: "6.5", label: "" },
    { value: "6", label: "" },
    { value: "5.5", label: "" },
    { value: "5", label: "Average" },
    { value: "4.5", label: "" },
    { value: "4", label: "" },
    { value: "3.5", label: "" },
    { value: "3", label: "" },
    { value: "2.5", label: "Below Average" },
    { value: "2", label: "" },
    { value: "1.5", label: "" },
    { value: "1", label: "" },
    { value: "0.5", label: "" },
    { value: "0", label: "Poor/Nonexistent" },
  ];

  const intangiblesQuestions = [
    { 
      label: "Poise/Court Demeanor",
      name: "poiseCourtDemeanor",
      helper: "Always in control? Do they play w/ good pace?" 
    },
    { 
      label: "Toughness",
      name: "toughness",
      helper: "Grit? 50-50 balls? Mentally tough? Durability?" 
    },
    { 
      label: "Motor/Effort Level",
      name: "motorEffortLevel",
      helper: "Do they take plays off?" 
    },
    { 
      label: "Clutch Game",
      name: "clutchGame",
      helper: "Calm under pressure? Do they want the big shot?" 
    },
    { 
      label: "Coachability/Body Language",
      name: "coachabilityBodyLanguage",
      helper: "Coachable? Maturity? Response to adversity?" 
    },
    { 
      label: "Confidence",
      name: "confidence",
      helper: "How do they carry themselves?" 
    },
    { 
      label: "Selflessness",
      name: "selflessness",
      helper: "Selfish? Team-first? Wins vs. Numbers?" 
    },
    { 
      label: "Leadership",
      name: "leadership",
      helper: "Vocal? By example? Rally the troops?" 
    },
  ];
  
  const physicalToolsQuestions = [
    { 
      label: "Overall Strength",
      name: "overallStrength",
      helper: "Can they play through contact?" 
    },
    { 
      label: "Speed/Acceleration",
      name: "speedAcceleration",
      helper: "0-60? Changing speed/direction?" 
    },
    { 
      label: "Explosiveness",
      name: "explosiveness",
      helper: "Elevation? Power? One vs. two legs?" 
    },
    { 
      label: "Vertical Athleticism",
      name: "verticalAthleticism",
      helper: "Quick off the ground? Second-jump? Timing?" 
    },
    { 
      label: "Lateral Athleticism",
      name: "lateralAthleticism",
      helper: "Quick vs. slow-twitch muscles?" 
    },
    { 
      label: "Upside",
      name: "upside",
      helper: "Still growing? Far from a finished product?" 
    },
  ];
  
  const positionalPhysicalQuestions = [
    { 
      label: "Height",
      name: "height",
      helper: "Compared to peers at the same position?" 
    },
    { 
      label: "Length",
      name: "length",
      helper: "WS? Hand size? Feet? Any extremes?" 
    },
    { 
      label: "Frame",
      name: "frame",
      helper: "How's their build for their age? Room to grow?" 
    },
    { 
      label: "Versatility",
      name: "versatility",
      helper: "How many positions can they play on both sides?" 
    },
  ];

  const positionQuestions = {
    Guard: {
    offensive: [
        { label: "Handle", name: "guard_o_handle" },
        { label: "Court Vision + Passing Ability", name: "guard_o_courtVisionPassingAbility" },
        { label: "First-Step Burst", name: "guard_o_firstStepBurst" },
        { label: "Change of Speed + Direction", name: "guard_o_changeOfSpeedDirection" },
        { label: "BBIQ/Feel for the Game", name: "guard_o_bbiqFeelForGame" },
        { label: "PnR Feel/Ability", name: "guard_o_pnrFeelAbility" },
        { label: "Getting Open/Off-Ball Separation", name: "guard_o_gettingOpenOffBallSeparation" },
        { label: "Offensive Rebounding Instincts", name: "guard_o_offensiveReboundingInstincts" },
        { label: "Playmaking Ability", name: "guard_o_playmakingAbility" },
        { label: "Footwork/On Ball Separation", name: "guard_o_footworkOnBallSeparation" },
        { label: "Paint Touch Consistency", name: "guard_o_paintTouchConsistency" },
        { label: "Finishing Ability w/ Both Hands", name: "guard_o_finishingAbilityBothHands" },
        { label: "Getting to FT Line", name: "guard_o_gettingToFtLine" },
        { label: "Touch", name: "guard_o_touch" },
        { label: "Scoring Instincts/Iso Ability", name: "guard_o_scoringInstinctsIsoAbility" },
        { label: "Floater Game", name: "guard_o_floaterGame" },
        { label: "Mid-Range Game", name: "guard_o_midRangeGame" },
        { label: "C&S Ability", name: "guard_o_cnsAbility" },
        { label: "Shooting OTD", name: "guard_o_shootingOffDribble" },
        { label: "FT Shooting", name: "guard_o_ftShooting" },
    ],
    defensive: [
        { label: "Physicality", name: "guard_d_physicality" },
        { label: "BBIQ/Overall Awareness", name: "guard_d_bbiqOverallAwareness" },
        { label: "Focus/Discipline", name: "guard_d_focusDiscipline" },
        { label: "Nose for Ball", name: "guard_d_noseForBall" },
        { label: "Sprinting Back on D", name: "guard_d_sprintingBackOnD" },
        { label: "Containing Dribble Penetration", name: "guard_d_containingDribblePenetration" },
        { label: "Defending Ball Screens", name: "guard_d_defendingBallScreens" },
        { label: "Contesting Shots", name: "guard_d_contestingShots" },
        { label: "Anticipation/Effectiveness Off-Ball", name: "guard_d_anticipationEffectOffBall" },
        { label: "Defending Post-Ups", name: "guard_d_defendingPostUps" },
        { label: "Defensive Rebounding", name: "guard_d_defensiveRebounding" },
        { label: "Defensive Versatility", name: "guard_d_defensiveVersatility" },
        { label: "Consistency", name: "guard_d_consistency" },
    ]
    },
    Wing: {
        offensive: [
            { label: "Handle", name: "wing_o_handle" },
            { label: "Court Vision + Passing Ability", name: "wing_o_courtVisionPassingAbility" },
            { label: "First-Step Burst", name: "wing_o_firstStepBurst" },
            { label: "Change of Speed + Direction", name: "wing_o_changeOfSpeedDirection" },
            { label: "BBIQ/Feel for the Game", name: "wing_o_bbiqFeelForGame" },
            { label: "PnR Feel/Ability", name: "wing_o_pnrFeelAbility" },
            { label: "Getting Open/Off-Ball Separation", name: "wing_o_gettingOpenOffBallSeparation" },
            { label: "Offensive Rebounding Instincts", name: "wing_o_offensiveReboundingInstincts" },
            { label: "Playmaking Ability", name: "wing_o_playmakingAbility" },
            { label: "Footwork/On-Ball Separation", name: "wing_o_footworkOnBallSeparation" },
            { label: "Paint Touch Consistency", name: "wing_o_paintTouchConsistency" },
            { label: "Finishing Ability w/ Both Hands", name: "wing_o_finishingAbilityBothHands" },
            { label: "Getting to FT Line", name: "wing_o_gettingToFtLine" },
            { label: "Touch", name: "wing_o_touch" },
            { label: "Scoring Instincts/Iso Ability", name: "wing_o_scoringInstinctsIsoAbility" },
            { label: "Floater Game", name: "wing_o_floaterGame" },
            { label: "Mid-Range Game", name: "wing_o_midRangeGame" },
            { label: "C&S Ability", name: "wing_o_cnsAbility" },
            { label: "Shooting OTD", name: "wing_o_shootingOffDribble" },
            { label: "FT Shooting", name: "wing_o_ftShooting" },
        ],
        defensive: [
            { label: "Physicality", name: "wing_d_physicality" },
            { label: "BBIQ/Overall Awareness", name: "wing_d_bbiqOverallAwareness" },
            { label: "Focus/Discipline", name: "wing_d_focusDiscipline" },
            { label: "Nose for Ball", name: "wing_d_noseForBall" },
            { label: "Sprinting Back on D", name: "wing_d_sprintingBackOnD" },
            { label: "Containing Dribble Penetration", name: "wing_d_containingDribblePenetration" },
            { label: "Defending Ball Screens", name: "wing_d_defendingBallScreens" },
            { label: "Contesting Shots", name: "wing_d_contestingShots" },
            { label: "Anticipation/Effectiveness Off-Ball", name: "wing_d_anticipationEffectOffBall" },
            { label: "Defending Post-Ups", name: "wing_d_defendingPostUps" },
            { label: "Defensive Rebounding", name: "wing_d_defensiveRebounding" },
            { label: "Defensive Versatility", name: "wing_d_defensiveVersatility" },
            { label: "Consistency", name: "wing_d_consistency" },
        ]
    },
    Big: {
        offensive: [
          { label: "Physicality", name: "big_o_physicality" },
          { label: "Rim Running", name: "big_o_rimRunning" },
          { label: "Hands", name: "big_o_hands" },
          { label: "BBIQ/Feel for the Game", name: "big_o_bbiqFeelForGame" },
          { label: "Back-To-Basket Game/Footwork", name: "big_o_backToBasketGameFootwork" },
          { label: "Face-Up Game", name: "big_o_faceUpGame" },
          { label: "PnR + PnP Ability", name: "big_o_pnrPnpAbility" },
          { label: "Court Vision/Passing Ability", name: "big_o_courtVisionPassingAbility" },
          { label: "Finishing With Either Hand", name: "big_o_finishingWithEitherHand" },
          { label: "Getting Open", name: "big_o_gettingOpen" },
          { label: "Screen Setting", name: "big_o_screenSetting" },
          { label: "Offensive Rebounding Instincts", name: "big_o_offensiveReboundingInstincts" },
          { label: "Getting to FT Line", name: "big_o_gettingToFtLine" },
          { label: "Handle", name: "big_o_handle" },
          { label: "Touch", name: "big_o_touch" },
          { label: "Scoring Instincts/Iso Ability", name: "big_o_scoringInstinctsIsoAbility" },
          { label: "Floater Game", name: "big_o_floaterGame" },
          { label: "Mid-Range Game", name: "big_o_midRangeGame" },
          { label: "C&S Ability", name: "big_o_cnsAbility" },
          { label: "FT Shooting", name: "big_o_ftShooting" },
        ],
        defensive: [
          { label: "Physicality", name: "big_d_physicality" },
          { label: "BBIQ/Overall Awareness", name: "big_d_bbiqOverallAwareness" },
          { label: "Focus/Discipline", name: "big_d_focusDiscipline" },
          { label: "Nose for Ball", name: "big_d_noseForBall" },
          { label: "Sprinting Back on D", name: "big_d_sprintingBackOnD" },
          { label: "Containing Dribble Penetration", name: "big_d_containingDribblePenetration" },
          { label: "Defending Ball Screens", name: "big_d_defendingBallScreens" },
          { label: "Contesting Shots", name: "big_d_contestingShots" },
          { label: "Anticipation/Effectiveness Off-Ball", name: "big_d_anticipationEffectOffBall" },
          { label: "Defending Post-Ups", name: "big_d_defendingPostUps" },
          { label: "Defensive Rebounding", name: "big_d_defensiveRebounding" },
          { label: "Defensive Versatility", name: "big_d_defensiveVersatility" },
          { label: "Consistency", name: "big_d_consistency" },
        ]
      },
      Forward: {
        offensive: [
          { label: "Handle", name: "forward_o_handle" },
          { label: "Physicality", name: "forward_o_physicality" },
          { label: "Change of Speed + Direction", name: "forward_o_changeOfSpeedDirection" },
          { label: "BBIQ/Feel for the Game", name: "forward_o_bbiqFeelForGame" },
          { label: "Getting Open/Off-Ball Separation", name: "forward_o_gettingOpenOffBallSeparation" },
          { label: "PnR + PnP Ability", name: "forward_o_pnrPnpAbility" },
          { label: "Offensive Rebounding Instincts", name: "forward_o_offensiveReboundingInstincts" },
          { label: "Court Vision + Passing Ability", name: "forward_o_courtVisionPassingAbility" },
          { label: "Playmaking Ability", name: "forward_o_playmakingAbility" },
          { label: "Footwork/On-Ball Separation", name: "forward_o_footworkOnBallSeparation" },
          { label: "Getting to FT Line", name: "forward_o_gettingToFtLine" },
          { label: "Touch", name: "forward_o_touch" },
          { label: "Scoring Instincts/Iso Ability", name: "forward_o_scoringInstinctsIsoAbility" },
          { label: "Finishing Ability w/ Both Hands", name: "forward_o_finishingAbilityBothHands" },
          { label: "Floater Game", name: "forward_o_floaterGame" },
          { label: "Mid-Range Game", name: "forward_o_midRangeGame" },
          { label: "C&S Ability", name: "forward_o_cnsAbility" },
          { label: "Shooting OTD", name: "forward_o_shootingOffDribble" },
          { label: "FT Shooting", name: "forward_o_ftShooting" },
          { label: "Post Game", name: "forward_o_postGame" },
        ],
        defensive: [
          { label: "Physicality", name: "forward_d_physicality" },
          { label: "BBIQ/Overall Awareness", name: "forward_d_bbiqOverallAwareness" },
          { label: "Focus/Discipline", name: "forward_d_focusDiscipline" },
          { label: "Nose for Ball", name: "forward_d_noseForBall" },
          { label: "Sprinting Back on D", name: "forward_d_sprintingBackOnD" },
          { label: "Defending in Space", name: "forward_d_defendingInSpace" },
          { label: "Defending Ball Screens", name: "forward_d_defendingBallScreens" },
          { label: "Rim Protection/Shot Blocking", name: "forward_d_rimProtectionShotBlocking" },
          { label: "Anticipation/Effectiveness Off-Ball", name: "forward_d_anticipationEffectOffBall" },
          { label: "Defending Post-Ups", name: "forward_d_defendingPostUps" },
          { label: "Defensive Rebounding", name: "forward_d_defensiveRebounding" },
          { label: "Defensive Versatility", name: "forward_d_defensiveVersatility" },
          { label: "Consistency", name: "forward_d_consistency" },
        ]
      },
    default: {
      offensive: [],
      defensive: [],
    },
  };

  const currentQuestions = positionQuestions[position] || positionQuestions.default;

  // Update the renderQuestions function (modify this in your component)
const renderQuestions = (questions, formik) =>
    questions.map((q) => (
      <Row key={q.name} className="mb-4 align-items-center">
        <Col md={6}>
          <div className={styles.field}>
            <label className={styles.label}>{q.label}</label>
            <select
              name={q.name}
              value={formik.values[q.name]}
              onChange={formik.handleChange}
              className={`${styles.fullWidth} ${styles.dropdown}`}
            >
              <option value="">Select Score</option>
              {scoreOptions.map((score) => (
                <option key={score.value} value={score.value}>
                  {score.label ? `${score.value} - ${score.label}` : score.value}
                </option>
              ))}
            </select>
            {formik.errors[q.name] && formik.touched[q.name] && (
              <div className={styles.errorMessage}>
                {formik.errors[q.name]}
              </div>
            )}
          </div>
        </Col>
        {q.helper && (
          <Col md={6}>
            <div className={styles.helperContainer}>
              <p className={styles.helperText}>{q.helper}</p>
            </div>
          </Col>
        )}
      </Row>
    ));


    // calculations 
    const calculateOverallScore = (values, position) => {
        const currentPosition = positionQuestions[position] || positionQuestions.default;
        
        // Sum all categories
        const sumCategory = (questions) => 
          questions.reduce((acc, q) => acc + parseFloat(values[q.name] || 0), 0);
      
        const total = 
          sumCategory(intangiblesQuestions) +
          sumCategory(physicalToolsQuestions) +
          sumCategory(positionalPhysicalQuestions) +
          sumCategory(currentPosition.offensive) +
          sumCategory(currentPosition.defensive);
      
        return (total / 5.1).toFixed(1);
    };

    const formik = useFormik({
        initialValues: {
          playerProfile: "",
          position: "",
          overallScore: "",
          // Static questions
          ...intangiblesQuestions.reduce((acc, q) => ({ ...acc, [q.name]: "" }), {}),
          ...physicalToolsQuestions.reduce((acc, q) => ({ ...acc, [q.name]: "" }), {}),
          ...positionalPhysicalQuestions.reduce((acc, q) => ({ ...acc, [q.name]: "" }), {}),
          // Position-specific questions (all positions)
          ...Object.values(positionQuestions).reduce((acc, position) => ({
            ...acc,
            ...position.offensive.reduce((oAcc, q) => ({ ...oAcc, [q.name]: "" }), {}),
            ...position.defensive.reduce((dAcc, q) => ({ ...dAcc, [q.name]: "" }), {}),
          }), {}),
        },
        validationSchema: Yup.object({
          playerProfile: Yup.string().required("Player profile is required"),
          position: Yup.string().required("Position is required"),
          // Static questions validation
          ...intangiblesQuestions.reduce((acc, q) => ({ 
            ...acc, 
            [q.name]: Yup.string().required("Score is required") 
          }), {}),
          ...physicalToolsQuestions.reduce((acc, q) => ({ 
            ...acc, 
            [q.name]: Yup.string().required("Score is required") 
          }), {}),
          ...positionalPhysicalQuestions.reduce((acc, q) => ({ 
            ...acc, 
            [q.name]: Yup.string().required("Score is required") 
          }), {}),
          // Position-specific validation (will only validate visible fields)
          ...Object.values(positionQuestions).reduce((acc, position) => ({
            ...acc,
            ...position.offensive.reduce((oAcc, q) => ({
              ...oAcc,
              [q.name]: Yup.string().when('position', {
                is: (val) => val === position.type,
                then: Yup.string().required("Score is required"),
                otherwise: Yup.string().notRequired()
              })
            }), {}),
            ...position.defensive.reduce((dAcc, q) => ({
              ...dAcc,
              [q.name]: Yup.string().when('position', {
                is: (val) => val === position.type,
                then: Yup.string().required("Score is required"),
                otherwise: Yup.string().notRequired()
              })
            }), {}),
          }), {}),
        }),
        
        onSubmit: async (values) => {
          setLoading(true);
          try {
            // Filter out empty position-specific fields
            const currentPosition = positionQuestions[values.position] || positionQuestions.default;
            const positionFields = [
              ...currentPosition.offensive.map(q => q.name),
              ...currentPosition.defensive.map(q => q.name)
            ];

            const overallScore = calculateOverallScore(values, position);
            values.overallScore = overallScore;

            const playerProfileRef = doc(db, "player-profile", values.playerProfile);
      
            const filteredValues = Object.keys(values).reduce((acc, key) => {
            // Always include these critical fields
            if (['playerProfile', 'position', 'overallScore'].includes(key)) {
                acc[key] = values[key];
                return acc;
            }
            
            if (positionFields.includes(key) || 
                intangiblesQuestions.some(q => q.name === key) ||
                physicalToolsQuestions.some(q => q.name === key) ||
                positionalPhysicalQuestions.some(q => q.name === key)) {
                acc[key] = values[key];
            }
            return acc;
            }, {});

            await setDoc(playerProfileRef, 
            {
                overallScore: overallScore,
                
            },
            { merge: true } // This merges with existing document instead of overwriting
            );

            
      
            const data = {
              ...filteredValues,
              createdAt: moment().unix(),
              //playerProfile: playerProfileId,
              editor: "admin",
            };
      
            if (existingDocId) {
              await db.collection("pi-scores").doc(existingDocId).update(data);
              console.log("PI Score updated successfully");
              alert("PI Score updated successfully");
            } else {
              await db.collection("pi-scores").add(data);
              console.log("PI Score submitted successfully");
              alert("PI Score submitted successfully");
            }
          } catch (error) {
            toast.error("Error submitting PI Score, please try again. If issue persists contact admin.");
            console.error("Error submitting PI Score:", error);
            alert("Error submitting PI Score, please try again.");
          } finally {
            toast.success("Evaluation submitted successfully!");
            setLoading(false);
            formik.resetForm();
            setAutoCompleteInput("");
          }
        },
      });

  useEffect(() => {
    db.collection("player-profile")
      .get()
      .then((allDocs) => {
        const allPlayerProfiles = [];
        allDocs.forEach((doc) => {
          allPlayerProfiles.push({
            fullName: `${doc.data().FirstName} ${doc.data().LastName}`,
            id: doc.id,
            dob: doc.data().DOB,
          });
        });
        setPlayerProfiles(allPlayerProfiles);
      });
  }, []);
  

  // Update fetchExistingPIScore to handle sanitized field names
  // Update fetchExistingPIScore function
  const fetchExistingPIScore = async (playerProfileId) => {
    try {
      const querySnapshot = await db.collection("pi-scores")
        .where("playerProfile", "==", playerProfileId)
        .limit(1)
        .get();
  
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const existingData = doc.data();
        
        // Get all possible fields for the stored position
        const positionKey = existingData.position || "default";
        const positionData = positionQuestions[positionKey] || positionQuestions.default;
        
        const allFields = [
          ...intangiblesQuestions,
          ...physicalToolsQuestions,
          ...positionalPhysicalQuestions,
          ...positionData.offensive,
          ...positionData.defensive
        ];
  
        // Map existing data to form values
        const formattedData = allFields.reduce((acc, q) => {
          acc[q.name] = existingData[q.name] || "";
          return acc;
        }, {
          playerProfile: existingData.playerProfile,
          position: existingData.position || "",
          overallScore: existingData.overallScore || ""
        });
  
        console.log("Mapped data:", formattedData); // Debugging log
        
        setExistingDocId(doc.id);
        setPosition(positionKey);
        formik.setValues(formattedData);
      } else {
        setExistingDocId(null);
        formik.setValues({
          ...formik.initialValues,
          playerProfile: playerProfileId
        });
        setPosition("");
      }
    } catch (error) {
      console.error("Error fetching PI Score:", error);
    }
  };

  const searchProfile = (event) => {
    const query = event.query.toLowerCase();
    const filtered = playerProfiles.filter((p) =>
      p.fullName.toLowerCase().includes(query)
    );
    setFilteredProfiles(filtered);
  };

  const itemTemplate = (item) => (
    <div>
      <b>{item.fullName}</b>
      <br />
      <code>DOB: {moment(item.dob).format("MM/DD/YYYY")}</code>
    </div>
  );

  return (
    <div>
      <Header />
      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>PI Score</h4>
            <subtitle>
              This section is used to create PI Score. Please fill all sections.{" "}
            </subtitle>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
        <Row>
        <Col md={12}>
            <div className={styles.field}>
            <p>Player Profile</p>
            <AutoComplete
                className={styles.fullWidth}
                value={autoCompleteInput}
                suggestions={filteredProfiles}
                completeMethod={searchProfile}
                field="fullName"
                itemTemplate={itemTemplate}
                dropdown
                name="playerProfile"
                onChange={(e) => {
                setAutoCompleteInput(e.value); // Update the input value
                }}
                onSelect={(e) => {
                const selectedProfile = e.value;

                if (!selectedProfile?.id) {
                    console.error("Invalid Profile ID!", selectedProfile);
                    return;
                }

                console.log("Selected Profile ID:", selectedProfile.id); // Debugging log

                // Update the input value
                setAutoCompleteInput(selectedProfile.fullName);

                // Update Formik's state
                formik.setFieldValue("playerProfile", selectedProfile.id);
                formik.setFieldTouched("playerProfile", true);

                // Trigger validation for the playerProfile field
                formik.validateField("playerProfile");

                // Set the DOB for display purposes
                setSelectedDOB(moment(selectedProfile.dob).format("MM/DD/YYYY"));

                // Fetch existing PI score for the selected player
                fetchExistingPIScore(selectedProfile.id);
                }}
                onBlur={() => {
                formik.setFieldTouched("playerProfile", true); // Mark the field as touched on blur
                formik.validateField("playerProfile"); // Trigger validation on blur
                }}
            />

            {formik.errors.playerProfile && formik.touched.playerProfile && (
                <div className={styles.errorMessage}>
                {formik.errors.playerProfile}
                </div>
            )}
            </div>
        </Col>
        </Row>

        <Row className="mb-4">
            <Col md={6}>
              <div className={styles.field}>
                <label className={styles.label}>Position</label>
                <select
                name="position"
                value={position}
                className={`${styles.fullWidth} ${styles.dropdown}`}
                onChange={(e) => {
                    const newPosition = e.target.value;
                    setPosition(newPosition);
                    formik.setFieldValue("position", newPosition);
                    
                    // Clear previous position's fields
                    const newInitialValues = {
                    ...formik.initialValues,
                    playerProfile: formik.values.playerProfile,
                    position: newPosition
                    };
                    
                    formik.setValues(newInitialValues);
                }}
                >
                  <option value="">Select Position</option>
                  <option value="Guard">Guard</option>
                  <option value="Wing">Wing</option>
                  <option value="Forward">Forward</option>
                  <option value="Big">Big</option>
                </select>
                {formik.errors.position && formik.touched.position && (
                  <div className={styles.errorMessage}>
                    {formik.errors.position}
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <h5>Intangibles</h5>
          {renderQuestions(intangiblesQuestions, formik)}

          <h5>Physical Tools/Physical Compared to Peers at Position</h5>
          {renderQuestions(physicalToolsQuestions, formik)}

          <h5>Positional/Physical Compared to Peers at Position</h5>
          {renderQuestions(positionalPhysicalQuestions, formik)}

          {currentQuestions.offensive.length > 0 && (
            <>
                <h5><b>Offensive Ability - <span style={{ backgroundColor: 'yellow' }}>{position}s Only</span></b></h5>
                <Row className="mb-4">
                {currentQuestions.offensive.map((q, index) => (
                    <Col key={q.name} md={6} className="mb-5">
                    <div className={styles.field}>
                        <label className={styles.label}>{q.label}</label>
                        <select
                        name={q.name}
                        value={formik.values[q.name]}
                        onChange={formik.handleChange}
                        className={`${styles.fullWidth} ${styles.dropdown}`}
                        >
                        <option value="">Select Score</option>
                        {scoreOptions.map((score) => (
                            <option key={score.value} value={score.value}>
                            {score.label ? `${score.value} - ${score.label}` : score.value}
                            </option>
                        ))}
                        </select>
                        {formik.errors[q.name] && formik.touched[q.name] && (
                        <div className={styles.errorMessage}>
                            {formik.errors[q.name]}
                        </div>
                        )}
                    </div>
                    </Col>
                ))}
                </Row>
            </>
            )}

            {currentQuestions.defensive.length > 0 && (
            <>
                <h5><b>Defensive Ability - <span style={{ backgroundColor: 'yellow' }}>{position}s Only</span></b></h5>
                <Row className="mb-4">
                {currentQuestions.defensive.map((q, index) => (
                    <Col key={q.name} md={6} className="mb-5">
                    <div className={styles.field}>
                        <label className={styles.label}>{q.label}</label>
                        <select
                        name={q.name}
                        value={formik.values[q.name]}
                        onChange={formik.handleChange}
                        className={`${styles.fullWidth} ${styles.dropdown}`}
                        >
                        <option value="">Select Score</option>
                        {scoreOptions.map((score) => (
                            <option key={score.value} value={score.value}>
                            {score.label ? `${score.value} - ${score.label}` : score.value}
                            </option>
                        ))}
                        </select>
                        {formik.errors[q.name] && formik.touched[q.name] && (
                        <div className={styles.errorMessage}>
                            {formik.errors[q.name]}
                        </div>
                        )}
                    </div>
                    </Col>
                ))}
                </Row>
            </>
            )}

          <Button type="submit" disabled={loading}>
            {loading ? <Spinner animation="border" /> : "Submit"}
          </Button>
        </form>
      </div>
    </div>
  );
}

