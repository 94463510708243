import { useState, useEffect } from "react";
import styles from "./Tickets.module.scss";
import Header from "../header/header";
import { FaSort, FaFilter, FaEye, FaPlus } from "react-icons/fa";
import { MdEdit, MdDelete } from "react-icons/md";
import EditModal from "./editModal";
import AddTicketModal from "./addTicketModal";
import ViewTicketModal from "./viewTicketModal";
import { db } from "../../firebase";
import { useAuth } from "../../Context/AuthContext";
import { Spinner } from "react-bootstrap"; 

export default function Tickets() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null); 
  const [ticketsTable, setTicketsTable] = useState([]);
  const [loading, setLoading] = useState(true); 
  const { currentUser } = useAuth();

  // Define custom priority order
  const priorityOrder = {
    "Highest - ASAP": 1,
    "High - 1 Week": 2,
    "Medium - 2 to 3 Weeks": 3,
    "Low - 3 to 4 Weeks": 4,
    "Lowest - 1 month+": 5,
    "Idea": 6,
  };

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        setLoading(true); 
        const snapshot = await db.collection("ticket-bugs").get();
        const tickets = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        // Sort tickets based on custom priority order
        const sortedTickets = tickets.sort((a, b) => {
          const priorityA = priorityOrder[a.priority] || 6; // Default to 6 if not in priorityOrder
          const priorityB = priorityOrder[b.priority] || 6; // Default to 6 if not in priorityOrder
          return priorityA - priorityB;
        });

        setTicketsTable(sortedTickets);
      } catch (error) {
        console.error("Error fetching tickets: ", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchTickets();
  }, []);

  const addTicket = () => {
    setOpenAddModal(true);
  };

  const editTicket = (ticket) => {
    setSelectedEdit(ticket);
    setOpenEditModal(true);
  };

  const viewTicket = (ticket) => {
    setSelectedTicket(ticket);
    setOpenViewModal(true);
  };

  const deleteTicket = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this ticket?");
    if (confirmDelete) {
      try {
        await db.collection("ticket-bugs").doc(id).delete();
        setTicketsTable(ticketsTable.filter((ticket) => ticket.id !== id));
      } catch (error) {
        console.error("Error deleting ticket: ", error);
      }
    }
  };

  const handleSave = async (updatedTicket) => {
    try {
      await db.collection("ticket-bugs").doc(updatedTicket.id).update(updatedTicket);
      setTicketsTable(ticketsTable.map((ticket) => (ticket.id === updatedTicket.id ? updatedTicket : ticket)));
    } catch (error) {
      console.error("Error updating ticket: ", error);
    }
    setOpenEditModal(false);
  };

  const handleAdd = async (newTicket) => {
    try {
      const docRef = await db.collection("ticket-bugs").add(newTicket);
      const newTicketWithId = { id: docRef.id, ...newTicket };

      // Update ticketsTable with the new ticket
      const updatedTickets = [...ticketsTable, newTicketWithId];

      // Sort tickets based on custom priority order
      const sortedTickets = updatedTickets.sort((a, b) => {
        const priorityA = priorityOrder[a.priority] || 6; // Default to 6 if not in priorityOrder
        const priorityB = priorityOrder[b.priority] || 6; // Default to 6 if not in priorityOrder
        return priorityA - priorityB;
      });

      setTicketsTable(sortedTickets); // Update state with sorted tickets
    } catch (error) {
      console.error("Error adding ticket: ", error);
    }
    setOpenAddModal(false);
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.ticketsContainer}>
        <div className={styles.title}>
          <h4>Tickets</h4>
        </div>
        <button
          className={styles.addButton}
          onClick={addTicket}
        >
          <FaPlus /> Add Ticket
        </button>
        <hr />

        {/* Show loading spinner if tickets are loading */}
        {loading ? (
          <div className={styles.loadingContainer}>
            <Spinner animation="border" />
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Ticket Date</th>
                <th>Priority</th>
                <th>Report Type</th>
                <th>Title</th>
                <th>Submitted By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {ticketsTable.map((ticket) => (
                <tr key={ticket.id}>
                  <td>{ticket.date}</td>
                  <td>{ticket.priority}</td>
                  <td>{ticket.Report}</td>
                  <td>{ticket.Title}</td>
                  <td>{ticket.submittedBy}</td>
                  <td>
                    <FaEye
                      onClick={() => viewTicket(ticket)}
                      style={{ cursor: "pointer", marginLeft: "8px" }}
                    />
                    <MdEdit
                      onClick={() => editTicket(ticket)}
                      style={{ cursor: "pointer", marginRight: "8px" }}
                    />
                    <MdDelete
                      onClick={() => deleteTicket(ticket.id)}
                      style={{ cursor: "pointer", color: "red" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Edit Ticket Modal */}
      {openEditModal && <EditModal setOpen={setOpenEditModal} onSave={handleSave} item={selectedEdit} />}

      {/* Add Ticket Modal */}
      {openAddModal && <AddTicketModal setOpen={setOpenAddModal} onAdd={handleAdd} />}

      {/* View Ticket Modal */}
      {openViewModal && selectedTicket && (
        <ViewTicketModal
          setOpen={setOpenViewModal}
          ticket={selectedTicket}
        />
      )}
    </div>
  );
}
