import Layout from "../../../components/layout/layout.js";

//import Bounty from "../../../components/componentsPage/bounty/index.js";
import cheatSheet from "../../../components/componentsPage/cheatSheet/index.js";


function CheatSheetPage({ component, ...rest }) {
  return <Layout Component={cheatSheet} {...rest} controlsDisabled={true} />;
}

export default CheatSheetPage;
