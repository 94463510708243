import { useState, useEffect } from "react";
import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { db, storage } from "../../firebase"; // Ensure Firebase storage is imported
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../Context/AuthContext"; // Assuming you have useAuth for context

export default function AddTicketModal({ setOpen, onAdd, tickets }) {
  const [ticketData, setTicketData] = useState({
    date: new Date().toLocaleDateString("en-US"), // MM/DD/YYYY format
    priority: "",
    Report: "",
    Title: "",
    submittedBy: "", // This will be populated automatically
    description: "",
    attachment: null,
  });

  const [uploading, setUploading] = useState(false);

  // Get current user's first and last name
  const { userInfo } = useAuth(); // Get userInfo from Auth context

  useEffect(() => {
    if (userInfo && userInfo.firstName && userInfo.lastName) {
      setTicketData((prevData) => ({
        ...prevData,
        submittedBy: `${userInfo.firstName} ${userInfo.lastName}`, // Automatically set submittedBy
      }));
    }
  }, [userInfo]); // Re-run when userInfo changes

  const close = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicketData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setTicketData((prevData) => ({
        ...prevData,
        attachment: e.target.files[0],
      }));
    }
  };

  const handleSave = async () => {
    setUploading(true);
    let attachmentURL = null;

    if (ticketData.attachment) {
      const storageRef = ref(storage, `bug-attachments/${ticketData.attachment.name}`);
      try {
        const snapshot = await uploadBytes(storageRef, ticketData.attachment);
        attachmentURL = await getDownloadURL(snapshot.ref);
      } catch (error) {
        console.error("Error uploading attachment:", error);
        setUploading(false);
        return;
      }
    }

    const newTicket = {
      ...ticketData,
      attachment: attachmentURL, // Store attachment URL in Firestore
    };

    // Add the new ticket and sort tickets by priority
    onAdd(newTicket);

    setUploading(false);
    close();
  };

  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>
        <div className={styles.content}>
          <h3>Add New Ticket</h3>
          <hr />
          <div className={styles.data}>
            <div>
              {/* Name field is removed, 'submittedBy' is auto-filled */}
              <span>Title</span>
              <input type="text" name="Title" value={ticketData.Title} onChange={handleChange} placeholder="Ex. Search Bar" />

              <span>Report Type</span>
              <select name="Report" value={ticketData.Report} onChange={handleChange}>
                <option value="">Select Report Type</option>
                <option value="Feature Request">Feature Request</option>
                <option value="Improvement">Improvement</option>
                <option value="Bug">Bug</option>
                <option value="Other">Other</option>
              </select>

              <span>Priority Type</span>
              <select name="priority" value={ticketData.priority} onChange={handleChange}>
                <option value="">Select Priority</option>
                <option value="Highest - ASAP">Highest - ASAP</option>
                <option value="High - 1 Week">High - 1 Week</option>
                <option value="Medium - 2 to 3 Weeks">Medium - 2 to 3 Weeks</option>
                <option value="Low - 3 to 4 Weeks">Low - 3 to 4 Weeks</option>
                <option value="Lowest - 1 month+">Lowest - 1 month+</option>
                <option value="Idea">Idea</option>
              </select>
            </div>
          </div>
          <div className={styles.desc}>
            <span>Description Note</span>
            <textarea name="description" value={ticketData.description} onChange={handleChange} placeholder="Explain the feature you want or the bug that was found"></textarea>
          </div>

          <div>
            <span>Upload Attachment</span>
            <span> (optional) </span>
            <div className={styles.dropzone}>
              <input type="file" onChange={handleFileChange} accept=".png,.jpg,.pdf,.doc,.docx" />
              <span>Upload smaller than 3MB</span>
            </div>
          </div>
        </div>
        <div className={styles.btns}>
          <button onClick={close} disabled={uploading}>Cancel</button>
          <button onClick={handleSave} disabled={uploading}>{uploading ? "Uploading..." : "Save"}</button>
        </div>
      </div>
    </div>
  );
}
