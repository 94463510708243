import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.scss";
import moment from "moment";
import { db } from "../../firebase";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Modal, Dropdown, Menu, Table, Spin } from "antd";
import { EllipsisOutlined } from '@ant-design/icons';
import { LoadingOutlined } from "@ant-design/icons";
import { FaEye } from "react-icons/fa";
import { useAuth } from "../../Context/AuthContext";

export default function ActivityFeed() {
  const [activityFeed, setActivityFeed] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [bountyData, setBountyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [activitySummary, setActivitySummary] = useState(null);
  const [bountyModalVisible, setBountyModalVisible] = useState(false); 
  const [activityTypes, setActivityTypes] = useState([]);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [bountyLoading, setBountyLoading] = useState(false); 
  const [viewModalData, setViewModalData] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);
  const { userInfo } = useAuth();

  // attempt to pull id from player profile
  const [playerIdMap, setPlayerIdMap] = useState({});
  
  
  const [descriptionModalData, setDescriptionModalData] = useState(null);

  useEffect(() => {
    let isMounted = true;
  
    const fetchMissingPlayerIds = async () => {
      const newMap = { ...playerIdMap };
      let hasUpdates = false;
  
      for (const item of activityFeed) {
        if (!item.playerID && item.player) {
          const playerName = item.player;
          if (!(playerName in newMap)) {
            try {
              const querySnapshot = await db.collection("player-profile")
                .where("Title", "==", playerName)
                .limit(1)
                .get();
  
              if (!isMounted) return;
  
              if (!querySnapshot.empty) {
                newMap[playerName] = querySnapshot.docs[0].id;
              } else {
                newMap[playerName] = null;
              }
              hasUpdates = true;
            } catch (error) {
              console.error("Error fetching player ID:", error);
              newMap[playerName] = null;
              hasUpdates = true;
            }
          }
        }
      }
  
      if (hasUpdates && isMounted) {
        setPlayerIdMap(newMap);
      }
    };
  
    fetchMissingPlayerIds();
  
    return () => {
      isMounted = false;
    };
  }, [activityFeed]); // eslint-disable-line react-hooks/exhaustive-deps

  

  const getActivityFeed = (loadMore = false) => {
    if (loadMore) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    let query = db.collection("activity")
      .orderBy("timestamp", "desc")
      .limit(20);

    if (loadMore && lastVisible) {
      query = query.startAfter(lastVisible);
    }

    query.get().then((snapshot) => {
      const feed = snapshot.docs.map((doc) => ({ ...doc.data() }));
      const lastDoc = snapshot.docs[snapshot.docs.length - 1];

      setActivityFeed((prevFeed) => loadMore ? [...prevFeed, ...feed] : feed);
      setLastVisible(lastDoc);
      setHasMore(snapshot.docs.length === 20);
      setLoading(false);
      setLoadingMore(false);
    });
  };

  const handleBountyBoardOpen = () => {
    fetchBounties(); // Fetch bounties when the modal opens
    setBountyModalVisible(true);
  };

  const fetchBounties = async () => {
    setLoading(true);
    try {
      const response = await db.collection("bounties").get();
      const data = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      const sortedData = data.sort((a, b) => {
        const progressOrder = {
          "Not Started": 1,
          "In Progress": 2,
          Completed: 3,
        };
  
        if (progressOrder[a.progress] !== progressOrder[b.progress]) {
          return progressOrder[a.progress] - progressOrder[b.progress];
        }
  
        const dateA = new Date(a.createdAt || 0);
        const dateB = new Date(b.createdAt || 0);
        return dateA - dateB;
      });
  
      setBountyData(sortedData);
    } catch (error) {
      console.error("Error fetching bounties: ", error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    getActivityFeed();
  }, []);

  const loadMore = () => {
    if (hasMore) {
      getActivityFeed(true);
    }
  };

  const fetchActivitySummary = () => {
    setModalVisible(true);
    setLoadingSummary(true);

    db.collection("activity")
      .get()
      .then((snapshot) => {
        const summary = {};
        const types = new Set();
        snapshot.forEach((doc) => {
          const data = doc.data();
          const normalizedEditor = data.editor === "Mikey Visenberg" ? "Michael Visenberg" : data.editor;
          types.add(data.desc);

          if (!summary[normalizedEditor]) {
            summary[normalizedEditor] = {};
          }
          if (!summary[normalizedEditor][data.desc]) {
            summary[normalizedEditor][data.desc] = 0;
          }

          summary[normalizedEditor][data.desc]++;
        });

        setActivitySummary(summary);
        setActivityTypes(Array.from(types));
      })
      .finally(() => {
        setLoadingSummary(false);
      });
  };

  const activityTableData = activitySummary
    ? Object.keys(activitySummary).map((editor) => {
        const editorData = { key: editor, editor };
        let totalEdits = 0;
        activityTypes.forEach((type) => {
          editorData[type] = activitySummary[editor][type] || 0;
          totalEdits += editorData[type];
        });
        editorData.totalEdits = totalEdits;
        return editorData;
      }).sort((a, b) => b.totalEdits - a.totalEdits)
    : [];

  const totalRow = activityTableData.reduce((totals, row) => {
    activityTypes.forEach((type) => {
      totals[type] = (totals[type] || 0) + row[type];
    });
    totals.totalEdits = (totals.totalEdits || 0) + row.totalEdits;
    return totals;
  }, { editor: "Total" });

  activityTableData.push(totalRow);

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key || !sortConfig.direction) {
      return activityTableData;
    }
  
    // Exclude totals row from sorting
    const totalsRowIndex = activityTableData.findIndex((row) => row.editor === "Total");
    const rowsWithoutTotal = totalsRowIndex === -1 ? activityTableData : activityTableData.slice(0, totalsRowIndex).concat(activityTableData.slice(totalsRowIndex + 1));
  
    const sortedRows = [...rowsWithoutTotal].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  
    // Add the totals row at the end of the sorted rows
    if (totalsRowIndex !== -1) {
      sortedRows.push(activityTableData[totalsRowIndex]);
    }
  
    return sortedRows;
  }, [activityTableData, sortConfig]);
  

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      let direction = "asc";
      if (prevConfig.key === key && prevConfig.direction === "asc") {
        direction = "desc";
      } else if (prevConfig.key === key && prevConfig.direction === "desc") {
        direction = null;
      }
      return { key, direction };
    });
  };

  const handleRequestAssignment = async (bountyId, bountyTitle) => {
    setRequestLoading(true);
    try {
      // Send the request to Firestore (adjust path/structure as needed)
      await db.collection("bounty-requests").add({
        bountyId,
         bountyTitle: bountyTitle, // Include bountyTitle
        requestedBy: `${userInfo.firstName} ${userInfo.lastName}`,
        status: "Pending",
        requestedAt: new Date(),
      });
  
      alert("Assignment requested successfully!");
    } catch (error) {
      console.error("Error requesting assignment:", error);
    } finally {
      setRequestLoading(false);
    }
  };
  

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={fetchActivitySummary}>
        Activity Tracker
      </Menu.Item>
      
      <Menu.Item key="bountyBoard" onClick={handleBountyBoardOpen}>
        Bounty Board
      </Menu.Item>
    </Menu>
  );

  const groupedColumns = [
    {
      title: (
        <span onClick={() => handleSort('editor')} style={{ cursor: 'pointer' }}>
          Editor {sortConfig.key === 'editor' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
        </span>
      ),
      dataIndex: 'editor',
      key: 'editor',
      fixed: 'left',
      width: 150,
    },
    {
      title: 'Added',
      children: [
        {
          title: (
            <span onClick={() => handleSort('New Player Profile Added')} style={{ cursor: 'pointer' }}>
              Player Profile {sortConfig.key === 'New Player Profile Added' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'New Player Profile Added',
          key: 'New Player Profile Added',
          className: styles.addedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('New General Report Added')} style={{ cursor: 'pointer' }}>
              General Report {sortConfig.key === 'New General Report Added' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'New General Report Added',
          key: 'New General Report Added',
          className: styles.addedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('New Scouting Report Added')} style={{ cursor: 'pointer' }}>
              Scouting Report {sortConfig.key === 'New Scouting Report Added' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'New Scouting Report Added',
          key: 'New Scouting Report Added',
          className: styles.addedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('New Intel Report Added')} style={{ cursor: 'pointer' }}>
              Intel Report {sortConfig.key === 'New Intel Report Added' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'New Intel Report Added',
          key: 'New Intel Report Added',
          className: styles.addedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('New Medical Report Added')} style={{ cursor: 'pointer' }}>
              Medical Report {sortConfig.key === 'New Medical Report Added' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'New Medical Report Added',
          key: 'New Medical Report Added',
          className: styles.addedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('New Background Overview Added')} style={{ cursor: 'pointer' }}>
              Background Overview {sortConfig.key === 'New Background Overview Added' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'New Background Overview Added',
          key: 'New Background Overview Added',
          className: styles.addedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('Scouting Overview Added')} style={{ cursor: 'pointer' }}>
              Scouting Overview {sortConfig.key === 'Scouting Overview Added' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'Scouting Overview Added',
          key: 'Scouting Overview Added',
          className: styles.addedRow,
        },
      ],
    },
    {
      title: 'Edited',
      children: [
        {
          title: (
            <span onClick={() => handleSort('Player Profile was Edited')} style={{ cursor: 'pointer' }}>
              Player Profile {sortConfig.key === 'Player Profile was Edited' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'Player Profile was Edited',
          key: 'Player Profile was Edited',
          className: styles.editedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('General Report was Edited')} style={{ cursor: 'pointer' }}>
              General Report {sortConfig.key === 'General Report was Edited' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'General Report was Edited',
          key: 'General Report was Edited',
          className: styles.editedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('Scouting Report was Edited')} style={{ cursor: 'pointer' }}>
              Scouting Report {sortConfig.key === 'Scouting Report was Edited' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'Scouting Report was Edited',
          key: 'Scouting Report was Edited',
          className: styles.editedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('Intel Report was Edited')} style={{ cursor: 'pointer' }}>
              Intel Report {sortConfig.key === 'Intel Report was Edited' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'Intel Report was Edited',
          key: 'Intel Report was Edited',
          className: styles.editedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('Medical Report was Edited')} style={{ cursor: 'pointer' }}>
              Medical Report {sortConfig.key === 'Medical Report was Edited' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'Medical Report was Edited',
          key: 'Medical Report was Edited',
          className: styles.editedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('Background Overview Was Edited')} style={{ cursor: 'pointer' }}>
              Background Overview {sortConfig.key === 'Background Overview Was Edited' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'Background Overview Was Edited',
          key: 'Background Overview Was Edited',
          className: styles.editedRow,
        },
        {
          title: (
            <span onClick={() => handleSort('Scouting Overview Was Edited')} style={{ cursor: 'pointer' }}>
              Scouting Overview {sortConfig.key === 'Scouting Overview Was Edited' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
            </span>
          ),
          dataIndex: 'Scouting Overview Was Edited',
          key: 'Scouting Overview Was Edited',
          className: styles.editedRow,
        },
      ],
    },
    {
      title: (
        <span onClick={() => handleSort('totalEdits')} style={{ cursor: 'pointer' }}>
          Total Activity {sortConfig.key === 'totalEdits' && (sortConfig.direction === 'asc' ? '↑' : sortConfig.direction === 'desc' ? '↓' : '')}
        </span>
      ),
      dataIndex: 'totalEdits',
      key: 'totalEdits',
    },
  ];
  return (
    <div className={styles.feed}>
      <div className={styles.topBar}>
        <p>Activity Feed</p>
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight" className={styles.threeDotMenu}>
          <EllipsisOutlined className={styles.icon} />
        </Dropdown>
      </div>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Player</th>
              <th>Type</th>
              <th>Editor</th>
            </tr>
          </thead>
          {loading ? (
            <tbody>
              {[...Array(5)].map((_, index) => (
                <tr key={index} className={styles.skeletonRow}>
                  <td colSpan="5">
                    <div className={styles.skeletonCell}></div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              {activityFeed.map((item, index) => (
                <tr key={index}>
                  <td>{moment.unix(item.timestamp).format("l")}</td>
                  <td>{moment.unix(item.timestamp).format("hh:mm:ss A")}</td>
                  <td>
                  {item.playerID ? (
                    <Link to={`/player-profile/detail/${item.playerID}`}>
                      {item.player}
                    </Link>
                  ) : playerIdMap[item.player] ? (
                    <Link to={`/player-profile/detail/${playerIdMap[item.player]}`}>
                      {item.player}
                    </Link>
                  ) : (
                    item.player
                  )}
                  </td>
                  <td>{item.desc.replace(/\bwas\b/gi, '').replace(/\s+/g, ' ').trim()}</td>
                  <td>{item.editor}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>

        <div className={styles.centeredButton}>
          {loadingMore ? (
            <p>Loading more...</p>
          ) : (
            <button onClick={loadMore}>Load More</button>
          )}
        </div>
      </div>

       {/* Activity Tracker Summary Modal */}
       <Modal
        title="Activity Tracker Summary"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={1000}
        bodyStyle={{ padding: 0 }}
      >
        {loadingSummary ? (
          <div className={styles.spinnerContainer}>
            <Spin
              size="large"
              indicator={<LoadingOutlined style={{ fontSize: 60, color: "black" }} spin />}
            />
          </div>
        ) : (
          <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
            <Table
              columns={groupedColumns}
              dataSource={sortedData}
              pagination={false}
              rowKey="key"
              size="middle"
              scroll={{ x: 'max-content' }}
            />
          </div>
        )}
      </Modal>

      {/* Bounty Board Modal */}
      <Modal
        title="Bounty Board"
        visible={bountyModalVisible}
        onCancel={() => setBountyModalVisible(false)}
        footer={null}
        width={1000}
        bodyStyle={{ padding: 0 }}
      >
        {loading ? (
          <div className={styles.spinnerContainer}>
            <Spin
              size="large"
              indicator={<LoadingOutlined style={{ fontSize: 60, color: "black" }} spin />}
            />
          </div>
        ) : (
          <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Bounty</th>
                  <th>Description</th>
                  <th>Reward</th>
                  <th>Assigned Editor</th>
                  <th>Progress</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {bountyData.map((bounty) => (
                  <tr
                    key={bounty.id}
                    className={
                      bounty.progress === "Completed" ? styles.completedRow : ""
                    }
                  >
                    <td>{bounty.title}</td>
                    <td>
                      {bounty.description.length > 100 ? (
                        <>
                          {bounty.description.slice(0, 100)}...
                          <span
                            className={styles.readMore}
                            onClick={() => setDescriptionModalData(bounty)}
                          >
                            Read more
                          </span>
                        </>
                      ) : (
                        bounty.description
                      )}
                    </td>
                    <td>{bounty.reward}</td>
                    <td>
                    {bounty.assignedEditor ? (
                      bounty.assignedEditor
                    ) : bounty.progress === "Completed" ? (
                      <span className={`${styles.requestLink} ${styles.disabled}`}>
                        Request Assignment
                      </span>
                    ) : (
                      <span
                        className={styles.requestLink}
                        onClick={() => handleRequestAssignment(bounty.id, bounty.title)}
                        disabled={requestLoading}
                      >
                        Request Assignment
                      </span>
                    )}
                    </td>
                    <td>{bounty.progress || "Not Started"}</td>
                    <td className={styles.iconCell}>
                    <FaEye
                      className={styles.viewIcon}
                      onClick={() => setViewModalData(bounty)}
                    />
                  </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Read More Modal */}
            {descriptionModalData && (
              <div className={styles.modalContainer}>
                <div className={styles.modalContent}>
                  <h4>Full Description</h4>
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    {descriptionModalData.description}
                  </p>
                  <button onClick={() => setDescriptionModalData(null)}>Close</button>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>
   
    {/* View Modal */}
    {viewModalData && (
        <Modal
          title={viewModalData.title}
          visible={!!viewModalData}
          onCancel={() => setViewModalData(null)}
          footer={null}
        >
          <p>
            <strong>Description:</strong>
          </p>
          <p style={{ whiteSpace: "pre-wrap" }}>{viewModalData.description}</p>
          <p>
            <strong>Reward:</strong> {viewModalData.reward}
          </p>
          <p>
            <strong>Assigned Editor:</strong>{" "}
            {viewModalData.assignedEditor || "Unassigned"}
          </p>
          <p>
            <strong>Progress:</strong> {viewModalData.progress || "Not Started"}
          </p>
        </Modal>
      )}
   
   
    </div>
  );
}
