import React, { useState, useEffect } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import defaultImage from "../../Assets/images/pp_Logo.jpg";

// styles
import styles from "./preview.module.scss";
import { PlayerReports } from "./reports";
import { SelectCompareProfile } from "./select-compare-profile";

// icons
import {
  IoSwapHorizontalOutline,
  IoChevronBackCircleOutline,
  IoStarOutline,
  IoStarSharp,
} from "react-icons/io5";

//import { AiFillBulb } from "react-icons/ai";

import moment from "moment";

import {
  //   BodyDescCol,
  BodyTitle,
  BodyColList,
  BodySocial,
  BodyList,
} from "./sections";

// router
import { useHistory } from "react-router-dom";
import { auth, db } from "../../firebase";
import { useAuth } from "../../Context/AuthContext";

export const PreviewDetails = ({
  playerProfileData,
  bgOverviewData,
  scoutingOverviewData,
  id,
  compare,
}) => {

  const { isAdmin, isSuperAdmin, getUserInfoData } = useAuth();
  console.log(playerProfileData, "playerProfileData");
  const history = useHistory();

  const [compareModal, setCompareModal] = useState(false);
  const [isWatchListed, setIsWatchListed] = useState(false);
  const [reportsData, setReports] = useState([]);

  const getPlayerReports = async () => {
    try {
      const response = await db
        .collection("reports")
        .where("playerProfile", "==", id)
        .get();
      let data = response.docs.map((report) => ({
        ...report.data(),
        id: report.id,
        playerName: `${playerProfileData.FirstName} ${playerProfileData.LastName}`,
      }));
      console.log(data);
      setReports(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleWatchList = async () => {
    console.log("add to watch list");

    const playerDocRef = db.collection("watchlist").doc(id);
    const playerDoc = await playerDocRef.get();
    if (playerDoc.exists) {
      const docData = playerDoc.data();
      if (docData.subscribers.includes(auth.currentUser.uid)) {
        // remove from watchlist
        await playerDocRef.update({
          subscribers: docData.subscribers.filter(
            (sub) => sub !== auth.currentUser.uid
          ),
        });
        setIsWatchListed(false);
      } else {
        // add to watchlist
        await playerDocRef.update({
          subscribers: [...docData.subscribers, auth.currentUser.uid],
        });
        setIsWatchListed(true);
      }
    } else {
      await playerDocRef.set({
        subscribers: [auth.currentUser.uid],
      });
      setIsWatchListed(true);
    }
  };

  //!TODO: disable the button while the firebase transaction is in progress

  useEffect(() => {
    document.title = `${playerProfileData.FirstName} ${playerProfileData.LastName}`;
    getPlayerReports();
  }, []);
  useEffect(() => {
    async function fetchWatchList() {
      // fetch from firebase db
      // id of player
      const document = await db.collection("watchlist").doc(id).get();
      if (document.exists) {
        const docData = document.data();
        if (docData.subscribers.includes(auth.currentUser.uid)) {
          setIsWatchListed(true);
        }
      }
    }
    fetchWatchList();
  }, [id]);

  const buyOutData =
    bgOverviewData && bgOverviewData.IBO
      ? {
          BuyOutInfo: {
            title: "Buy-Out Information",
            list: bgOverviewData.buyOutInfo.terms,
          },
        }
      : {};

      const Awards = bgOverviewData
      ? { 
        Awards: {
          title: "Awards & Honors" ,
          list: bgOverviewData.awards.map((item) =>
            item.year === "" ? "" : `${item.year}: ${item.awards}`
          ),
        },
        }
      : {};

  const natioalTeamExp = bgOverviewData
    ? { 
 
      nationalTeam: {
        title: `National Team Experience (${bgOverviewData.nationalTeamExperience.country})` ,
        list: bgOverviewData.nationalTeamExperience.experiences.map((item) =>
          item.year === "" ? "" : `${item.year}: ${item.experience}`
        ),
      },
      }
    : {};

    //console.log("testing: " + bgOverviewData.recruitment.notableVisits);
  const recruitmentData = bgOverviewData
    ? { 
        NotableVisits: {
          title: "Notable Visits",
          list: bgOverviewData.recruitment.notableVisits,
        },
        NotableOffers: {
          title: "Notable Offers",
          list: bgOverviewData.recruitment.notableOffers,
        },
        NotableInterests: {
          title: "Notable Interest",
          list: bgOverviewData.recruitment.notableInterests,
        },
      }
    : {};
    const personalData = bgOverviewData
    ? {
        family: {
          title: "Family/Circle",
          list: bgOverviewData.personal.familyCircleMembers.map((item) =>
            item.member === "" || item.member === "---" || item.member === "--"
              ? item.note
              : `${item.member}: ${item.note}`
          ),
        },
        nugget: {
          title: "Nugget(s)",
          list: bgOverviewData.personal.nuggets,
        },
      }
    : {};
  const socialData = bgOverviewData
    ? {
        Twitter: {
          title: "Twitter",
          social: {
            tag:
              bgOverviewData.socialMedia.twitter.account === ""
                ? ""
                : `@${bgOverviewData.socialMedia.twitter.account}`,
            followers: `Followers:`,
          },
        },
        Instagram: {
          title: "Instagram",
          social: {
            tag:
              bgOverviewData.socialMedia.instagram.account === ""
                ? ""
                : `@${bgOverviewData.socialMedia.instagram.account}`,
            followers: `Followers:`,
          },
        },
      }
    : {};

    const commitmentData = bgOverviewData?.recruitment.commitment;
    const monthData = bgOverviewData?.recruitment.month;
    const yearData = bgOverviewData?.recruitment.year;

    console.log(bgOverviewData, "Background Overview Data");

  return (
    <div
      style={{
        marginLeft: compare ? "5px" : "0px",
      }}
    >
      {compareModal && (
        <SelectCompareProfile
          id={id}
          handleClose={() => setCompareModal(false)}
        />
      )}

      {/* Header Section */}
      <Row
        className="mt-4 "
        style={{
          borderRadius: "10px 10px 0px 0px",
          overflow: "hidden",
          marginRight: "0px",
        }}
      >
        <Col
          md={7}
          style={{
            position: "relative",
            paddingRight: "0px",
          }}
        >
          <div
            className={styles.headerInfoPicture}
            style={{
              backgroundImage: `url(${playerProfileData.Headshot || defaultImage})`, // Use default header image if Headshot is not available

              backgroundSize: "cover",
            }}
          ></div>

          <div className={styles.headerInfo}>
            <div>
              <IoChevronBackCircleOutline
                onClick={() => history.goBack()}
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              />
            </div>
            <div>
              <h2>
                {playerProfileData.FirstName}
                <br />
                {playerProfileData.LastName}
              </h2>            
              <h4 style={{color: "white"}}>
                {playerProfileData.hometown_City && playerProfileData.hometown_State_Country ?
                  `${playerProfileData.hometown_City}, ${playerProfileData.hometown_State_Country}` :
                  playerProfileData.hometown_City || playerProfileData.hometown_State_Country}
              </h4>
              <h4 style={{ color: "white" }}>
                {(playerProfileData.Team === "Transfer Portal" && (isAdmin() || isSuperAdmin())) ? (
                  playerProfileData.Team
                ) : (playerProfileData.Team !== "Transfer Portal" && playerProfileData.Team)}
              </h4>
            </div>
          </div>
        </Col>
        <Col md={5} className={styles.headerInfoDetail}>
          <div className={styles.innerDiv}>
            <Row>
              <Col className="text-center">
                <div className={styles.middleCard}>
                  <p>P.I. Score</p>
                  <h4>Coming Soon</h4>
                  {/*
                  <h4>
                    {playerProfileData.Piscore === ""
                      ? "-"
                      : playerProfileData.Piscore}
                  </h4>
                  */}
                </div>
              </Col>
              <Col md={6} className="text-center">
                <div className={styles.middleCard}>
                  <p>Position</p>
                  <h4>
                    {playerProfileData.Position === ""
                      ? "-"
                      : playerProfileData.Position}
                  </h4>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="text-center">
                <div className={styles.Card}>
                  <p>Height</p>
                  <h4>
                    {playerProfileData.Height === ""
                      ? "-"
                      : playerProfileData.Height}
                  </h4>
                </div>
              </Col>
              <Col md={6} className="text-center">
                <div className={styles.Card}>
                  <p>Weight</p>
                  <h4>
                    {playerProfileData.Weight === ""
                      ? "-"
                      : playerProfileData.Weight}
                  </h4>
                </div>
              </Col>
              <Col md={6}>
                <div className={styles.middleCard}>
                  <p>Wingspan</p>
                  <h4>
                    {playerProfileData.Wingspan === ""
                      ? "-"
                      : playerProfileData.Wingspan}
                  </h4>
                </div>
              </Col>
              <Col md={6}>
                <div className={styles.middleCard}>
                  <p>Hand</p>
                  <h4>
                    {playerProfileData.Hand === ""
                      ? "-"
                      : playerProfileData.Hand}
                  </h4>
                </div>
              </Col>
              <Col md={6} className="text-center">
                <div className={styles.Card}>
                  <p>DOB</p>
                  <h4>{playerProfileData.DOB ? moment(playerProfileData.DOB).format("l") : '--'}</h4>
                  
                </div>
              </Col>
              <Col md={6} className="text-center">
                <div className={styles.Card}>
                  <p>Age</p>
                  <h4>
                    {playerProfileData.Age !== 0.0 && !isNaN(parseFloat(playerProfileData.Age))
                      ? parseFloat(playerProfileData.Age).toFixed(1)
                      : '--'}
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <div className={styles.lastCard}>
                  <p>HS Class</p>
                  <h4>
                    {playerProfileData.Year}
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
              <div className={styles.lastCard}>
                <p>Projection Range</p>
                <h4>
                  {playerProfileData.pRange && playerProfileData.Prlow !== "" && playerProfileData.Prhigh !== ""
                    ? (playerProfileData.Prlow === playerProfileData.Prhigh
                        ? playerProfileData.Prhigh
                        : `${playerProfileData.Prlow} to ${playerProfileData.Prhigh}`)
                    : "-"}
                </h4>
              </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <div className={styles.lastCard}>
                  <p>AAU TEAM</p>
                  <h4>
                    {playerProfileData.aauTeam === ""
                      ? "-"
                      : playerProfileData.aauTeam}
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <div className={styles.lastCard}>
                  <p>Recruitment Status</p>
                  <h4>
                    {playerProfileData.Status === ""
                      ? "-"
                      : playerProfileData.Status}
                    {playerProfileData.committedTeam === ""
                      ? ""
                      : `: ${playerProfileData.committedTeam}`}
                  </h4>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* Compare section */}
      <div className={styles.compareSection}>
        <Row>
          
          <Col md={6}>
            <button onClick={() => setCompareModal(true)}>
              <IoSwapHorizontalOutline />
              <span>Compare</span>
            </button>
          </Col>
          
          <Col md={6}>
            <button className={styles.watchButton} onClick={handleWatchList}>
              {isWatchListed ? (
                <>
                  <IoStarSharp />
                  <span>Remove from Watchlist</span>
                </>
              ) : (
                <>
                  <IoStarOutline />
                  <span>Add to Watchlist</span>
                </>
              )}
            </button>
          </Col>
        </Row>
      </div>
      {/* accordition sections */}
      <Accordion defaultActiveKey="0" className={styles.accordionSection}>
        {/* accordition sections 
        <Accordion.Item eventKey="0">
          <Accordion.Header>AT A GLANCE</Accordion.Header>
          <Accordion.Body>
            Zion Lateef Williamson (born July 6, 2000) is an American
            professional basketball player for the New Orleans Pelicans of the
            National Basketball Association (NBA). He plays the power forward
            position.[1] Following a freshman-year stint with the Duke Blue
            Devils, Williamson was selected by the Pelicans with the first
            overall pick in the 2019 NBA draft. He was named to the NBA
            All-Rookie First Team in 2020. In 2021, he became the 4th youngest
            NBA player to be selected
          </Accordion.Body>
        </Accordion.Item>
        */}
        {bgOverviewData && (
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              {bgOverviewData.IBO
                ? "INT'L BACKGROUND OVERVIEW"
                : "BACKGROUND OVERVIEW"}
            </Accordion.Header>
            <Accordion.Body>
            {bgOverviewData.teamHistory?.length > 0 && (
              <>
              <BodyTitle title="Playing History" />
              <h2 className={styles.grayTitle}>Team History</h2>
              <div className={styles.table}>
                <table>
                  <thead>
                    <tr>
                      <th>Year</th>
                      <th>Team</th>
                      <th>Location</th>
                      <th>Coach</th>
                      <th>Phone</th>
                      <th>e-mail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bgOverviewData.teamHistory.map((th) => (
                      <tr>
                        <td>{th.year}</td>
                        <td>{th.school || th.team}</td>
                        <td >{th.country || th.state}</td>
                        <td>
                          {th.coachFirstName} {th.coachLastName}
                        </td>
                        <td>{th.phone}</td>
                        <td>{th.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </>
              )}


              {playerProfileData.aauTeam && ( // Check if aauTeam is not empty or blank
                  <>
                    <h2 className={styles.grayTitle}>AAU</h2>
                    <div className={styles.table}>
                      <table>
                        <thead>
                          <tr>
                            <th colSpan={4}>Team</th>
                            <th colSpan={2}></th>
                            <th>Affiliation</th>
                            <th>Coach</th>
                            <th>Phone</th>
                            <th>e-mail</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                          <td colSpan={4}>{playerProfileData.aauTeam}</td>
                          <td colSpan={2}></td>
                            
                          <td>{playerProfileData.aauAffil}</td>
                            <td>
                              {playerProfileData.aauCoach} {playerProfileData.aauCoachLast}
                            </td>
                            <td>{playerProfileData.aauCoachInfo}</td>
                            <td>{playerProfileData.aauCoachEmail}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}


              <>                  
                  <BodyColList data={natioalTeamExp}/>
              </>
              <>                  
                  <BodyColList data={Awards}/>
              </>
              {/* <BodyDescCol data={getPlayerHistory()} /> */}
              {bgOverviewData.IBO && bgOverviewData.buyOutInfo?.terms?.length > 0 ? (
                <>
                  <BodyTitle title="Buy-Out Information" />
                  <BodyList data={buyOutData} cols={2} />
                </>
              ) : (
                  

                <>
                  {(commitmentData ||
                    bgOverviewData.recruitment.notableVisits.some(item => item !== "") ||
                    bgOverviewData.recruitment.notableOffers.some(item => item !== "") ||
                    bgOverviewData.recruitment.notableInterests.some(item => item !== "") ||
                    bgOverviewData.recruitment.notes) && (
                      <BodyTitle title="Recruitment" />
                  )}
                  
                  {commitmentData ? (
                    <div>
                      <h2 className={styles.grayTitle}>Commitment</h2>
                      <div className={styles.table}>
                        <table>
                          <thead>
                            <tr>
                              <th>Team</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{commitmentData}</td>
                              <td>
                                {monthData} {yearData}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}

                  <>
                  {bgOverviewData.recruitment.notableVisits.length > 0 && (
                      <span>
                        {bgOverviewData.recruitment.notableVisits.filter(item => item !== "").length > 0 && (
                          <h2 className={styles.grayTitle}>Visits</h2>
                        )}

                          <Row>
                          
                            <h5>
                              {bgOverviewData.recruitment.notableVisits.filter(item => item !== "").length > 0 && (
                                <span style={{ marginRight: "10px", marginLeft: "20px"}}   role="img" aria-label="bulb">💡</span>
                              )}
                              {bgOverviewData.recruitment.notableVisits.filter(item => item !== "").join(", ")}
                            </h5>
                           
                          </Row>
                        
                      </span>
                    )}

                    {bgOverviewData.recruitment.notableOffers.length > 0 && (
                      <span>
                        {bgOverviewData.recruitment.notableOffers.filter(item => item !== "").length > 0 && (
                          <h2 className={styles.grayTitle}>Offers</h2>
                        )}
                        <Row>
                          <h5>
                            {bgOverviewData.recruitment.notableOffers.filter(item => item !== "").length > 0 && (
                              <span style={{ marginRight: "10px", marginLeft: "20px"}}  role="img" aria-label="bulb">💡</span>
                            )}
                            {bgOverviewData.recruitment.notableOffers.filter(item => item !== "").join(", ")}
                          </h5>
                        </Row>
                      </span>
                    )}

                    {bgOverviewData.recruitment.notableInterests.length > 0 && (
                      <span>
                        {bgOverviewData.recruitment.notableInterests.filter(item => item !== "").length > 0 && (
                          <h2 className={styles.grayTitle}>Interest</h2>
                        )}
                        <Row>
                          <h5>
                            {bgOverviewData.recruitment.notableInterests.filter(item => item !== "").length > 0 && (
                              <span style={{ marginRight: "10px", marginLeft: "20px"}}   role="img" aria-label="bulb">💡</span>
                            )}
                            {bgOverviewData.recruitment.notableInterests.filter(item => item !== "").join(", ")}
                          </h5>
                        </Row>
                      </span>
                    )}
                </>
                
                {bgOverviewData.recruitment.notes && (
                  <>
                    <h2 className={styles.grayTitle}>Notes</h2>
                    <div
                    style={{
                      marginLeft: "20px",
                      fontSize: "21px", // Matches h5 font size
                      fontWeight: "500", // Matches h5 font weight
                      lineHeight: "1.5", // Matches h5 line height
                      whiteSpace: "pre-wrap", // Preserves line breaks
                    }}
                    >{bgOverviewData.recruitment.notes}
                    </div>
                  </>
                )}
                
                </>
              )}

              
              {(personalData.family?.list?.length > 0 || personalData.nugget?.list?.length > 0) && (
                <>
                  <BodyTitle title="Personal" />
                  <BodyList data={personalData} />
                </>
              )}
                            
             
              
              {/*
              <BodyTitle title="Social" />
              <BodySocial data={socialData} />
              */}
              {bgOverviewData.additionalNotes && (
                <>
                  <BodyTitle title="Notes" />
                  <div
                    style={{
                      marginLeft: "20px",
                      fontSize: "21px", // Matches h5 font size
                      fontWeight: "500", // Matches h5 font weight
                      lineHeight: "1.5", // Matches h5 line height
                      whiteSpace: "pre-wrap", // Preserves line breaks
                    }}
                  >
                    {bgOverviewData.additionalNotes}
                  </div>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        )}
        {scoutingOverviewData && (
          <Accordion.Item eventKey="3">
            <Accordion.Header>SCOUTING OVERVIEW</Accordion.Header>
            <Accordion.Body>
              {(scoutingOverviewData.projections.Projections.list.length > 0 ||
                scoutingOverviewData.roles.Roles.list.length > 0) && (
                <>
                  <BodyTitle title="Outlook" />
                  {scoutingOverviewData.projections.Projections.list.length >
                    0 && <BodyList data={scoutingOverviewData.projections} />}
                  {scoutingOverviewData.roles.Roles.list.length > 0 && (
                    <BodyList data={scoutingOverviewData.roles} />
                  )}
                </>
              )}

              {scoutingOverviewData.strengths.Strengths.list.length > 0 && (
                <>
                  <BodyTitle title="Strengths" />
                  <BodyList data={scoutingOverviewData.strengths} />
                </>
              )}
              {scoutingOverviewData.weaknesses.Weaknesses.list.length > 0 && (
                <>
                  <BodyTitle title="Weaknesses" />
                  <BodyList data={scoutingOverviewData.weaknesses} />
                </>
              )}
              {scoutingOverviewData.bottomLine.length > 0 && (
                <>
                  <BodyTitle title="Bottom Line" />
                  <div
                    style={{
                      marginLeft: "20px",
                      fontSize: "21px", // Matches h5 font size
                      fontWeight: "500", // Matches h5 font weight
                      lineHeight: "1.5", // Matches h5 line height
                      whiteSpace: "pre-wrap", // Preserves line breaks
                    }}>{scoutingOverviewData.bottomLine}
                    </div>
                </>
              )}
              {scoutingOverviewData.questions.Questions.list.length > 0 && (
                <>
                  <BodyTitle title="Questions" />
                  <BodyList data={scoutingOverviewData.questions} />
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        )}
        
        {playerProfileData.Instagram !== "" && bgOverviewData && (
  <Accordion.Item eventKey="4">
    <Accordion.Header>SOCIAL MEDIA</Accordion.Header>
    <Accordion.Body>
      {playerProfileData.Instagram !== "" && (
        <>
          <BodyTitle title="Instagram" />
          <BodyColList
            data={{
              Instagram: {
                list: [
                  <a href={`https://www.instagram.com/${playerProfileData.Instagram}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                    @{playerProfileData.Instagram}
                  </a>,
                  // Conditional rendering of "Account is" line
                  bgOverviewData.socialMedia?.instagram?.account && `Account is: ${bgOverviewData.socialMedia.instagram.account}`,
                ],
              },
            }}
            cols={3}
            noIcon
          />
          <BodyList
            data={{
              Nuggets: {
                list: bgOverviewData.socialMedia?.instagram?.nuggets,
              },
            }}
          />
        </>
      )}
      {playerProfileData.Twitter !== "" && (
        <>
          <BodyTitle title="Twitter" />
          <BodyColList
            data={{
              Twitter: {
                list: [
                  <a href={`https://www.twitter.com/${playerProfileData.Twitter}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                    @{playerProfileData.Twitter}
                  </a>,
                  //"Followers:",
                  bgOverviewData.socialMedia?.twitter?.account && `Account is: ${bgOverviewData.socialMedia.twitter.account}`,
                  bgOverviewData.socialMedia?.twitter?.dms !== "" && `DMs are: ${bgOverviewData.socialMedia.twitter.dms}`,
                ],
              },
            }}
            cols={3}
            noIcon
          />
          <BodyList
            data={{
              Nuggets: {
                list: bgOverviewData.socialMedia?.twitter?.nuggets,
              },
            }}
          />
        </>
      )}
      {playerProfileData.Snapchat !== "" && (
        <>
          <BodyTitle title="Snapchat" />
          <BodyColList
            data={{
              Snapchat: {
                list: [
                  <p>
                    @{playerProfileData.Snapchat}
                  </p>
                ],
              },
            }}
            cols={4}
            noIcon
          />
        </>
      )}
      {playerProfileData.TikTok && playerProfileData.TikTok !== "" && (
        <>
          <BodyTitle title="TikTok" />
          <BodyColList
            data={{
              TikTok: {
                list: [
                  <a href={`https://www.tiktok.com/@${playerProfileData.TikTok}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                    @{playerProfileData.TikTok}
                  </a>,
                ],
              },
            }}
            cols={3}
            noIcon
          />
        </>
      )}              
    </Accordion.Body>
  </Accordion.Item>
)}



        {reportsData.length > 0 && (
          <Accordion.Item eventKey="5">
            <Accordion.Header>REPORTS</Accordion.Header>
            <Accordion.Body>
              <PlayerReports
                playerID={id}
                reportData={reportsData}
                playerName={`${playerProfileData.FirstName} ${playerProfileData.LastName}`}
              />
            </Accordion.Body>
          </Accordion.Item>
        )}{" "}
        {/*
        <Accordion.Item eventKey="6">
          <Accordion.Header>Stats</Accordion.Header>
          <Accordion.Body></Accordion.Body>
        </Accordion.Item>
        */}
        {playerProfileData.video1 && (
        <Accordion.Item eventKey="6">
          <Accordion.Header>VIDEOS</Accordion.Header>
          <Accordion.Body>
            {Array.apply(null, Array(20)).map((el, index) => (
              <>
                {playerProfileData["video" + (index + 1)] === "" ? null : (
                  <ReactPlayer
                    className="mb-2" 
                    url={playerProfileData["video" + (index + 1)]}
                  />
                )}
              </>
            ))}
          </Accordion.Body>
        </Accordion.Item>
        )}
      </Accordion>
    </div>
  );
};
