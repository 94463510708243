import { Modal, Button } from "react-bootstrap";

export default function ViewTicketModal({ setOpen, ticket }) {
  const handleClose = () => setOpen(false);

  const handleViewAttachment = () => {
    // Logic to open the attachment in a new tab (assuming it's a URL)
    if (ticket.attachment) {
      window.open(ticket.attachment, "_blank");
    }
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>View Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Date Submitted:</strong> {ticket.date}</p>
        <p><strong>Priority:</strong> {ticket.priority}</p>
        <p><strong>Report Type:</strong> {ticket.Report}</p>
        <p><strong>Title:</strong> {ticket.Title}</p>
        <p><strong>Submitted By:</strong> {ticket.submittedBy}</p>
        <p><strong>Description:</strong> <p style={{ whiteSpace: "pre-wrap" }}>{ticket.description}</p></p>

        {/* View Attachment Section */}
        {ticket.attachment && (
          <div>
            <strong>Attachment:</strong>
            <Button variant="link" onClick={handleViewAttachment}>
               View Attachment
            </Button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
