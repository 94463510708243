import React, { useState } from "react";
import Header from "../header/header";
import { Button, Table, Modal, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import Papa from "papaparse";
import styles from "./stats.module.scss"; // Import stats styling for consistent UI

export const ImportPage = () => {
  const [csvData, setCsvData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const calculatePercentage = (statStr) => {
    const parts = statStr.split("-");
    if (parts.length !== 2) return "0.0%";
    const made = Number(parts[0]);
    const attempted = Number(parts[1]);
    if (!attempted) return "0.0%";
    return ((made / attempted) * 100).toFixed(1) + "%";
  };

  const mapRowToDoc = (row) => {
    return {
      playerProfileId: row["playerID"] || "",
      fullName: row["Full Name"] || "",
      date: row["Date"] || "",
      type: row["Type (League)"] || "",
      team: row["Team"] || "",
      opponent: row["Opponent"] || "",
      result: row["Result"] || "",
      min: row["MIN"] || "",
      fgFga: row["FG-FGA"] || "",
      threePThreePA: row["3P-3PA"] || "",
      ftFta: row["FT-FTA"] || "",
      fgPct: row["FG-FGA"] ? calculatePercentage(row["FG-FGA"]) : "",
      threeFgPct: row["3P-3PA"] ? calculatePercentage(row["3P-3PA"]) : "",
      ftPct: row["FT-FTA"] ? calculatePercentage(row["FT-FTA"]) : "",
      orb: row["ORB"] || "",
      drb: row["DRB"] || "",
      trb: row["TRB"] || "",
      ast: row["AST"] || "",
      blk: row["BLK"] || "",
      stl: row["STL"] || "",
      to: row["TO"] || "",
      pf: row["PF"] || "",
      pts: row["PTS"] || "",
    };
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error("No file selected.");
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.errors.length) {
          toast.error("Error parsing CSV file.");
          console.error("CSV Parsing Errors:", result.errors);
          return;
        }
        setCsvData(result.data);
      },
      error: (error) => {
        toast.error(`Error parsing CSV: ${error.message}`);
      },
    });
  };

  const handleImport = async () => {
    if (csvData.length === 0) {
      toast.error("No data to import.");
      return;
    }

    setIsUploading(true);
    try {
      await Promise.all(
        csvData.map(async (row) => {
          const docData = mapRowToDoc(row);
          await addDoc(collection(db, "game-log-stats"), docData);
        })
      );
      toast.success("CSV data imported successfully!");
      setCsvData([]);
    } catch (error) {
      toast.error(`Error importing data: ${error.message}`);
    } finally {
      setIsUploading(false);
      setShowConfirmModal(false);
    }
  };

  return (
    <>
      <Header />
      <div className={`${styles.container} ${styles.importPage}`}>
        <Row>
          <Col>
            <h4>Import Game Log Stats</h4>
            <input style={{paddingTop:"20px", paddingBottom:"40px"}} type="file" accept=".csv" onChange={handleFileUpload} className={styles.fileInput} />
            {csvData.length > 0 && (
              <>
                <Table striped bordered hover responsive className={styles.statsTable}>
                  <thead className={styles.tableHeader}>
                    <tr>
                      {Object.keys(csvData[0]).map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {csvData.map((row, index) => (
                      <tr key={index} className={styles.tableRow}>
                        {Object.values(row).map((value, idx) => (
                          <td key={idx} className={styles.tableCell}>{value}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Button
                  className={styles.importButton}
                  onClick={() => setShowConfirmModal(true)}
                  disabled={isUploading}
                >
                  {isUploading ? "Importing..." : "Confirm Import"}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Import</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to import the selected data?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleImport}>
            Confirm Import
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
