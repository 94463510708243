import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// firebase
import { db } from "../../../firebase";

export default function AddModal({ setOpen, reloadData }) {
  const close = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      reward: "",
      assignedEditor: "",
      progress: "Not Started",
    },
    validate: (values) => {
      const errors = {};
      if (!values.title) errors.title = "Bounty title is required";
      if (!values.description) errors.description = "Description is required";
      if (!values.reward) errors.reward = "Reward is required";
      //if (!values.assignedEditor) errors.assignedEditor = "Assigned editor is required";
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      const { title, description, reward, assignedEditor, progress } = values;

      try {
        // Add bounty to Firestore
        await db.collection("bounties").add({
          title,
          description,
          reward,
          assignedEditor,
          progress,
          createdAt: new Date().toISOString(),
        });

        toast.success("Bounty added successfully!", { theme: "colored" });
        reloadData();
        resetForm();
        close();
      } catch (error) {
        console.error("Error adding bounty: ", error);
        toast.error("Error adding bounty", { theme: "colored" });
      }
    },
  });

  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close} className={styles.closeButton}>
          <MdClose />
        </button>
        <form onSubmit={formik.handleSubmit} className="w-100">
          <div className={styles.content}>
            <h3>Add Bounty</h3>
            <span>Fill in the details to create a new bounty.</span>
            <hr />
            <div className={styles.inputs}>
              {/* Bounty Title */}
              <label htmlFor="title">Bounty Title</label>
              <input
                id="title"
                type="text"
                name="title"
                placeholder="Enter bounty title"
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              <span className={styles.error}>
                {formik.errors.title && formik.touched.title && formik.errors.title}
              </span>

              {/* Description */}
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                name="description"
                placeholder="Enter bounty description"
                rows={4}
                onChange={formik.handleChange}
                value={formik.values.description}
              ></textarea>
              <span className={styles.error}>
                {formik.errors.description &&
                  formik.touched.description &&
                  formik.errors.description}
              </span>

              {/* Reward */}
              <label htmlFor="reward">Reward</label>
              <input
                id="reward"
                type="text"
                name="reward"
                placeholder="Enter reward (e.g., $500)"
                onChange={formik.handleChange}
                value={formik.values.reward}
              />
              <span className={styles.error}>
                {formik.errors.reward && formik.touched.reward && formik.errors.reward}
              </span>

              {/* Assigned Editor */}
              <label htmlFor="assignedEditor">Assigned Editor (Optional)</label>
              <input
                id="assignedEditor"
                type="text"
                name="assignedEditor"
                placeholder="Enter editor name"
                onChange={formik.handleChange}
                value={formik.values.assignedEditor}
              />
              <span className={styles.error}>
                {formik.errors.assignedEditor &&
                  formik.touched.assignedEditor &&
                  formik.errors.assignedEditor}
              </span>

              {/* Progress */}
              <label htmlFor="progress">Progress</label>
              <select
                id="progress"
                name="progress"
                onChange={formik.handleChange}
                value={formik.values.progress}
              >
                <option value="Not Started">Not Started</option>
                <option value="In Progress">In Progress</option>
                <option value="Completed">Completed</option>
              </select>
            </div>
          </div>
          <div className={styles.btns}>
            <button type="button" onClick={close}>
              Cancel
            </button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}