import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { useState } from "react";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";

export default function EditModal({ setOpen, item, refreshEvents }) {
  const toLocalISO = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    const offset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
    return new Date(date - offset).toISOString().slice(0, 16);
  };

  const toUTCTimestamp = (datetime) => {
    return new Date(datetime).getTime(); // Converts to UTC timestamp
  };

  const [formData, setFormData] = useState({
    title: item.title || "",
    startDateTime: toLocalISO(item.startDateTime), // Convert from UTC to local
    endDateTime: toLocalISO(item.endDateTime),
    location: item.location || "",
    note: item.note || "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (!formData.startDateTime || !formData.endDateTime || !formData.title) {
      alert("Please complete all required fields.");
      return;
    }

    try {
      const eventRef = doc(db, "pi-calendar", item.id);
      await updateDoc(eventRef, {
        title: formData.title,
        startDateTime: toUTCTimestamp(formData.startDateTime), // Convert to UTC before saving
        endDateTime: toUTCTimestamp(formData.endDateTime),
        location: formData.location,
        note: formData.note,
      });

      refreshEvents();
      setOpen(false);
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        await deleteDoc(doc(db, "pi-calendar", item.id));
        refreshEvents();
        setOpen(false);
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    }
  };

  return (
    <div className={styles.modalContainer}>
      <div onClick={() => setOpen(false)} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={() => setOpen(false)}>
          <MdClose />
        </button>
        <div className={styles.content}>
          <h3>Edit Event</h3>
          <span>Edit event details below.</span>
          <hr />
          <div className={styles.inputs}>
          <span>Event Title</span>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
            />


            <span>Start Date & Time</span>
            <input
              type="datetime-local"
              name="startDateTime"
              value={formData.startDateTime}
              onChange={handleChange}
            />

            <span>End Date & Time</span>
            <input
              type="datetime-local"
              name="endDateTime"
              value={formData.endDateTime}
              onChange={handleChange}
            />

            
            <span>Location</span>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
            />

            <span>Note</span>
            <textarea
              name="note"
              value={formData.note}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className={styles.btns}>
          <button onClick={() => setOpen(false)}>Cancel</button>
          <button onClick={handleDelete} style={{ background: "red" }}>
            Delete
          </button>
          <button onClick={handleSave}style={{ background: "green", color: "white" }}>Save</button>
        </div>
      </div>
    </div>
  );
}
