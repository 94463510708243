import React, { useState, useEffect, memo } from "react";
import ChatContainer from "../chatContainer";
import { db } from "../../firebase";
import styles from "./Dashboard.module.scss";
import CountUp from "react-countup";

// Memoize CountUp component to prevent unnecessary re-renders
const MemoizedCountUp = memo(CountUp);

export default function StatsWidgets() {
  const [counts, setCounts] = useState({
    livePlayerCount: 0,
    liveNoteCount: 0,
    scoutingOverviewCount: 0,
    bgOverviewCount: 0,
  });

  // Fetch counts in parallel to improve performance
  const getDocsCount = async (collection_name) => {
    const snapshot = await db.collection(collection_name).get();
    return snapshot.size;
  };

  const getCounts = async () => {
    const playerCountPromise = getDocsCount("player-profile");
    const noteCountPromise = getDocsCount("reports");
    const scoutingOverviewCountPromise = getDocsCount("scouting-overview");
    const bgOverviewCountPromise = getDocsCount("background-overview");

    // Wait for all promises to resolve in parallel
    const [playerCount, noteCount, scoutingOverviewCount, bgOverviewCount] = await Promise.all([
      playerCountPromise,
      noteCountPromise,
      scoutingOverviewCountPromise,
      bgOverviewCountPromise,
    ]);

    setCounts({
      livePlayerCount: playerCount,
      liveNoteCount: noteCount,
      scoutingOverviewCount: scoutingOverviewCount,
      bgOverviewCount: bgOverviewCount,
    });
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <div className={styles.content}>
      <div>
        <div>
          <p>Total Players</p>
          <span>
            <MemoizedCountUp start={0} end={counts.livePlayerCount} duration={2.5} />
          </span>
        </div>

        <div>
          <p>Total Reports</p>
          <span>
            <MemoizedCountUp start={0} end={counts.liveNoteCount} duration={2.5} />
          </span>
        </div>

        <div>
          <p>Background Overviews</p>
          <span>
            <MemoizedCountUp start={0} end={counts.bgOverviewCount} duration={2.5} />
          </span>
        </div>

        <div>
          <p>Scouting Overviews</p>
          <span>
            <MemoizedCountUp start={0} end={counts.scoutingOverviewCount} duration={2.5} />
          </span>
        </div>
      </div>
      <ChatContainer />
    </div>
  );
}
