import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";

// dropzone
import { FileDropzone } from '../../form/report/fileDropzone'

// editor for report
import RichEditor from "../../article/rich-editor";

// react icons
import { BsArrowRightCircle } from 'react-icons/bs'

// React Autocomplete
import { AutoComplete } from 'primereact/autocomplete';

// css for removing focus blue glow
import '../../form/index.css'

// react bootstrap
import { Row, Col, Button, Spinner, Modal } from "react-bootstrap";

// components
import styles from "../../form/Form.module.scss";

// forms
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
//  toast
import { toast } from "react-toastify";

// firebase
import { db, storage } from "../../../firebase";
import { StatsTable } from "../../form/report/statsTable.form";
import log_activity from "../../activity/logger";
//authentication data
import { useAuth } from "../../../Context/AuthContext";

export function EditReportModal({ reportID, show, handleClose, getPlayerReports, reportData }) {
	const [loading, setLoading] = useState(false);
	const [playerProfiles, setPlayerProfiles] = useState([]);
	const [filteredProfiles, setFilteredProfiles] = useState(null);
	const [clearFiles, setClearFiles] = useState(false);
	const [roleCount, setRoleCount] = useState(1);
	const options = ["General", "Medical", "Intel", "Scouting"];
	const [rolesDropdownData, setRolesDropdownData] = useState(null);
	const [filteredOptions, setFilteredOptions] = useState(options);
	const [clearQuillBoard, setClearQuillBoard] = useState(false);
	const initialSubFormValues = { urlReference: "", source: "" };
	// pr low and high dropdown data
	const [prLowData, setPrLowData] = useState([]);
	const [prHighData, setPrHighData] = useState([]);
	//const toast = useRef(null);
	const { userInfo } = useAuth();

	const playerProfileNames = useRef([]);
	useEffect(() => {
		console.log(reportData)
		db.collection("player-profile").get().then((allDocs) => {
			const allPlayerProfiles = [];
			allDocs.forEach(doc => {
				allPlayerProfiles.push({ fullName: `${doc.data().FirstName} ${doc.data().LastName}`, id: doc.id, dob: doc.data().DOB });
				playerProfileNames.current.push(`${doc.data().FirstName} ${doc.data().LastName}`);
			});
			setPlayerProfiles(allPlayerProfiles);
			getRolesData();
			
			// get prLow and High
			getPrLowData();
			getPrHighData();


			reportData.typeSpecificFields.roles.length > 0 && setRoleCount(reportData.typeSpecificFields.roles.length);
		});
	}, [])
	const getRolesData = async () => {
		try {
			const response = await db.collection("roles-dropdown").get();
			const data = response.docs.map((doc) => {
				return { id: doc.id, ...doc.data() };
			});
			const sortedRolesData = data.sort((a, b) => a.name.localeCompare(b.name));
			setRolesDropdownData(sortedRolesData);
		} catch (error) {
			console.log(error, "error");
		}
	};

	const getPrHighData = async () => {
		try {
		  const response = await db.collection("hight-dropdown").get();
		  const data = response.docs.map((doc) => {
			return { id: doc.id, ...doc.data() };
		  });
		  data.sort((a, b) => a.order - b.order);
		  setPrHighData(data);
		} catch (error) {
		  console.log(error, "error");
		}
	  };
	
	  const getPrLowData = async () => {
		try {
		  const response = await db.collection("low-dropdown").get();
		  const data = response.docs.map((doc) => {
			return { id: doc.id, ...doc.data() };
		  });
		  data.sort((a, b) => a.order - b.order);
		  setPrLowData(data);
		} catch (error) {
		  console.log(error, "error");
		}
	  };

	const searchProfile = (event) => {
		let _filteredProfiles = [...playerProfiles];
		if (event.query.trim().length > 0) {
			_filteredProfiles = _filteredProfiles.filter((p) => {
				return p.fullName.toLowerCase().includes(event.query.toLowerCase());
			});
		}
		setFilteredProfiles(_filteredProfiles);
	}
	const searchType = (event) => {
		let _options = options;
		setFilteredOptions(_options);
	}

	const formik = useFormik({

		initialValues: {
			playerProfile: reportData.playerProfile || '',
            reportDate: reportData.reportDate || '',
            type: reportData.type || 'General',
            reportPreview: reportData.reportPreview || '',
			location: reportData.location || '',
			typeSpecificFields: (() => {
			  const type = reportData.type || 'General';
			  const tsFields = reportData.typeSpecificFields || {};
			  
			  switch(type) {
				case 'General':
				case 'Medical':
				  return {
					urlReference: tsFields.urlReference || '',
					source: tsFields.source || ''
				  };
				case 'Intel':
				  return { source: tsFields.source || '' };
				case 'Scouting':
				  return {
					roles: tsFields.roles || [],
					projectionRange: tsFields.projectionRange ? 
					(Array.isArray(tsFields.projectionRange) ? 
					  tsFields.projectionRange[0] : 
					  tsFields.projectionRange
					) : { 
					  low: '', 
					  high: '', 
					  confidence: '' 
					},
					stats: tsFields.stats ? 
					(Array.isArray(tsFields.stats) ? 
					tsFields.stats : 
					[tsFields.stats]
					) : [{
					team: "",
					opponent: "",
					score: [],
					min: "",
					fg: [],
					"3fg": [],
					ft: [],
					or: "",
					tr: "",
					ast: "",
					to: "",
					blk: "",
					stl: "",
					pf: "",
					pts: "",
					}]
				};
				default:
				  return {};
			  }
			})(),
			reportContent: reportData.reportContent || '',
			drafted: reportData.drafted || false,
			attachment: reportData.attachment || '',
			creator: reportData.creator || `${userInfo.firstName} ${userInfo.lastName}`,
			createdAt: reportData.createdAt || moment().unix(),
		  },
		validationSchema: validationSchema(playerProfileNames),
		validate: (values) => {
            const errors = {};
            // Add custom validation logic here if needed
            console.log('Validation Errors:', errors);
            return errors;
        },
		onSubmit: (values, { resetForm }) => {
			console.log(values, "values");
			console.log("SUBMIT DATA:" + reportData);
			setLoading(true);
			if (values.typeSpecificFields.hasOwnProperty("roles")) {
				values.typeSpecificFields["roles"] = values.typeSpecificFields.roles.filter(r => r != undefined)
			}
			db.collection("reports")
				.doc(reportID)
				.update({
					...values,
					lastModified: moment().unix(),
					playerProfile: values.playerProfile.id
				})
				.then((docRef) => {
					console.log("Document successfully written!");
					getPlayerReports();
					setLoading(false);
					resetForm({});
					setClearQuillBoard(true);
					setClearFiles(true);
					handleClose();
					toast.info("Report Updated Successfully", {
						theme: "colored",
					});
					log_activity(
						values.playerProfile.fullName,
						`${values.type} Report was Edited`,
						userInfo.firstName + " " + userInfo.lastName,
					);

				})
				.catch((error) => {
					setLoading(false);
					toast.error("Error Creating Report", {
						theme: "colored",
					});
					console.error("Error writing document: ", error);
				});
		},
	});

	const getRolesDropdownsComp = useCallback(() => {
		let rolesArray = []
		for (let i = 0; i < roleCount; i++) {
			rolesArray.push(
				<select
					key={i}
					className={styles.custDropdownStyle}
					onChange={formik.handleChange}
					value={formik.values.typeSpecificFields.roles[i]}
					name={`typeSpecificFields.roles[${i}]`}
					onBlur={formik.onBlur}
				>
					<option disabled selected value={""}>Role {i + 1}</option>
					{rolesDropdownData && rolesDropdownData.map(({ id, name, value }) =>
						<option key={id} value={value}>{name}</option>
					)}
				</select>)
		}
		return rolesArray;
	}, [roleCount, formik.values.typeSpecificFields.roles,rolesDropdownData, formik.values.type]);

	const itemTemplate = (item) => {
		return (
			<div>
				<b>{item.fullName}</b><br />
				<code>DOB: {moment(item.dob).format('MM/DD/YYYY')}</code>
			</div>
		);
	}

	const handleChange = (event) => {
		const { name, value } = event.target;
		formik.setFieldValue(name, value);
	  };

	//   useEffect(()=>{
	//     // setClearQuillBoard(true)
	// console.log(clearQuillBoard)
	//     console.log(formik.values.playerProfile)
	//console.log("error: " + formik.error.values)
	 //    console.log("error: " + formik.errors.playerProfile)
	//	 console.log(reportData)
	//   }, [formik.values.playerProfile])
	return (
		<Modal
			show={show}
			onHide={handleClose}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<div>
				{/* ------------- REPORT FORM BEGINS -------------------*/}
				<Modal.Body>
					<div className={styles.container}>
						<div className={styles.title}>
							<div>
								<h4>Report</h4>
								<subtitle>
									This section is used to edit report. Please fill all sections.{" "}
								</subtitle>
							</div>
						</div>

						<Row>
							<Col md={6}>

								<div className={styles.field}>
									<p>Player Profile</p>
									<AutoComplete
										className={styles.fullWidth}
										value={formik.values.playerProfile}
										suggestions={filteredProfiles}
										completeMethod={searchProfile}
										field="fullName"
										itemTemplate={itemTemplate}
										name="playerProfile"
										dropdown
										onChange={formik.handleChange}
										onBlur={formik.handleBlur} />

									{formik.errors.playerProfile
										&& formik.touched.playerProfile
										? (
											<div className={styles.errorMessage}>
												{formik.errors.playerProfile + " "}
												{formik.errors.playerProfile === "This player profile doesn't exist" &&
													<Link className={styles.createProfLink} to="/player-profile/create">
														Create Player Profile <BsArrowRightCircle />
													</Link>}
											</div>
										) : null}
								</div>
								<div className={styles.field}>
									<p>Report Date</p>
									<input
										type="date"
										name="reportDate"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.reportDate}
									/>
									{formik.errors.reportDate && formik.touched.reportDate ? (
										<div className={styles.errorMessage}>
											{formik.errors.reportDate}
										</div>
									) : null}
								</div>

								<div className={styles.field}>
									<p>Type</p>
									<AutoComplete
										className={styles.fullWidth}
										dropdown forceSelection readOnly
										value={formik.values.type}
										suggestions={filteredOptions}
										completeMethod={searchType}
										name="type"
										onChange={(e) => {
											formik.handleChange(e)
											let type = e.value;
											let typeSpecificFieldsValues;
											if (["General", "Medical"].includes(type)) {
												typeSpecificFieldsValues = initialSubFormValues;
											}
											else if (type === "Intel") {
												typeSpecificFieldsValues = { source: "" }
											}
											else {
												setRoleCount(1);
												typeSpecificFieldsValues = {
													bottomLine: "",
													stats: [{
														team: "",
														opponent: "",
														score: [],
														min: "",
														fg: [],
														"3fg": [],
														ft: [],
														or: "",
														tr: "",
														ast: "",
														to: "",
														blk: "",
														stl: "",
														pf: "",
														pts: "",
													  }],
													roles: [], projectionRange: { low: "", high: "" }
												}
											}
											formik.setFieldValue("typeSpecificFields", typeSpecificFieldsValues);
										}}
										onBlur={formik.handleBlur}
									/>
									{formik.errors.type && formik.touched.type ? (
										<div className={styles.errorMessage}>{formik.errors.type}</div>
									) : null}
								</div>
							</Col>

							<Row>
								<Col md={["General", "Medical"].includes(formik.values.type) ? 6 : 12}>
									<div className={styles.field}>
										<p>Report Preview/Event</p>
										<input
											type="text"
											name="reportPreview"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.reportPreview}
										/>
										{formik.errors.reportPreview && formik.touched.reportPreview ? (
											<div className={styles.errorMessage}>
												{formik.errors.reportPreview}
											</div>
										) : null}
									</div>
								
									

								</Col>
								<Col md={6}>
									<div className={styles.field}>
										{["General", "Medical"].includes(formik.values.type) &&
											<>
												<p>URL Reference* (optional)</p>
												<input
													type="text"
													name="typeSpecificFields.urlReference"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.typeSpecificFields.urlReference}
												/>
												{formik.errors.typeSpecificFields?.urlReference && formik.touched.typeSpecificFields?.urlReference ? (
													<div className={styles.errorMessage}>
														{formik.errors.typeSpecificFields?.urlReference}
													</div>
												) : null}
											</>
										}
									</div>
								</Col>
							</Row>
							<div className={styles.field}>
								{formik.values.type === "Scouting" &&
									<>

										<div className={styles.field}>
											<p>Location</p>
											<input
											type="text"
											name="location"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.location}
											/>
											{formik.errors.location && formik.touched.location ? (
											<div className={styles.errorMessage}>
												{formik.errors.location}
											</div>
											) : null}
										</div>


										<p>Projection Range</p>
										<div className="d-flex flex-wrap">
										<div className="px-2">
											<p>Low</p>
											<select
											name="typeSpecificFields.projectionRange.low"
											onChange={handleChange}
											value={formik.values.typeSpecificFields.projectionRange.low}
											className={styles.dropdownStyle}
											>
											<option value="">Select</option>
											{prLowData.map((item) => (
												<option key={item.id} value={item.value}>
												{item.name}
												</option>
											))}
											</select>
										</div>
										<div className="px-2">
											<p>High</p>
											<select
											name="typeSpecificFields.projectionRange.high"
											onChange={handleChange}
											value={formik.values.typeSpecificFields.projectionRange.high}
											className={styles.dropdownStyle}
											>
											<option value="">Select</option>
											{prHighData.map((item) => (
												<option key={item.id} value={item.value}>
												{item.name}
												</option>
											))}
											</select>
										</div>
										<div className="px-2">
											<p>Confidence</p>
											<select
											name="typeSpecificFields.projectionRange.confidence"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											className={styles.dropdownStyle}
											value={formik.values.typeSpecificFields.projectionRange.confidence}
											>
											<option value="">Select</option>
											{[...Array(21).keys()].map((index) => (
												<option key={index} value={`${index * 5}%`}>{`${index * 5}%`}</option>
											))}
											</select>
										</div>
										</div>
										{(formik.errors.typeSpecificFields?.projectionRange?.low ||
										formik.errors.typeSpecificFields?.projectionRange?.high) &&
										(formik.touched.typeSpecificFields?.projectionRange?.low ||
										formik.touched.typeSpecificFields?.projectionRange?.high) ? (
										<>
											<div className={styles.errorMessage}>
											{formik.errors.typeSpecificFields.projectionRange.low}
											</div>
											<div className={styles.errorMessage}>
											{formik.errors.typeSpecificFields.projectionRange.high}
											</div>
										</>
										) : null}
									</>
								}
							</div>
							<div className={styles.field}>
								{formik.values.type === "Scouting" &&
									<>
										<p>Roles</p>
										<div className={styles.grayContainer}>
											{formik.values.typeSpecificFields.roles && getRolesDropdownsComp()}
											<button onClick={() => setRoleCount((prevCount) => prevCount + 1)} className={styles.roleBtn} style={{ width: "auto" }}><b>+</b></button>
										</div>
										{formik.errors.typeSpecificFields?.roles && formik.touched.typeSpecificFields?.roles ? (
											<div className={styles.errorMessage}>
												{formik.errors.typeSpecificFields?.roles}
											</div>
										) : null}
									</>
								}
							</div>
							<div>
								{formik.values.type === "Scouting" &&
									<>
										<p style={{ fontWeight: "500" }}>Stats</p>
										<StatsTable formik={formik} />
										{formik.errors.typeSpecificFields?.stats && formik.touched.typeSpecificFields?.stats ? (
											<div className={styles.errorMessage}>
												{formik.errors.typeSpecificFields?.stats}
											</div>
										) : null}
									</>
								}
							</div>
							<div className={styles.field}>
								<p>Report</p>
								<RichEditor
									value={formik.values.reportContent}
									setClear={setClearQuillBoard}
									clear={clearQuillBoard}
									handleChange={(value) => {
										formik.setFieldValue("reportContent", value);
									}}
								/>
								{formik.errors.reportContent && formik.touched.reportContent ? (
									<div className={styles.errorMessage}>
										{formik.errors.reportContent}
									</div>
								) : null}
							</div>

							<div className={styles.field}>
								{formik.values.type !== "Scouting" &&
									<>
										<p>Source</p>
										<input
											type="text"
											name="typeSpecificFields.source"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.typeSpecificFields.source}
										/>
										{formik.errors.typeSpecificFields?.source && formik.touched.typeSpecificFields?.source ? (
											<div className={styles.errorMessage}>
												{formik.errors.typeSpecificFields?.source}
											</div>
										) : null}
									</>
								}
							</div>
		
							<div
								className={styles.field}
							>
								<p>Upload Attachment</p>
							</div>

							<FileDropzone
								setLoading= {setLoading}
								attachment={formik.values.attachment}
								clearFiles={clearFiles}
								formik={formik}
							/>
						</Row>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className={styles.buttonContainer}>
						<Row>
							
							<Col md={4}>
								<Button
									style={{width:"140px"}}
									disabled={loading}
									type="submit"
									onClick={() => {
										formik.setFieldValue("drafted", false);
										formik.handleSubmit();
									}}
								>
									{loading ? <Spinner animation="border" /> : "Save & Publish"}
								</Button>

							</Col>
						</Row>
					</div>
				</Modal.Footer>
				{/* ------------- REPORT ENDS ENDS -------------------*/}
			</div >
		</Modal>
	);
}

const validationSchema = (allPlayerProfileNames) => {
	return Yup.object({}).shape({
	  playerProfile: Yup.lazy((value) => {
		if (value === undefined) {
		  return Yup.string().required("Required");
		} else if (value.constructor === String) {
		  return Yup.string()
			.required("Required")
			.oneOf(
			  allPlayerProfileNames.current,
			  "This player profile doesn't exist"
			)
			.test(
			  "ExistingProfile",
			  `Profile/s with this name exists. Please Select`,
			  async (value2) =>
				(await allPlayerProfileNames.current.filter(
				  (p) => p.fullName === value2
				).length) > 0
			);
		} else {
		  return Yup.object().required("Required");
		}
	  }),
	  reportDate: Yup.date().required("Required"),
	  type: Yup.string().required("Required"),
	  reportPreview: Yup.string().required("Required"),
	  //location: Yup.string().required("Required"),
	  typeSpecificFields: Yup.lazy((value) => {
		let schema = {};
		if (value.hasOwnProperty("urlReference"))
		  schema["urlReference"] = Yup.string().notRequired();
		if (value.hasOwnProperty("projectionRange"))
		  schema["projectionRange"] = Yup.object().shape(
			{
			  low: Yup.mixed()
				.typeError("The value should be a number")
				.when("high", {
				  is: (val) => val !== undefined,
				  then: (schema) =>
					schema.required("Enter both low and high or enter none"),
				}),
			  high: Yup.mixed()
				.typeError("The value should be a number")
				.when("low", {
				  is: (val) => val !== undefined,
				  then: (schema) =>
					schema.required("Enter both low and high or enter none"),
				}),
			},
			[["high", "low"]]
		  );
		return Yup.object().shape(schema).required();
	  }),
	});
  };
