import React, { useMemo,useState, useEffect, Fragment, useRef} from "react";
import { Formik, Form, Field } from "formik";
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import * as Yup from "yup";
import { toast } from "react-toastify";
import { MultiSelect } from "primereact/multiselect";
import { AutoComplete } from "primereact/autocomplete";


// react bootstrap
import { Row, Col, Spinner } from "react-bootstrap";

// styles
import styles from "../form/Form.module.scss";

// firebase
import { db, storage } from "../../firebase";

//import { StyledDropzone } from "../form/imageDropZone";
import { useDropzone } from "react-dropzone";

//icons
import { MdCloudUpload, MdVideocam, MdInfo, MdCheckCircle} from "react-icons/md";

import { Button, Modal } from "react-bootstrap";
import log_activity from "../activity/logger";
import { notificationService } from "../../Services/notification-service";

//authentication data
import { isAdmin, isSuperAdmin, useAuth } from "../../Context/AuthContext";

export const EditProfileModal = ({
  show,
  handleClose,
  item,
  docID,
  getPlayerProfile,
}) => {
  const [nationalityData, setNationalityData] = useState(null);
  const [yearData, setYearData] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [statusTeamsData, setStatusTeamsData] = useState(null);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [positionData, setPositionData] = useState(null);
  const [handData, setHandData] = useState(null);
  const [heightData, setHeightData] = useState(null);
  const [weightData, setWeightData] = useState(null);
  const [wingspanData, setWingspanData] = useState(null);
  const [ncaaData, setNcaaData] = useState(null);
  const [aauTeamData, setAauTeamData] = useState(null);
  const [afiliationData, setAfiliationData] = useState(null);
  const [prLowData, setPrLowData] = useState(null);
  const [prHighData, setPrHighData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorsResponse, setErrors] = useState(null);

  const { isAdmin, isSuperAdmin, userInfo } = useAuth();
  
  const [modalOpen, setModalOpen] = useState(false);
  const [HeadshotImage, setHeadshotImage] = useState(null);

  // state for selcted team
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [schools, setSchools] = useState([]);

  const typeaheadRef = useRef(null);

  useEffect(() => {
    getNationalityData();
    getYearData();
    getStatusData();
    getStatusTeamsData();
    getPositionData();
    getHandData();
    getHeightData();
    getWeightData();
    getWingspanData();
    getNcaaData();
    getAauTeamData();
    getAfiliationData();
    getPrLowData();
    getPrHighData();
    getSchoolData();

    return () => {};
  }, []);


useEffect(() => {
  const handleOutsideClick = (event) => {
    const modalContent = document.querySelector(`.${styles.modalContent}`);
    if (modalContent && !modalContent.contains(event.target)) {
      setModalOpen(false); 
    }
  };

  if (modalOpen) {
    document.addEventListener('click', handleOutsideClick);
  }

  return () => {
    document.removeEventListener('click', handleOutsideClick);
  };
}, [modalOpen]); 

const handleModalTriggerClick = (event) => {
  event.stopPropagation();
  setModalOpen(true);
};

const handleCloseModal = () => {
  setModalOpen(false);
};


  const handleFireBaseUpload = (imageAsFile, docID) => {
    console.log("start of upload");
  
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }
    const uploadTask = storage
      .ref(`/profile-headshots/${imageAsFile.name}`)
      .put(imageAsFile);
    
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        console.log(snapShot);
      },
      (err) => {
        console.log(err);
      },
      () => {
        storage
          .ref("profile-headshots")
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            db.collection("player-profile")
              .doc(docID)
              .update({
                ID: docID,
                Headshot: fireBaseUrl,
              })
              .then(() => {
                console.log(fireBaseUrl, "fireBaseUrl");
                console.log("image uploaded and saved to firebase");
                handleClose();
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    );
  };

  const getPrHighData = async () => {
    try {
      const response = await db.collection("hight-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => b.order - a.order);
      setPrHighData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getPrLowData = async () => {
    try {
      const response = await db.collection("low-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => b.order - a.order);
      setPrLowData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getAfiliationData = async () => {
    try {
      const response = await db.collection("affiliation-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => a.order - b.order);
      setAfiliationData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getAauTeamData = async () => {
    try {
      const response = await db.collection("AAUTeam-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => a.name.localeCompare(b.name));

      setAauTeamData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getNcaaData = async () => {
    try {
      const response = await db.collection("NCAA-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setNcaaData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getWeightData = async () => {
    try {
      const response = await db.collection("weight-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      const sortedWeightData = data.sort((a, b) => a.name.localeCompare(b.name));

    setWeightData(sortedWeightData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getHeightData = async () => {
    try {
      const response = await db.collection("height-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      
      const sortedHeightData = data.sort((a, b) => a.order - b.order);

      setHeightData(sortedHeightData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getWingspanData = async () => {
    try {
      const response = await db.collection("wingspan-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      const sortedWingspanData = data.sort((a, b) => a.order - b.order);

    setWingspanData(sortedWingspanData);
    } catch (error) {
      console.log(error, "error");
    }
  };


  const getHandData = async () => {
    try {
      const response = await db.collection("hand-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setHandData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getPositionData = async () => {
    try {
      const response = await db.collection("position-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      // Define the custom order of positions
    const customOrder = ["Guard", "Wing", "Forward", "Big"];

    // Sort the data based on the custom order
    const sortedPositionData = data.sort((a, b) => {
      const orderA = customOrder.indexOf(a.name);
      const orderB = customOrder.indexOf(b.name);
      return orderA - orderB;
    });

    setPositionData(sortedPositionData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getStatusData = async () => {
    try {
      const response = await db.collection("status-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setStatusData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getStatusTeamsData = async () => {
    try {
      const response = await db.collection("status-teams").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setStatusTeamsData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const searchTeam = (event) => {
    let filtered = [];
  
    if (event.query.length === 0) {
      filtered = [...statusTeamsData];
    } else {
      filtered = statusTeamsData.filter((team) =>
        team.name.toLowerCase().includes(event.query.toLowerCase()) // filter by the 'name' field
      );
    }
  
    setFilteredTeams(filtered);
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
     // Handle the state change or form submission logic here
     // For example, if using Formik, you can access the Formik context to update the field value
   };
 
   
   
  


  const getYearData = async () => {
    try {
      const response = await db.collection("year-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      const sortedYearData = data.sort((a, b) => {
        if (a.name === "INT'L") return -1; // "INT'L" comes first
        if (b.name === "INT'L") return 1; // "INT'L" comes first
        return a.name.localeCompare(b.name); // Sort the rest in ascending order
      });
  
      setYearData(sortedYearData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getNationalityData = async () => {
    try {
      const response = await db.collection("nationality-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      const sortedNationalityData = data.sort((a, b) => {
        if (a.name === "United States of America") return -1; // "United States" comes first
        if (b.name === "United States of America") return 1; // "United States" comes first
        return a.name.localeCompare(b.name); // Sort the rest in ascending order
      });
  
      setNationalityData(sortedNationalityData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const calculateAge = (birthday) => {
    return (new Date() - new Date(birthday)) / 31557600000;
  };


  const getSchoolData = async () => {
    try {
        let res = await db.collection("school-dropdown").get();
        let sortedData = res.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .sort((a, b) => a.name.localeCompare(b.name));

        // Add "Transfer Portal" option for admin and super-admin users
        if (isAdmin() || isSuperAdmin()) {
            sortedData.unshift({ identifier: "Other", name: "Transfer Portal", value: "Transfer Portal" });
       }

        setSchools(sortedData);
    } catch (error) {
        console.error("Error fetching school data:", error);
    }
}

const renderMenuWithGrouping = (results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }, state) => {
  let index = 0;
  const regions = groupBy(results, 'identifier');
  
  // Check if there are no matches found
  if (Object.keys(regions).length === 0) {
    return <Menu {...menuProps}><MenuItem>{'No match found. Contact admin to add new team.'}</MenuItem></Menu>;
  }
  
  const items = Object.keys(regions)
      .sort()
      .map((region) => (
          <Fragment key={region}>
              {index !== 0 && <Menu.Divider />}
              <Menu.Header>{region}</Menu.Header>
              {regions[region].map((i) => {
                  const item = (
                      <MenuItem key={index} option={i} position={index}>
                          {i.name}
                          {(i.identifier === 'NBA' || i.identifier === 'G League') && <div><small>{i.teamName}</small></div>}
                      </MenuItem>
                  );

                  index += 1;
                  return item;
              })}
          </Fragment>
      ));

  return <Menu {...menuProps}>{items}</Menu>;
};
function groupBy(array, key) {
  return array.reduce((result, currentValue) => {
      const identifier = currentValue[key]; // Get the identifier value
      if (identifier !== undefined) { // Check if the identifier is defined
          (result[identifier] = result[identifier] || []).push(currentValue);
      }
      return result;
  }, {});
}


  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        initialValues={item}
        initialErrors={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { resetForm }) => {
          console.log(values);
          setLoading(true);

          db.collection("player-profile")
            .doc(docID)
            .update({
              ...values,
              //createdAt: new Date(),
              Age: calculateAge(values.DOB).toFixed(1),
              Title: values.FirstName + " " + values.LastName,
              Hometown: values.City + ", " + values.State,
              pRange: values.Prlow + " to " + values.Prhigh,
              committedTeam:
                values.Status !== "Undecided" && values.Status !== ""
                  ? values.committedTeam
                  : "",
            })
            .then(async (docRef) => {
              console.log("Document successfully written!");
              HeadshotImage && handleFireBaseUpload(HeadshotImage, docID);

              getPlayerProfile();
              setLoading(false);
              handleClose();
              resetForm({});
              setSelectedTeam([]);
              typeaheadRef.current.clear(); 
              toast.success("Player Profile Updated Successfully", {
                theme: "colored",
              });
              const watchlistDoc = await db
                .collection("watchlist")
                .doc(docID)
                .get();
                if (watchlistDoc.exists) {
                  const receipients = watchlistDoc.data().subscribers;
                  const playerFirstName = (values.FirstName ?? "").trim();
                  const playerLastName = (values.LastName ?? "").trim();
                  const playerName = playerFirstName + " " + playerLastName;
                
                  // Check if the last name ends with "s" and format the possessive form accordingly
                  const possessivePlayerName = playerLastName.endsWith("s")
                    ? playerName + "'"
                    : playerName + "'s";
                
                  notificationService.send(
                    receipients,
                    `${possessivePlayerName} profile has been updated.`
                  );
                }
              log_activity(
                values.FirstName + " " + values.LastName,
                "Player Profile was Edited",
                userInfo.firstName + " " + userInfo.lastName,
              );
            })
            .catch((error) => {
              setLoading(false);
              setErrors(error);
              toast.error("Error Updating Player Profile", {
                theme: "colored",
              });
              console.error("Error writing document: ", error);
            });
        }}
      >
        {({
          isValid,
          values,
          errors,
          touched,
          handleChange,
          handleTeamChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validateForm,
        }) => (
          <>
            <Modal.Body>
              <Form>
                <>
                  <div className={styles.container}>
                    <div className={styles.title}>
                      <div>
                        <h4>Player Profile</h4>
                        <subtitle>
                          This section is used to create player profiles. Please
                          fill as many sections as possible.{" "}
                        </subtitle>
                      </div>
                    </div>

                    <Row>
                      <Col md={4}>
                        <div className={styles.field}>
                          <p>First Name</p>
                          <Field
                            type="text"
                            name="FirstName"
                            value={values.FirstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors.FirstName && touched.FirstName ? (
                          <div className={styles.errorMessage}>
                            {errors.FirstName}
                          </div>
                        ) : null}
                        <div className={styles.field}>
                          <p>Last Name</p>
                          <input
                            type="text"
                            name="LastName"
                            value={values.LastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {errors.LastName && touched.LastName ? (
                            <div className={styles.errorMessage}>
                              {errors.LastName}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.field}>
                          <p>Hometown/City</p>
                          <input
                            type="text"
                            name="hometown_City"
                            value={values.hometown_City}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.hometown_City && touched.hometown_City ? (
                            <div className={styles.errorMessage}>
                              {errors.hometown_City}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.field}>
                          <p>State or Country</p>
                          <input
                            type="text"
                            name="hometown_State_Country"
                            value={values.hometown_State_Country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.hometown_State_Country &&
                          touched.hometown_State_Country ? (
                            <div className={styles.errorMessage}>
                              {errors.hometown_State_Country}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.field}>
                        <p>Team</p>
                        <Typeahead
                          ref={typeaheadRef}
                          id="Team"
                          labelKey="name"
                          options={schools}
                          defaultSelected={[{ name: values.Team }]} // Set the defaultSelected prop to an array containing the selected team
                          onChange={(selected) => {
                            // Assuming selected is an array of selected options
                            const selectedTeamName = selected[0] ? selected[0].name : ""; // Get the name value of the selected option
                            setFieldValue("Team", selectedTeamName); // Assign the name value to Team
                          }}
                          placeholder="Select Team..."
                          renderMenu={renderMenuWithGrouping}
                        />
                        {errors.Team && touched.Team ? (
                            <div className={styles.errorMessage}>{errors.Team}</div>
                        ) : null}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className={styles.field}>
                          <p>Nationality</p>
                          <Field
                            as="select"
                            name="Country"
                            id="Country"
                            className={styles.dropdownStyle}
                          >
                            <option value="">Select Player Profile</option>
                            {nationalityData &&
                              nationalityData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                        {errors.Country && touched.Country ? (
                          <div className={styles.errorMessage}>
                            {errors.Country}
                          </div>
                        ) : null}

                        <div className={styles.field}>
                          <p>Year</p>
                          <Field
                            as="select"
                            name="Year"
                            id="Year"
                            className={styles.dropdownStyle}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Year</option>
                            {yearData &&
                              yearData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.Year && touched.Year ? (
                            <div className={styles.errorMessage}>
                              {errors.Year}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.field}>
                          <p>Date of Birth</p>
                          <input
                            type="date"
                            name="DOB"
                            value={values.DOB}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.DOB && touched.DOB ? (
                            <div className={styles.errorMessage}>
                              {errors.DOB}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.field}>
                          <p>Position</p>
                          <Field
                            as="select"
                            name="Position"
                            className={styles.dropdownStyle}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Position</option>
                            {positionData &&
                              positionData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.Position && touched.Position ? (
                            <div className={styles.errorMessage}>
                              {errors.Position}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.field}>
                          <p>Hand</p>
                          <Field
                            as="select"
                            name="Hand"
                            className={styles.dropdownStyle}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Hand</option>
                            {handData &&
                              handData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.Hand && touched.Hand ? (
                            <div className={styles.errorMessage}>
                              {errors.Hand}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className={styles.field}>
                          <p>Height</p>
                          <Field
                            as="select"
                            name="Height"
                            className={styles.dropdownStyle}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Height</option>
                            {heightData &&
                              heightData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.Height && touched.Height ? (
                            <div className={styles.errorMessage}>
                              {errors.Height}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.field}>
                          <p>Weight</p>
                          <Field
                            as="select"
                            name="Weight"
                            className={styles.dropdownStyle}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Weight</option>
                            {weightData &&
                              weightData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.Weight && touched.Weight ? (
                            <div className={styles.errorMessage}>
                              {errors.Weight}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.field}>
                          <p>Wingspan</p>
                          <Field
                            as="select"
                            name="Wingspan"
                            className={styles.dropdownStyle}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Wingspan</option>
                            {wingspanData &&
                              wingspanData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.Wingspan && touched.Wingspan ? (
                            <div className={styles.errorMessage}>
                              {errors.Wingspan}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.field}>
                    <p>Viewing Privilege&nbsp;
                      <span
                        className={styles.helperInfo}
                        title="Click to view helper info"
                        onClick={handleModalTriggerClick} // Call the click handler
                      >
                        <MdInfo />
                      </span>
                    </p>

                    <MultiSelect
                      style={{
                        width: "100%",
                        border: "#f8f8f8",
                        backgroundColor: "#f8f8f8",
                      }}
                      placeholder="Select Viewing Privilege"
                      value={values.viewing_privileges}
                      optionLabel="name"
                      optionValue="value"
                      options={[
                        { name: "NCAA", value: "ncaa" },
                        { name: "Executive", value: "executive" },
                        { name: "Demo", value: "demo" },
                        { name: "Draft", value: "Draft" },             
                        { name: "Recertification", value: "ncaa_admin" },
                      ]}
                      onChange={(e) =>
                        setFieldValue("viewing_privileges", e.value)
                      }
                    />

                      {/* Modal component */}
                      {modalOpen && (
                        <div className={styles.modal} onClick={handleCloseModal}>
                          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                            <span className={styles.closeButton} onClick={handleCloseModal}>
                              &times;
                            </span>
                            <h5>Viewing Privilages</h5>
                            <p>NCAA: for NCAA clients (old "live" database)</p>
                            <p>Executive: for non-NCAA clients (old "archive" database)</p>
                            <p>Demo: our new pitch deck - profiles intended for demo site</p>
                            <p>Draft: just a draft - not a public profile</p>
                            <p>Recertification: (for developer use only)</p>
                          </div>
                        </div>
                      )}

                      {/* Error message component */}
                      {errors["viewing_privileges"] && touched["viewing_privileges"] ? (
                        <div className={styles.errorMessage}>
                          {errors["viewing_privileges"]}
                        </div>
                      ) : null}
                    </div>

                        <div className={styles.field}>
                          <p>Headshot</p>

                          <StyledDropzone
                            // errors={errors.Headshot}
                            // touched={touched.Headshot}
                            onDrop={(acceptedFiles) => {
                              setHeadshotImage(acceptedFiles);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className={styles.container}>
                    <div className={styles.title}>
                      <div>
                        <h4>Social Media</h4>

                        <subtitle>
                          This section is used to add social media handles.
                          Please fill as many sections as possible without the @
                          symbol.
                        </subtitle>
                      </div>
                    </div>

                    <Row>
                      <Col md={6}>
                        <div className={styles.field}>
                          <p>Twitter</p>
                          <input
                            type="text"
                            name="Twitter"
                            value={values.Twitter}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.Twitter && touched.Twitter ? (
                            <div className={styles.errorMessage}>
                              {errors.Twitter}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className={styles.field}>
                          <p>Instagram</p>
                          <input
                            type="text"
                            name="Instagram"
                            value={values.Instagram}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.Instagram && touched.Instagram ? (
                            <div className={styles.errorMessage}>
                              {errors.Instagram}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className={styles.field}>
                          <p>Snapchat</p>
                          <input
                            type="text"
                            name="Snapchat"
                            value={values.Snapchat}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.Snapchat && touched.Snapchat ? (
                            <div className={styles.errorMessage}>
                              {errors.Snapchat}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className={styles.field}>
                          <p>TikTok</p>
                          <input
                            type="text"
                            name="TikTok"
                            value={values.TikTok}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.TikTok && touched.TikTok ? (
                            <div className={styles.errorMessage}>
                              {errors.TikTok}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className={styles.container}>
                    <div className={styles.title}>
                      <div>
                        <h4>AAU Information (*Optional)</h4>
                        <subtitle>
                          This section is used to add AAU information. If
                          applicable please fill as many sections as possible.
                        </subtitle>
                      </div>
                    </div>

                    <Row>
                    <Col md={4}>
  <div className={styles.field}>
    <p>AAU Team</p>
    <Field
      as="select"
      name="aauTeam"
      className={styles.dropdownStyle}
      onBlur={handleBlur}
      value={values.aauTeam} // Set the value attribute to the selected value
      onChange={(e) => {
        const selectedTeamName = e.target.value; // Get the name of the selected team
        console.log("Selected Team Name:", selectedTeamName); // Debugging
        const selectedTeam = aauTeamData.find((item) => item.name === selectedTeamName); // Use strict comparison to find the selected team
        console.log("Selected Team:", selectedTeam); // Debugging
        if (selectedTeam) {
          // Check if there are changes to existing contact information
          const hasChanges = values.aauCoach || values.aauCoachLast || values.aauCoachInfo || values.aauCoachEmail;
          if (hasChanges) {
            // Display confirmation prompt if there are changes
            const confirmChange = window.confirm("Updating the AAU team will delete previous contact information. Are you sure you want to proceed?");
            if (!confirmChange) {
              return; // If user cancels, do not proceed with the change
            }
          }

          // Update corresponding fields with selected team's information
          setFieldValue("aauAffil", selectedTeam.aauAffilation || "");
          setFieldValue("aauCoach", selectedTeam.aauCoach || "");
          setFieldValue("aauCoachLast", selectedTeam.aauCoachLast || "");
          setFieldValue("aauCoachInfo", selectedTeam.aauCoachInfo || "");
          setFieldValue("aauCoachEmail", selectedTeam.aauCoachEmail || "");
          setFieldValue("aauTeam", selectedTeamName); 
        }
      }}
    >
      <option value="">Select AAU Team</option>
      {aauTeamData &&
        aauTeamData.map((item) => {
          return (
            <option key={item.id} value={item.name}> {/* Set value to team name */}
              {item.name}
            </option>
          );
        })}
    </Field>
    {errors.aauTeam && touched.aauTeam ? (
      <div className={styles.errorMessage}>
        {errors.aauTeam}
      </div>
    ) : null}
  </div>
</Col>

                      <Col md={4}>
                        <div className={styles.field}>
                          <p>Affiliation</p>
                          <Field
                            as="select"
                            name="aauAffil"
                            className={styles.dropdownStyle}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Affiliation</option>
                            {afiliationData &&
                              afiliationData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.aauAffil && touched.aauAffil ? (
                            <div className={styles.errorMessage}>
                              {errors.aauAffil}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <h4 className={styles.subHead}>Contact Information</h4>

                    <Row>
                      <Col md={3}>
                        <div className={styles.field}>
                          <p>Contact First Name</p>
                          <input
                            type="text"
                            name="aauCoach"
                            value={values.aauCoach}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.aauCoach && touched.aauCoach ? (
                            <div className={styles.errorMessage}>
                              {errors.aauCoach}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className={styles.field}>
                          <p>Contact Last Name</p>
                          <input
                            type="text"
                            name="aauCoachLast"
                            value={values.aauCoachLast}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.aauCoachLast && touched.aauCoachLast ? (
                            <div className={styles.errorMessage}>
                              {errors.aauCoachLast}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className={styles.field}>
                          <p>Phone</p>
                          <input
                            type="text"
                            name="aauCoachInfo"
                            value={values.aauCoachInfo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.aauCoachInfo && touched.aauCoachInfo ? (
                            <div className={styles.errorMessage}>
                              {errors.aauCoachInfo}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className={styles.field}>
                          <p>Email</p>
                          <input
                            type="text"
                            name="aauCoachEmail"
                            value={values.aauCoachEmail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.aauCoachEmail && touched.aauCoachEmail ? (
                            <div className={styles.errorMessage}>
                              {errors.aauCoachEmail}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                  </div>
                  <div className={styles.container}>
                    <div className={styles.title}>
                      <div>
                        <h4>Recruitment Status</h4>
                        <subtitle>
                          This section is used add the current recruitment
                          status If committed or signed please fill as many
                          sections as possible.
                        </subtitle>
                      </div>
                    </div>
                    
                    <Row>
                    <Col md={4}>
                        <div className={styles.field}>
                          <p>Status</p>
                          <Field
                            as="select"
                            name="Status"
                            onBlur={handleBlur}
                            className={styles.dropdownStyle}
                          >
                            <option value="">Select</option>
                            {statusData &&
                              statusData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.Status && touched.Status ? (
                            <div className={styles.errorMessage}>
                              {errors.Status}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      {values.Status !== "Undecided" && values.Status !== "" && (
                        <Col md={4}>
                          <div className={styles.field}>
                            <p>Team (optional)</p>
                            <AutoComplete
                              value={values.committedTeam}
                              suggestions={filteredTeams}
                              completeMethod={searchTeam}
                              field="name" // Indicates to display the name field from objects in the suggestions
                              onChange={(e) => {
                                // Check if the selected value is an object with a name field
                                const selectedTeamName = e.value && e.value.name ? e.value.name : e.value;
                                setFieldValue("committedTeam", selectedTeamName); // Assign the name value to committedTeam
                              }}
                              onBlur={handleBlur}
                              className={styles.dropdownStyle}
                              placeholder="Select or type a team"
                              forceSelection={false} // Allows manual input
                            />
                            {errors.committedTeam && touched.committedTeam && (
                              <div className={styles.errorMessage}>{errors.committedTeam}</div>
                            )}
                          </div>
                        </Col>
                      )}

                    </Row>

                  </div>
                  <div className={styles.container}>
                    <div className={styles.title}>
                      <div>
                        <h4>Projection Range</h4>
                        <subtitle>
                          This section is used add players projection range. If
                          confident please fill this section. (optional)
                        </subtitle>
                      </div>
                    </div>
                    <Row>
                      <Col md={4}>
                        <div className={styles.field}>
                          <p>Low</p>
                          <Field
                            as="select"
                            name="Prlow"
                            className={styles.dropdownStyle}
                            onBlur={handleBlur}
                          >
                            <option value="">Select</option>
                            {prLowData &&
                              prLowData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.Prlow && touched.Prlow ? (
                            <div className={styles.errorMessage}>
                              {errors.Prlow}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className={styles.field}>
                          <p>High</p>
                          <Field
                            as="select"
                            name="Prhigh"
                            className={styles.dropdownStyle}
                            onBlur={handleBlur}
                          >
                            <option value="">Select</option>
                            {prHighData &&
                              prHighData.map((item) => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.Prhigh && touched.Prhigh ? (
                            <div className={styles.errorMessage}>
                              {errors.Prhigh}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/*
                  <div className={styles.container}>
                    <div className={styles.title}>
                      <div>
                        <h4>PI Score</h4>
                        <subtitle>
                          This section is used add players PI score. If
                          confident please fill this section. (optional)
                        </subtitle>
                      </div>
                    </div>
                    <Row>
                      <Col md={4}>
                        <div className={styles.field}>
                          <p>Score</p>
                          <input
                            type="text"
                            name="Piscore"
                            value={values.Piscore}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.Piscore && touched.Piscore ? (
                            <div className={styles.errorMessage}>
                              {errors.Piscore}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  */}
                  <div className={styles.container}>
                    <div className={styles.title}>
                      <div>
                        <h4>Videos</h4>
                        {/* <subtitle>
                      This section is used add players PI score. If confident
                      please fill this section. (optional)
                    </subtitle> */}
                      </div>
                    </div>
                    <Row>
                      {Array.apply(null, Array(20)).map((el, index) => (
                        <Col md={4}>
                          <div className={styles.field}>
                            <p>Video {index + 1}</p>
                            <input
                              type="text"
                              name={`video${index + 1}`}
                              value={values["video" + (index + 1)]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  {/* <div className={styles.buttonContainer}>
                    <Row>
                      <Col md={4}>
                        <Button className={styles.secondary}>Preview</Button>
                      </Col>
                      <Col md={4}>
                        <Button className={styles.secondary}>Save Draft</Button>
                      </Col>
                      <Col md={4}>
                        <Button
                          disabled={loading}
                          type="submit"
                          onClick={handleSubmit}
                        >
                          {loading ? <Spinner animation="border" /> : "Publish"}
                        </Button>
                      </Col>
                    </Row>
                  </div> */}
                  {errorsResponse && (
                    <div className={styles.errorMessage}>
                      {JSON.stringify(errorsResponse)}
                    </div>
                  )}
                </>
                {/* <PlayerProfileForm noheader /> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                disabled={loading}
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                variant="primary"
              >
                {loading ? <Spinner animation="border" /> : " Save Changes"}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

// <div className={styles.modalContainer}>
//   <div onClick={close} className={styles.clickAway}></div>
//   <div className={styles.modal}>
//     <button onClick={close}>
//       <MdClose />
//     </button>
//     <div className={styles.content}>
//       <PlayerProfileForm />
//     </div>
//     <div className={styles.btns}>
//       <button onClick={close}>Cancel</button>
//       <button>Save</button>
//     </div>
//   </div>
// </div>

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  marginTop: "15px",
  width: "100%",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function StyledDropzone(props) {
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // For progress bar
  const [selectedFile, setSelectedFile] = useState(null); // Track selected file

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/gif": [".gif"],
      "image/bmp": [".bmp"],
      "image/tiff": [".tiff"],
      "image/webp": [".webp"],
      "image/svg": [".svg"],
    },
    multiple: false,
    maxSize: 5000000,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      // Reset state for new file
      setUploadComplete(false);
      setUploadProgress(0);
      setSelectedFile(file); // Store the selected file
      simulateUpload(file); // Simulate the file upload
      props.onDrop(file);
    },
  });

  // Simulate an upload process
  const simulateUpload = (file) => {
    setUploadProgress(0);
    const uploadInterval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(uploadInterval);
          setUploadComplete(true); // Mark as upload complete
          return 100;
        }
        return prevProgress + 20; // Simulate progress increments
      });
    }, 500); // Update progress every 500ms
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {uploadComplete ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <MdCheckCircle style={{ fontSize: "24px", color: "green" }} />
            <p style={{ marginLeft: "8px" }}>File Uploaded Successfully!</p>
          </div>
        ) : selectedFile ? (
          <div style={{ width: "100%", marginTop: "10px" }}>
            <p>Uploading: {selectedFile.name}</p>
            <progress value={uploadProgress} max="100" style={{ width: "100%" }} />
          </div>
        ) : (
          <>
            <MdCloudUpload style={{ fontSize: "24px" }} />
            <p>Upload file smaller than 5MB</p>
          </>
        )}
      </div>
      {props.errors && props.touched ? (
        <div className="errorMessage">{props.errors}</div>
      ) : null}
      {acceptedFiles.length > 0 && (
        <aside>
          <h6 className="mt-2">Files</h6>
          <ul>{files}</ul>
        </aside>
      )}
    </>
  );
}



const validationSchema = Yup.object().shape({
  FirstName: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters"),
  LastName: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters"),
  Year: Yup.string()
    .required("Year is required")
    .min(2, "Year must be at least 2 characters"),
  Position: Yup.string()
    .required("Position is required")
    .min(2, "Position must be at least 2 characters"),
  Hand: Yup.string().required("Hand is required"),
  Height: Yup.string()
    .required("Height is required")
    .min(2, "Height must be at least 2 characters"),
  viewing_privileges: Yup.array()
    .min(1, "At least one viewing privilege should be selected")
    .required("Viewing Privileges is required"),
});

const initialValues = {
  // Extra fields
  Title: "",

  // player profile
  FirstName: "",
  LastName: "",
  hometown_City: "",
  hometown_State_Country: "",
  Country: "",
  Year: "",
  DOB: "",
  Position: "",
  Hand: "",
  Height: "",
  Weight: "",
  Wingspan: "",
  "NCAA Eligible": "",
  //Headshot: "",
  Team: "",

  // Social Media
  Instagram: "",
  Twitter: "",
  Snapchat: "",

  // AAU Information
  aauTeam: "",
  aauAffil: "",
  aauCoach: "",
  aauCoachLast: "",
  aauCoachInfo: "",
  aauCoachEmail: "",


  // Recruitment Status
  Status: "",
  committedTeam: "",

  // Projection Range
  Prlow: "",
  Prhigh: "",
  Piscore: "",

  //Wingspan: "",
  Hometown: "",
  Age: "",
  TikTok: "",
  "Foller URL": "",
  TwitterURL: "",
  InstagramURL: "",
  fullHometown: "",
  pRange: "",
  //committedTeam: "",
  Display: "",
  video1: "",
  video2: "",
  video3: "",
  video4: "",
  video5: "",
  video6: "",
  video7: "",
  video8: "",
  video9: "",
  video10: "",
  creator: "",
  Owner: "",
  updated: "",
};
