import React, { useEffect } from "react";
import moment from "moment";
import { Row, Col, Modal } from "react-bootstrap";
import styles from "./preview.module.scss";

export function ViewReportModal({ show, handleClose, reportData,firstName, lastName  }) {
  useEffect(() => {
    console.log("yes", reportData);
  });

  useEffect(() => {
    if (reportData.reportContent)
      document.getElementById("content").innerHTML = reportData.reportContent;
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div>
        {/* ------------- REPORT FORM BEGINS -------------------*/}
        <Modal.Body style={{ padding: "2rem" }}>
          <div className={styles.container} style={{ marginTop: "0px" }}>
            <div className={styles.title}>
              <div>
                <h4>{`${firstName} ${lastName} Report`}</h4>
              </div>
            </div>

            <Row>
              <Col md={12}>
                
                  <p></p>
                  <p><b>Type: </b>{reportData.type}</p>
                  <p><b>Date: </b>{moment(reportData.reportDate).format('L')}</p>
                  <p><b>Title: </b>{reportData.reportPreview} </p>
                  {reportData.location && <p><b>Location: </b>{reportData.location} </p>}
                
              </Col>
              
              {Array.isArray(reportData.typeSpecificFields.roles) && reportData.typeSpecificFields.roles.some(role => role.trim().length > 0) && (
              <Col md={12}>
                <p><b>Roles: </b>{reportData.typeSpecificFields.roles.join(", ")}</p>
              </Col>
            )}


            {(reportData.typeSpecificFields.projectionRange && 
              (reportData.typeSpecificFields.projectionRange.low || reportData.typeSpecificFields.projectionRange.high)) && (
              <Col md={12}>
                <p>
                  <b>Projection Range: </b>{' '}
                  {`${reportData.typeSpecificFields.projectionRange.low !== reportData.typeSpecificFields.projectionRange.high ?
                    `${reportData.typeSpecificFields.projectionRange.low || ""} to ` : ""}
                    ${reportData.typeSpecificFields.projectionRange.high || ""}`}
                  {reportData.typeSpecificFields.projectionRange.confidence &&
                    ` (confidence level: ${reportData.typeSpecificFields.projectionRange.confidence})`}
                </p>
              </Col>
            )}

            {/* Add Stats Table - remove when not available*/}
            {reportData.type === "Scouting" && 
            Array.isArray(reportData.typeSpecificFields?.stats) && 
            reportData.typeSpecificFields.stats.some(stat => 
              Object.values(stat).some(value => {
                if (Array.isArray(value)) {
                  return value.length > 0; // Ensures non-empty arrays
                }
                return value && value !== '-'; // Ensures meaningful text/number values
              })
            ) && (
              <Col md={12} className="mt-3">
                <p>
                  <b>Stats:</b>{' '}
                </p>
                <div className="table-responsive">
                  <table className={`table table-sm ${styles.table}`} style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: '24%', minWidth: '180px', whiteSpace: "nowrap" }}>Game</th>
                        <th style={{ width: '12%', minWidth: '100px', whiteSpace: "nowrap" }}>Score</th>
                        <th style={{ width: '5%', minWidth: '60px', whiteSpace: "nowrap" }}>MIN</th>
                        <th style={{ width: '8%', minWidth: '80px', whiteSpace: "nowrap" }}>FG</th>
                        <th style={{ width: '8%', minWidth: '80px', whiteSpace: "nowrap" }}>3FG</th>
                        <th style={{ width: '8%', minWidth: '80px', whiteSpace: "nowrap" }}>FT</th>
                        <th style={{ width: '5%', minWidth: '40px', whiteSpace: "nowrap" }}>OR</th>
                        <th style={{ width: '5%', minWidth: '40px', whiteSpace: "nowrap" }}>TR</th>
                        <th style={{ width: '5%', minWidth: '40px', whiteSpace: "nowrap" }}>AST</th>
                        <th style={{ width: '5%', minWidth: '40px', whiteSpace: "nowrap" }}>TO</th>
                        <th style={{ width: '5%', minWidth: '40px', whiteSpace: "nowrap" }}>BLK</th>
                        <th style={{ width: '5%', minWidth: '40px', whiteSpace: "nowrap" }}>STL</th>
                        <th style={{ width: '5%', minWidth: '40px', whiteSpace: "nowrap" }}>PF</th>
                        <th style={{ width: '5%', minWidth: '40px', whiteSpace: "nowrap" }}>PTS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData.typeSpecificFields.stats.map((stat, index) => (
                        <tr key={index}>
                          <td style={{ minWidth: "180px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {`${stat.team} vs ${stat.opponent}`}
                          </td>
                          <td>{stat.score?.join('-') || '-'}</td>
                          <td>{stat.min || '-'}</td>
                          <td>{stat.fg?.join('-') || '-'}</td>
                          <td>{stat["3fg"]?.join('-') || '-'}</td>
                          <td>{stat.ft?.join('-') || '-'}</td>
                          <td>{stat.or || '-'}</td>
                          <td>{stat.tr || '-'}</td>
                          <td>{stat.ast || '-'}</td>
                          <td>{stat.to || '-'}</td>
                          <td>{stat.blk || '-'}</td>
                          <td>{stat.stl || '-'}</td>
                          <td>{stat.pf || '-'}</td>
                          <td>{stat.pts || '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
          )}






              
              {reportData.reportContent && (
                <Col md={12}>
                  <p>
                  <b>Report: </b>{' '}
                  </p>
                  <div id="content"></div>
                </Col>
              )}

              {/*reportData.typeSpecificFields.bottomLine && (
                <Col md={12}>
                  <p><b>Bottom Line: </b>{reportData.typeSpecificFields.bottomLine}</p>
                </Col>
              )*/}

              {(reportData.type === "General" ||
                reportData.type === "Medical" ||
                reportData.type === "Intel") && (
                <>
                  {/* Commented out URL Reference, Source, and Attachment */}
                  {/* {reportData.typeSpecificFields.urlReference && (
                    <p>
                      <b>URL Reference: </b>{" "}
                      {reportData.typeSpecificFields.urlReference}{" "}
                    </p>
                  )}
                  {reportData.typeSpecificFields.source && (
                    <p>
                      <b>Source: </b> {reportData.typeSpecificFields.source}{" "}
                    </p>
                  )}
                  {reportData.attachment && (
                    <>
                      <p>Attachment: </p>
                      <a href={reportData.attachment} download>
                        <img
                          src="/other.png"
                          width="100"
                          height="100"
                          alt="attachment"
                        />
                      </a>
                    </>
                  )} */}
                </>
              )}
            </Row>
          </div>
        </Modal.Body>
        {/* ------------- REPORT ENDS ENDS -------------------*/}
      </div>
    </Modal>
  );
}
