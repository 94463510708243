import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import { CSVLink } from "react-csv";
import csvtojson from "csvtojson";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

// dropzone
import { FileDropzone } from "./report/fileDropzone";

// editor for report
import RichEditor from "../article/rich-editor";

// react icons
import { BsArrowRightCircle } from "react-icons/bs";

// React Autocomplete
import { AutoComplete } from "primereact/autocomplete";

// css for removing focus blue glow
import "./index.css";

// react bootstrap
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { Button as PButton } from "primereact/button";

// components
import styles from "./Form.module.scss";

// header
import Header from "../header/header";

// forms
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
//  toast
import { toast } from "react-toastify";

// firebase
import { db, storage } from "../../firebase";
import { StatsTable } from "./report/statsTable.form";
import log_activity from "../activity/logger";
import { useAuth } from "../../Context/AuthContext";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";

export default function ReportForm() {
  const [loading, setLoading] = useState(false);
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState(null);
  const [clearFiles, setClearFiles] = useState(false);
  const [roleCount, setRoleCount] = useState(1);
  const options = ["General", "Medical", "Intel", "Scouting"];
  const [rolesDropdownData, setRolesDropdownData] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [clearQuillBoard, setClearQuillBoard] = useState(false);
  const initialSubFormValues = { urlReference: "", source: "" };
  const [csvReport, setCsvReport] = useState(null);
  const { isSuperAdmin } = useAuth();
  const selectCSV = useRef(null);
  // pr low and high dropdown data
  const [prLowData, setPrLowData] = useState([]);
  const [prHighData, setPrHighData] = useState([]);
  //const toast = useRef(null);
  const playerProfileNames = useRef([]);

  const { userInfo } = useAuth();

  const getExportReportsData = async () => {
    try {
      const response = await db.collection("reports").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      //console.log(data, "reports data");
      const csvReport = {
        data: data,
        headers: [
          { label: "Title", key: "title" },
          { label: "FirebaseIDKey", key: "playerProfile" },
          { label: "Date", key: "reportDate" },
          { label: "Type", key: "type" },
          { label: "Preview", key: "reportPreview" },
          { label: "Note", key: "reportContent" },
          { label: "Creator", key: "creator" },
        ],
        filename: `reports-${new Date().toDateString()}-${new Date().toTimeString()}.csv`,
      };

      setCsvReport(csvReport);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getExportReportsData();
    db.collection("player-profile")
      .get()
      .then((allDocs) => {
        const allPlayerProfiles = [];
        allDocs.forEach((doc) => {
          allPlayerProfiles.push({
            fullName: `${doc.data().FirstName} ${doc.data().LastName}`,
            id: doc.id,
            dob: doc.data().DOB,
          });
          playerProfileNames.current.push(
            `${doc.data().FirstName} ${doc.data().LastName}`
          );
        });
        setPlayerProfiles(allPlayerProfiles);
        getRolesData();
        // get prLow and High
        getPrLowData();
        getPrHighData();

      });
  }, []);
  const getRolesData = async () => {
    try {
      const response = await db.collection("roles-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      const sortedRolesData = data.sort((a, b) => a.name.localeCompare(b.name));

      setRolesDropdownData(sortedRolesData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getPrHighData = async () => {
    try {
      const response = await db.collection("hight-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => b.order - a.order);
      setPrHighData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getPrLowData = async () => {
    try {
      const response = await db.collection("low-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => b.order - a.order);
      setPrLowData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };


  const searchProfile = (event) => {
    let _filteredProfiles = [...playerProfiles];
    if (event.query.trim().length > 0) {
      _filteredProfiles = _filteredProfiles.filter((p) => {
        return p.fullName.toLowerCase().includes(event.query.toLowerCase());
      });
    }
    setFilteredProfiles(_filteredProfiles);
  };
  const searchType = (event) => {
    let _options = options;
    setFilteredOptions(_options);
  };

  const formik = useFormik({
    initialValues: {
      playerProfile: "",
      type: "General",
      reportPreview: "",
      location: "",
      typeSpecificFields: initialSubFormValues,
      reportDate: "",
      reportContent: "",
      drafted: true,
      attachment: "",
      creator: userInfo.firstName + " " + userInfo.lastName,
      createdAt: moment().unix(),
    },
    validationSchema: validationSchema(playerProfileNames),
    onSubmit: (data, { resetForm }) => {
      let values = cloneDeep(data);
      console.log(values, "values");
      setLoading(true);
      if (values.typeSpecificFields.hasOwnProperty("roles")) {
        values.typeSpecificFields["roles"] =
          values.typeSpecificFields.roles.filter(
            (r) => r != undefined && r !== ""
          );
      }
      db.collection("reports")
        .add({
          ...values,
          playerProfile: values.playerProfile.id,
        })
        .then((docRef) => {
          console.log("Document successfully written!");
          setLoading(false);
          resetForm({});
          setClearQuillBoard(true);
          setClearFiles(true);
          toast.info("Report Created Successfully", {
            theme: "colored",
          });
         log_activity(
                values.playerProfile.fullName,
                `New ${values.type} Report Added`,
                userInfo.firstName + " " + userInfo.lastName,
                
              );
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Error Creating Report", {
            theme: "colored",
          });
          console.error("Error writing document: ", error);
        });
    },
  });

  const getRolesDropdownsComp = useCallback(() => {
    let rolesArray = [];
    for (let i = 0; i < roleCount; i++) {
      rolesArray.push(
        <select
          key={i}
          className={styles.custDropdownStyle}
          onChange={formik.handleChange}
          value={formik.values.typeSpecificFields.roles[i]}
          name={`typeSpecificFields.roles[${i}]`}
          onBlur={formik.onBlur}
        >
          <option selected value={""}>
            Role {i + 1}
          </option>
          {rolesDropdownData?.map(({ id, name, value }) => (
            <option key={id} value={value}>
              {name}
            </option>
          ))}
        </select>
      );
    }
    return rolesArray;
  }, [
    roleCount,
    formik.values.typeSpecificFields.roles,
    formik.values.type,
    rolesDropdownData,
  ]);

  const itemTemplate = (item) => {
    return (
      <div>
        <b>{item.fullName}</b>
        <br />
        <code>DOB: {moment(item.dob).format("MM/DD/YYYY")}</code>
      </div>
    );
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csv = e.target.result;
        const jsonArray = await csvtojson().fromString(csv);
        console.log(jsonArray, "jsonArray");

        confirm1(jsonArray);
      };
      reader.readAsText(file);
    }
  };
  const confirm1 = (payload) => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      // handle acccept
      accept: () => {
        handleAcceptFileUpload(payload);
      },

      reject,
    });
  };

  const confirm2 = () => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        handleDeleteAllData();
      },
      reject,
    });
  };
  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const handleDeleteAllData = async () => {
    let response = await db.collection("reports").get();
    let data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    let response2 = data.map(async (item) => {
      let response = await db.collection("reports").doc(item.id).delete();
      return response;
    });
    response2 = await Promise.all(response2);
    if (response2.length > 0) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Reports Deleted Successfully",
        life: 3000,
      });
      // getPlayerProfiles();
      getExportReportsData();
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong",
        life: 3000,
      });
    }
  };
  const handleAcceptFileUpload = async (payload) => {
    // upload all the data Array multiple objects to firebase
    let response = payload.map(async (item) => {
      let reportRef = db.collection("reports").doc();
      let reportOBJ = {
        createdAt: moment(item.CreatedDate, "YYYY-MM-DD").unix(),
        creator: item.creator,
        title: item.Title,
        playerProfile: item.FirebaseIDKey,
        reportContent: item.Note,
        previewPreview: item.previousPreview,
        reportPreview: item.Preview,
        location: item.reportLocation,
        //need to add location
        type: item.Type,
        reportDate: item.reportDate,
        wixID: item.wixID,
        drafted: false,
        creator: item.creator,
        typeSpecificFields: {
         
          projectionRange: [{ low: item.prLow, high: item.prHigh, confidence: item.confidenceLvl },],
          nbaProjectionRange: [{ nbaLow: item.nbaLow, nbaBullseye: item.nbaBullseye, nbaHigh: item.nbaHigh, nbaConfidence: item.nbaConfidence },],
          nbaDraftProjection: [{ draftProjection: item.draftProjection, draftConfidenceLvl: item.draftConfidenceLvl },],
          roles: [""],
          stats: {
            team: "",
            opponent: "",
            score: [],
            min: "",
            fg: [],
            "3fg": [],
            ft: [],
            or: "",
            tr: "",
            ast: "",
            to: "",
            blk: "",
            stl: "",
            pf: "",
            pts: "",
            }, 
          source: "",
          urlReference: "",
        },
      };
      let response = await reportRef.set(reportOBJ);
      return response;
    });

    response = await Promise.all(response);

    if (response.length > 0) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Reports Added Successfully",
        life: 3000,
      });
      // getPlayerProfiles();
      getExportReportsData();
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong",
        life: 3000,
      });
    }
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };
  //   useEffect(()=>{
  //     // setClearQuillBoard(true)
  // console.log(clearQuillBoard)
  //     console.log(formik.values.playerProfile)
  //     console.log(formik.errors.playerProfile)
  //   }, [formik.values.playerProfile])

  const ref = useRef(null);


  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Header />
      {/* ------------- REPORT FORM BEGINS -------------------*/}

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Report</h4>
            <subtitle>
              This section is used to create reports. Please fill all sections.{" "}
            </subtitle>
          </div>

          {/* 
          { isSuperAdmin() && (
            <div>
              //<Button
              //label="Update Age from DOB"
              //onClick={handleUpdateAgefromDOB}
              // </div>></Button>
              <input
                type="file"
                ref={selectCSV}
                // only accept .csv files
                accept=".csv"
                style={{
                  display: "none",
                }}
                onChange={handleFileUpload}
              />
              <PButton
                onClick={() => selectCSV.current.click()}
                icon="pi pi-check"
                label="Import"
                severity="success"
                // style={{
                //   backgroundColor: "green",
                //   borderColor: "green",
                // }}
                size="small"
              ></PButton>
              {csvReport && (
                <CSVLink {...csvReport}>
                  <PButton
                    size="small"
                    icon="pi pi-times"
                    label="Export"
                    severity="danger"
                    style={{
                      marginLeft: "10px",
                    }}
                  ></PButton>
                </CSVLink>
              )}{" "}
              <PButton
                onClick={confirm2}
                icon="pi pi-times"
                label="Delete"
                severity="danger"
                style={{
                  marginLeft: "10px",
                  backgroundColor: "red",
                  borderColor: "red",
                }}
                size="small"
              ></PButton>
            </div>
          )}
            */}


        </div>
       

        <Row>
          <Col md={6}>
            <div className={styles.field}>
              <p>Player Profile</p>
              <AutoComplete
                className={styles.fullWidth}
                value={formik.values.playerProfile}
                suggestions={filteredProfiles}
                completeMethod={searchProfile}
                field="fullName"
                itemTemplate={itemTemplate}
                name="playerProfile"
                dropdown
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.errors.playerProfile && formik.touched.playerProfile ? (
                <div className={styles.errorMessage}>
                  {formik.errors.playerProfile + " "}
                  {formik.errors.playerProfile ===
                    "This player profile doesn't exist" && (
                    <Link
                      className={styles.createProfLink}
                      to="/player-profile/create"
                    >
                      Create Player Profile <BsArrowRightCircle />
                    </Link>
                  )}
                </div>
              ) : null}
            </div>
            <div className={styles.field}>
              <p>Report Date</p>
              <input
                type="date"
                name="reportDate"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reportDate}
              />
              {formik.errors.reportDate && formik.touched.reportDate ? (
                <div className={styles.errorMessage}>
                  {formik.errors.reportDate}
                </div>
              ) : null}
            </div>

            <div className={styles.field}>
      <p>Type</p>
      <AutoComplete
        ref={ref}
        className={styles.fullWidth}
        dropdown
        forceSelection
        readOnly
        value={formik.values.type}
        suggestions={filteredOptions}
        completeMethod={searchType}
        name="type"
        onChange={(e) => {
          formik.handleChange(e);
          let type = e.value;
          let typeSpecificFieldsValues;
          if (["General", "Medical"].includes(type)) {
            typeSpecificFieldsValues = initialSubFormValues;
          } else if (type === "Intel") {
            typeSpecificFieldsValues = { source: "" };
          } else {
            setRoleCount(1);
            typeSpecificFieldsValues = {
              stats: [{
                team: "",
                opponent: "",
                score: [],
                min: "",
                fg: [],
                "3fg": [],
                ft: [],
                or: "",
                tr: "",
                ast: "",
                to: "",
                blk: "",
                stl: "",
                pf: "",
                pts: "",
              }],
              location: "",
              roles: [],
              projectionRange: { low: "", high: "" }
            };
          }
          formik.setFieldValue("typeSpecificFields", typeSpecificFieldsValues);
        }}
        onBlur={formik.handleBlur}
        // @ts-ignore
        onClick={(e) => ref.current?.search(e, "", "dropdown")}
      />
      {formik.errors.type && formik.touched.type ? (
        <div className={styles.errorMessage}>{formik.errors.type}</div>
      ) : null}
    </div>
          </Col>

          <Row>
            <Col
              md={["General", "Medical"].includes(formik.values.type) ? 6 : 12}
            >
              <div className={styles.field}>
                <p>Report Preview/Event</p>
                <input
                  type="text"
                  name="reportPreview"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reportPreview}
                />
                {formik.errors.reportPreview && formik.touched.reportPreview ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.reportPreview}
                  </div>
                ) : null}
              </div> 
            </Col>
            <Col md={6}>
              <div className={styles.field}>
                {["General", "Medical"].includes(formik.values.type) && (
                  <>
                    <p>URL Reference* (optional)</p>
                    <input
                      type="text"
                      name="typeSpecificFields.urlReference"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.typeSpecificFields.urlReference}
                    />
                    {formik.errors.typeSpecificFields?.urlReference &&
                    formik.touched.typeSpecificFields?.urlReference ? (
                      <div className={styles.errorMessage}>
                        {formik.errors.typeSpecificFields?.urlReference}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </Col>
          </Row>
          <div className={styles.field}>
            {formik.values.type === "Scouting" && (
              <>
              <div className={styles.field}>
                <p>Location</p>
                <input
                  type="text"
                  name="location"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.location}
                />
                {formik.errors.location && formik.touched.location ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.location}
                  </div>
                ) : null}
              </div>

              <p>Projection Range</p>
                <div className="d-flex flex-wrap">
                  <div className="px-2">
                    <p>Low</p>
                    <select
                      name="typeSpecificFields.projectionRange.low"
                      onChange={handleChange}
                      value={formik.values.typeSpecificFields.projectionRange.low}
                      className={styles.dropdownStyle}
                    >
                      <option value="">Select</option>
                      {prLowData.map((item) => (
                        <option key={item.id} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="px-2">
                    <p>High</p>
                    <select
                      name="typeSpecificFields.projectionRange.high"
                      onChange={handleChange}
                      value={formik.values.typeSpecificFields.projectionRange.high}
                      className={styles.dropdownStyle}
                    >
                      <option value="">Select</option>
                      {prHighData.map((item) => (
                        <option key={item.id} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="px-2">
                    <p>Confidence</p>
                    <select
                      name="typeSpecificFields.projectionRange.confidence"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={styles.dropdownStyle}
                      value={formik.values.typeSpecificFields.projectionRange.confidence}
                    >
                      <option value="">Select</option>
                      {[...Array(21).keys()].reverse().map((index) => (
                        <option key={index} value={`${index * 5}%`}>{`${index * 5}%`}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {(formik.errors.typeSpecificFields?.projectionRange?.low ||
                  formik.errors.typeSpecificFields?.projectionRange?.high) &&
                (formik.touched.typeSpecificFields?.projectionRange?.low ||
                  formik.touched.typeSpecificFields?.projectionRange?.high) ? (
                  <>
                    <div className={styles.errorMessage}>
                      {formik.errors.typeSpecificFields.projectionRange.low}
                    </div>
                    <div className={styles.errorMessage}>
                      {formik.errors.typeSpecificFields.projectionRange.high}
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>
          <div className={styles.field}>
            {formik.values.type === "Scouting" && (
              <>
                <p>Roles</p>
                <div className={styles.grayContainer}>
                  {formik.values.typeSpecificFields.roles &&
                    getRolesDropdownsComp()}
                  <button
                    onClick={() => setRoleCount((prevCount) => prevCount + 1)}
                    className={styles.roleBtn}
                    style={{ width: "auto" }}
                  >
                    <b>+</b>
                  </button>
                </div>
                {formik.errors.typeSpecificFields?.roles &&
                formik.touched.typeSpecificFields?.roles ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.typeSpecificFields?.roles}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div>
            {formik.values.type === "Scouting" && (
              <>
                <p style={{ fontWeight: "500" }}>Stats</p>
                <StatsTable formik={formik} />
                {formik.errors.typeSpecificFields?.stats &&
                formik.touched.typeSpecificFields?.stats ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.typeSpecificFields?.stats}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className={styles.field}>
            <p>Report</p>
            <RichEditor
              value={""}
              setClear={setClearQuillBoard}
              clear={clearQuillBoard}
              handleChange={(value) => {
                formik.setFieldValue("reportContent", value);
              }}
            />
            {formik.errors.reportContent && formik.touched.reportContent ? (
              <div className={styles.errorMessage}>
                {formik.errors.reportContent}
              </div>
            ) : null}
          </div>

          <div className={styles.field}>
            {formik.values.type !== "Scouting" && (
              <>
                <p>Source</p>
                <input
                  type="text"
                  name="typeSpecificFields.source"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.typeSpecificFields.source}
                />
                {formik.errors.typeSpecificFields?.source &&
                formik.touched.typeSpecificFields?.source ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.typeSpecificFields?.source}
                  </div>
                ) : null}
              </>
            )}
          </div>
          
          <div className={styles.field}>
            <p>Upload Attachment</p>
          </div>

          <FileDropzone
            setLoading={setLoading}
            clearFiles={clearFiles}
            formik={formik}
          />
        </Row>
      </div>
      <div className={styles.buttonContainer}>
        <Row>
          {/*
          <Col md={4}>
            <Button className={styles.secondary}>Preview</Button>
          </Col>
          <Col md={4}>
            <Button
              disabled={loading}
              className={styles.secondary}
              onClick={() => {
                formik.setFieldValue("drafted", true);
                formik.handleSubmit();
              }}
            >
              Save Draft
            </Button>
          </Col>
          */}
          <Col md={4}>
            <Button
              disabled={loading}
              type="submit"
              onClick={() => {
                formik.setFieldValue("drafted", false);
                formik.handleSubmit();
              }}
            >
              {loading ? <Spinner animation="border" /> : "Publish"}
            </Button>
          </Col>
        </Row>
      </div>

      {/* ------------- REPORT ENDS ENDS -------------------*/}
    </div>
  );
}

const validationSchema = (allPlayerProfileNames) => {
  return Yup.object({}).shape({
    playerProfile: Yup.lazy((value) => {
      if (value === undefined) {
        return Yup.string().required("Required");
      } else if (value.constructor === String) {
        return Yup.string()
          .required("Required")
          .oneOf(
            allPlayerProfileNames.current,
            "This player profile doesn't exist"
          )
          .test(
            "ExistingProfile",
            `Profile/s with this name exists. Please Select`,
            async (value2) =>
              (await allPlayerProfileNames.current.filter(
                (p) => p.fullName === value2
              ).length) > 0
          );
      } else {
        return Yup.object().required("Required");
      }
    }),
    reportDate: Yup.date().required("Required"),
    type: Yup.string().required("Required"),
    reportPreview: Yup.string().required("Required"),
    //location: Yup.string().required("Required"),
    typeSpecificFields: Yup.lazy((value) => {
      let schema = {};
      if (value.hasOwnProperty("urlReference"))
        schema["urlReference"] = Yup.string().notRequired();
      if (value.hasOwnProperty("projectionRange"))
        schema["projectionRange"] = Yup.object().shape(
          {
            low: Yup.mixed()
              .typeError("The value should be a number")
              .when("high", {
                is: (val) => val !== undefined,
                then: (schema) =>
                  schema.required("Enter both low and high or enter none"),
              }),
            high: Yup.mixed()
              .typeError("The value should be a number")
              .when("low", {
                is: (val) => val !== undefined,
                then: (schema) =>
                  schema.required("Enter both low and high or enter none"),
              }),
          },
          [["high", "low"]]
        );
      return Yup.object().shape(schema).required();
    }),
  });
};
