import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import styles from "./subs.module.scss";
import { Formik, Form, Field } from "formik";
import { isEmptyField, isValidEmail } from "../../utils/helpers";
import { db } from "../../firebase";
import { toast, ToastContainer } from "react-toastify";
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import "react-toastify/dist/ReactToastify.css";

const EditMemberForm = ({ open, handleToggle, refreshMembers, member }) => {
  const initialValues = {
    firstName: member?.firstName || "",
    lastName: member?.lastName || "",
    org: member?.org || "",
    orgTitle: member?.orgTitle || "",
    subStart: member?.subStart ? moment(member.subStart.toDate()).format('YYYY-MM-DD') : "",
    subEnd: member?.subEnd ? moment(member.subEnd.toDate()).format('YYYY-MM-DD') : "",
    memberType: member?.memberType || "",
    email: member?.email || "",
  };

  const handleDateChange = (event, fieldName, setFieldValue) => {
    const { value } = event.target;
    setFieldValue(fieldName, value);
  };

  const handleSubmitForm = async (values) => {
    try {
      const subStartDate = values.subStart ? new Date(values.subStart + "T00:00:00") : null;
      const subEndDate = values.subEnd ? new Date(values.subEnd + "T00:00:00") : null;

      const updatedValues = {
        ...values,
        subStart: subStartDate ? Timestamp.fromDate(subStartDate) : null,
        subEnd: subEndDate ? Timestamp.fromDate(subEndDate) : null,
      };

      // Remove email from update since we shouldn't change it
      delete updatedValues.email;

      await db.collection("Accounts").doc(member.id).update(updatedValues);
      toast.success("Member updated successfully!");
      refreshMembers();
      handleToggle();
    } catch (error) {
      console.error("Error updating member:", error);
      toast.error("Error updating member: " + error.message);
    }
  };

  const validateForm = (values) => {
    let errors = {};

    if (isEmptyField(values.firstName)) errors.firstName = "First Name is required";
    if (isEmptyField(values.lastName)) errors.lastName = "Last Name is required";
    if (isEmptyField(values.memberType)) errors.memberType = "Member Type is required";
    if (isEmptyField(values.org)) errors.org = "Organization is required";

    return errors;
  };

  return (
    <>
      <ToastContainer />
      <Modal show={open} onHide={handleToggle} className={styles.addFormModalContainer}>
        <div className={styles.modalDialog}>
          <Modal.Header className={styles.modalHeader}>
            <Modal.Title>
              <h3>Edit Member</h3>
            </Modal.Title>
            <Button variant="close" onClick={handleToggle} />
          </Modal.Header>

          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validate={validateForm}
              onSubmit={handleSubmitForm}
              enableReinitialize
            >
              {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <label>First Name</label>
                    <Field
                      name="firstName"
                      type="text"
                      className={styles.inputField}
                    />
                    {errors.firstName && touched.firstName && (
                      <p className={styles.error}>{errors.firstName}</p>
                    )}
                  </div>

                  <div>
                    <label>Last Name</label>
                    <Field
                      name="lastName"
                      type="text"
                      className={styles.inputField}
                    />
                    {errors.lastName && touched.lastName && (
                      <p className={styles.error}>{errors.lastName}</p>
                    )}
                  </div>

                  <div>
                    <label>Organization</label>
                    <Field
                      name="org"
                      type="text"
                    />
                    {errors.org && touched.org && (
                      <p className={styles.error}>{errors.org}</p>
                    )}
                  </div>

                  <div>
                    <label>Organization Title</label>
                    <Field
                      name="orgTitle"
                      type="text"
                    />
                  </div>

                  <div>
                    <label>Start Date</label>
                    <Field
                      name="subStart"
                      type="date"
                      onChange={(event) => {
                        handleDateChange(event, 'subStart', setFieldValue);
                      }}
                    />
                  </div>

                  <div>
                    <label>Renewal Date</label>
                    <Field
                      name="subEnd"
                      type="date"
                      onChange={(event) => {
                        handleDateChange(event, 'subEnd', setFieldValue);
                      }}
                    />
                  </div>

                  <div>
                    <label>Email</label>
                    <Field
                      name="email"
                      type="email"
                      disabled
                    />
                  </div>

                  <div>
                    <label>Member Type</label>
                    <Field
                      name="memberType"
                      as="select"
                    >
                      <option value="executive">Executive</option>
                      <option value="ncaa">NCAA</option>
                      <option value="demo">Demo</option>
                      <option value="admin">Admin</option>
                      <option value="super-admin">Super Admin</option>
                    </Field>
                    {errors.memberType && touched.memberType && (
                      <p className={styles.error}>{errors.memberType}</p>
                    )}
                  </div>

                  <Modal.Footer className={styles.modalFooter}>
                    <Button variant="secondary" onClick={handleToggle}>
                      Close
                    </Button>
                    <Button variant="primary" type="submit">
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default EditMemberForm;