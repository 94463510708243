import { useState, useEffect, useMemo } from "react";
import styles from "./SchoolDropdown.module.scss";
import Header from "../../header/header";
import { FaSortAmountDownAlt } from "react-icons/fa";
import AddModal from "./addModal";
import EditModal from "./editModal";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { CustomSpinner } from "../../shared/Spinner";
import { MdModeEdit, MdRestoreFromTrash } from "react-icons/md";
import DeleteModal from "../../shared/DeleteModal";
import DeleteCollectionModal from "../../shared/DeleteCollection";
import { Button, Select } from "antd";

const { Option } = Select;

export default function SchoolDropdown() {
  // Modal and loading state
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // Data states (raw data from Firestore)
  const [rawSchoolData, setRawSchoolData] = useState([]);
  const [suggestedTeams, setSuggestedTeams] = useState([]);

  // Filter states (exact match)
  const [filterStateValue, setFilterStateValue] = useState("");
  const [filterIdentifierValue, setFilterIdentifierValue] = useState("");

  // Modal and deletion states
  const [editModelToggle, setEditModelToggle] = useState(false);
  const [selectedDeleteSchool, setSelectedDeleteSchool] = useState(false);
  const [selectedDeleteTeam, setSelectedDeleteTeam] = useState(null);
  const [showDeleteCollectionModal, setShowDeleteCollectionModal] = useState(false);
  const [currentCollection, setCurrentCollection] = useState(null); // Track the current collection

  // Filter the raw school data based on exact match for state and identifier.
  // If no filters are applied, displaySchoolData returns the entire rawSchoolData.
  const displaySchoolData = useMemo(() => {
    let data = [...rawSchoolData];
    if (filterStateValue) {
      data = data.filter((item) => item.state === filterStateValue);
    }
    if (filterIdentifierValue) {
      data = data.filter((item) => item.identifier === filterIdentifierValue);
    }
    return data;
  }, [rawSchoolData, filterStateValue, filterIdentifierValue]);

  // Compute unique options for State from the raw data.
  const stateOptions = useMemo(() => {
    const states = rawSchoolData.map((item) => item.state).filter(Boolean);
    const uniqueStates = Array.from(new Set(states));
    uniqueStates.sort();
    return uniqueStates;
  }, [rawSchoolData]);

  // Compute unique options for Identifier from the raw data.
  const identifierOptions = useMemo(() => {
    const ids = rawSchoolData.map((item) => item.identifier).filter(Boolean);
    const uniqueIds = Array.from(new Set(ids));
    uniqueIds.sort();
    return uniqueIds;
  }, [rawSchoolData]);

  useEffect(() => {
    getSchoolData();
    getSuggestedTeams();
  }, []);

  const getSchoolData = async () => {
    setLoading(true);
    try {
      const response = await db.collection("school-dropdown").get();
      const data = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      // Sort by team name (using "name" or "value")
      data.sort((a, b) => {
        const aName = a.name || a.value || "";
        const bName = b.name || b.value || "";
        return aName.localeCompare(bName);
      });
      setRawSchoolData(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const getSuggestedTeams = async () => {
    setLoading(true);
    try {
      const response = await db.collection("team-suggestion-dropdown").get();
      const data = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setSuggestedTeams(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const handleDelete = (id) => {
    setLoading(true);
    db.collection("school-dropdown")
      .doc(id)
      .delete()
      .then(() => {
        setLoading(false);
        toast.error("School Item Deleted Successfully", { theme: "colored" });
        getSchoolData();
      })
      .catch((error) => {
        toast.error("Error Deleting School Item", { theme: "colored" });
      });
  };

  const handleDeleteTeam = (id) => {
    setLoading(true);
    db.collection("team-suggestion-dropdown")
      .doc(id)
      .delete()
      .then(() => {
        setLoading(false);
        toast.error("Team Suggestion Deleted Successfully", { theme: "colored" });
        getSuggestedTeams();
      })
      .catch((error) => {
        toast.error("Error Deleting Team Suggestion", { theme: "colored" });
      });
  };

  const deleteCollection = () => {
    setLoading(true);
    db.collection("school-dropdown")
      .get()
      .then((querySnapshot) => {
        const batch = db.batch();
        querySnapshot.forEach((doc) => batch.delete(doc.ref));
        return batch.commit();
      })
      .then(() => {
        setLoading(false);
        toast.success("School Collection Deleted Successfully", { theme: "colored" });
        setRawSchoolData([]);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error Deleting School Collection", { theme: "colored" });
      });
  };

  const importData = (file) => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileData = event.target.result;
      const lines = fileData.split(/\r\n|\n/);
      const headers = lines[0].split(",");
      const importedData = [];

      for (let i = 1; i < lines.length; i++) {
        const row = lines[i].split(",");
        if (row.length === headers.length) {
          const rowData = {};
          for (let j = 0; j < headers.length; j++) {
            rowData[headers[j]] = row[j];
          }
          importedData.push(rowData);
        }
      }

      try {
        const batch = db.batch();
        importedData.forEach((data) => {
          const docRef = db
            .collection("school-dropdown")
            .doc(data.id || db.collection("school-dropdown").doc().id);
          batch.set(docRef, data, { merge: true });
        });
        await batch.commit();
        setLoading(false);
        toast.success("Data Imported Successfully", { theme: "colored" });
        getSchoolData();
      } catch (error) {
        setLoading(false);
        toast.error("Error Importing Data", { theme: "colored" });
      }
    };

    reader.readAsText(file);
  };

  // UPDATED EXPORT FUNCTION:
  // Now, instead of exporting the entire database, we export only the filtered data
  // (displaySchoolData). If no filters are applied, displaySchoolData equals rawSchoolData.
  const exportData = () => {
    if (!displaySchoolData || displaySchoolData.length === 0) {
      toast.error("No data available for export.");
      return;
    }

    // Sort the filtered data alphabetically by team name (using 'name' or 'value')
    const sortedData = [...displaySchoolData].sort((a, b) => {
      const aName = a.name || a.value || "";
      const bName = b.name || b.value || "";
      return aName.localeCompare(bName);
    });

    // CSV headers
    const headers = ["Team", "Identifier", "Contact Name", "Phone", "Email"];

    // Helper function to properly format CSV values
    const formatCSVValue = (value) => {
      if (!value) return "";
      return value.includes(",") ? `"${value}"` : value;
    };

    // Build CSV rows from the filtered (and sorted) data
    const rows = sortedData.map((item) => [
      formatCSVValue( item.name), // Team
      formatCSVValue(item.identifier), // Identifier
      formatCSVValue(`${item.coachFN || ""} ${item.coachLN || ""}`.trim()), // Contact Name
      formatCSVValue(item.phone), // Phone
      formatCSVValue(item.email), // Email
    ]);

    // Convert to CSV format and trigger download
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers, ...rows].map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "teams_export.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (!rawSchoolData && !loading) {
    return <div>Error loading school data</div>;
  }

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <div className={styles.title}>
          <h4>Schools and Teams</h4>
          <p>
            This section is used to view existing school and team options within the database.
            Any contact information will be used to autopopulate in forms.
          </p>
        </div>
        <hr />

        {suggestedTeams.length > 0 && (
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div>
                <span>Suggested Teams</span>
                <span>{suggestedTeams.length}</span>
              </div>
            </div>
            <div className={styles.row}>
              <div>Team</div>
              <div>State</div>
              <div>Identifier</div>
              <div>First Name</div>
              <div>Last Name</div>
              <div>Phone</div>
              <div>Email</div>
              <div>Actions</div>
            </div>
            {suggestedTeams.map((team) => (
              <div key={team.id} className={styles.row}>
                <div>{team.value}</div>
                <div>{team.state}</div>
                <div>{team.identifier}</div>
                <div>{team.coachFN}</div>
                <div>{team.coachLN}</div>
                <div>{team.phone}</div>
                <div>{team.email}</div>
                <div>
                  <MdModeEdit
                    onClick={() => {
                      setEditModelToggle(team);
                      setCurrentCollection("team-suggestion-dropdown");
                    }}
                   
                  />
                  <MdRestoreFromTrash
                    onClick={() => {
                      setSelectedDeleteTeam(team.id);
                    }}
                
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div>
              <span>Total</span>
              <span>{displaySchoolData.length}</span>
            </div>
            <div>
              <button
                onClick={exportData}
                className={styles.exportButton}
                style={{ backgroundColor: "black" }}
              >
                Export Data
              </button>
            </div>
            <button
              onClick={() => {
                setOpenEditModal(true);
              }}
            >
              Add Item
            </button>
          </div>
          {/* Table header row with searchable, scrollable Select filters for State and Identifier */}
          <div className={styles.row}>
            <div>Team</div>
            <div>
              <Select
                bordered={false}
                showSearch
                allowClear
                style={{ width: 150 }}
                placeholder="State"
                optionFilterProp="children"
                onChange={(value) => setFilterStateValue(value || "")}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {stateOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <Select
                bordered={false}
                showSearch
                allowClear
                style={{ width: 150 }}
                placeholder="Identifier"
                optionFilterProp="children"
                onChange={(value) => setFilterIdentifierValue(value || "")}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {identifierOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
            <div>First Name</div>
            <div>Last Name</div>
            <div>Phone</div>
            <div>Email</div>
            <div>Actions</div>
          </div>
          {displaySchoolData.map((item) => (
            <div key={item.id} className={styles.row}>
              <div>{item.value}</div>
              <div>{item.state}</div>
              <div>{item.identifier}</div>
              <div>{item.coachFN}</div>
              <div>{item.coachLN}</div>
              <div>{item.phone}</div>
              <div>{item.email}</div>
              <div>
                <MdModeEdit
                  onClick={() => {
                    setEditModelToggle(item);
                    setCurrentCollection("school-dropdown");
                  }}
                
                />
                <MdRestoreFromTrash
                  onClick={() => {
                    setSelectedDeleteSchool(item.id);
                  }}
                  
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <DeleteCollectionModal
        handleClose={() => setShowDeleteCollectionModal(false)}
        show={showDeleteCollectionModal}
        handleConfirm={deleteCollection}
        title="Delete Collection"
        message="Are you sure you want to delete the entire collection? This action cannot be undone."
      />

      <DeleteModal
        handleClose={() => setSelectedDeleteSchool(false)}
        show={selectedDeleteSchool}
        handleConfirm={() => handleDelete(selectedDeleteSchool)}
        title="Delete Item"
        message="Are you sure you want to delete the item? This action cannot be undone."
      />

      <DeleteModal
        handleClose={() => setSelectedDeleteTeam(null)}
        show={selectedDeleteTeam}
        handleConfirm={() => handleDeleteTeam(selectedDeleteTeam)}
        title="Delete Team"
        message="Are you sure you want to delete the team suggestion? This action cannot be undone."
      />

      {openEditModal && (
        <AddModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          reloadData={getSchoolData}
        />
      )}
      {editModelToggle && (
        <EditModal
          data={editModelToggle}
          setOpen={setEditModelToggle}
          reloadData={() => {
            getSchoolData();
            getSuggestedTeams();
          }}
          collection={currentCollection}
        />
      )}
    </div>
  );
}
