import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { useState } from "react";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";

export default function AddEventModal({ setOpen, refreshEvents }) {
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [note, setNote] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");

  const close = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (!title || !startDateTime || !endDateTime) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      await addDoc(collection(db, "pi-calendar"), {
        title,
        location,
        note,
        startDateTime: new Date(startDateTime).getTime(), // Store as timestamp
        endDateTime: new Date(endDateTime).getTime(),     // Store as timestamp
      });

      refreshEvents(); // Refresh calendar events
      close();
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>
        <div className={styles.content}>
          <h3>Add Event</h3>
          <span>Fill out the details below to create a new event.</span>
          <hr />
          <div className={styles.inputs}>
            <span>Event Title</span>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />

            <span>Start Date & Time</span>
            <input type="datetime-local" value={startDateTime} onChange={(e) => setStartDateTime(e.target.value)} />

            <span>End Date & Time</span>
            <input type="datetime-local" value={endDateTime} onChange={(e) => setEndDateTime(e.target.value)} />

            <span>Location</span>
            <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} />

            <span>Note</span>
            <textarea value={note} onChange={(e) => setNote(e.target.value)}></textarea>
          </div>
        </div>
        <div className={styles.btns}>
          <button onClick={close}>Cancel</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
}
