import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// firebase
import { db } from "../../../firebase";

export default function EditModal({ data, setOpen, reloadData, collection }) {
  const close = () => {
    setOpen(false);
  };

  const handleApprove = () => {
    const newAAUData = {
      name: formik.values.name,
      value: formik.values.value,
      aauAffilation: formik.values.aauAffilation,
      aauCoach: formik.values.aauCoach,
      aauCoachLast: formik.values.aauCoachLast,
      aauCoachInfo: formik.values.aauCoachInfo,
      aauCoachEmail: formik.values.aauCoachEmail,
    };

    db.collection("AAUTeam-dropdown").add(newAAUData)
      .then(() => {
        db.collection("aau-suggestion").doc(data.id).delete()
          .then(() => {
            toast.success("AAU Team moved to Approved List successfully", {
              theme: "colored",
            });
            close();
            reloadData();
          })
          .catch((error) => {
            toast.error("Error removing AAU Team from Dropdown", {
              theme: "colored",
            });
            console.error("Error removing AAU team: ", error);
          });
      })
      .catch((error) => {
        toast.error("Error adding AAU Team to Approved List", {
          theme: "colored",
        });
        console.error("Error adding AAU team: ", error);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      value: data.value || "",
      aauAffilation: data.aauAffilation || "",
      aauCoach: data.aauCoach || "",
      aauCoachLast: data.aauCoachLast || "",
      aauCoachInfo: data.aauCoachInfo || "",
      aauCoachEmail: data.aauCoachEmail || "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.value) {
        errors.value = "Required";
      }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      db.collection(collection)
        .doc(data.id)
        .update({
          ...values,
        })
        .then(() => {
          close();
          resetForm();
          toast.success("AAU Team Item Updated Successfully", {
            theme: "colored",
          });
          reloadData();
        })
        .catch((error) => {
          toast.error("Error Updating AAU Team Item", {
            theme: "colored",
          });
          console.error("Error updating document: ", error);
        });
    },
  });

  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>
        <form onSubmit={formik.handleSubmit} className="w-100">
          <div className={styles.content}>
            <h3>AAU Teams</h3>
            <span>
              This section is used to edit existing AAU Team options in the dropdown.
            </span>
            <hr />
            <div className={styles.inputs}>
              <span>Name</span>
              <input
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                onBlur={formik.handleBlur}
              />
              <span className={styles.error}>
                {formik.errors.name && formik.touched.name && formik.errors.name}
              </span>

              <span>Value</span>
              <input
                type="text"
                name="value"
                onChange={formik.handleChange}
                value={formik.values.value}
                onBlur={formik.handleBlur}
              />
              <span className={styles.error}>
                {formik.errors.value && formik.touched.value && formik.errors.value}
              </span>

              <span>AAU Affiliation</span>
              <select
                name="aauAffilation"
                onChange={formik.handleChange}
                value={formik.values.aauAffilation}
                onBlur={formik.handleBlur}
                className={styles.dropdownStyle}
              >
                <option value="">Choose AAU Affiliation</option>
                <option value="Nike">Nike</option>
                <option value="adidas">adidas</option>
                <option value="Under Armour">Under Armour</option>
                <option value="Puma/Pro16">Puma/Pro16</option>
                <option value="New Balance/P32">New Balance/P32</option>
                <option value="Independent">Independent</option>
              </select>
              <span className={styles.error}>
                {formik.errors.aauAffilation && formik.touched.aauAffilation && formik.errors.aauAffilation}
              </span>

              <span>Coach First Name</span>
              <input
                type="text"
                name="aauCoach"
                onChange={formik.handleChange}
                value={formik.values.aauCoach}
                onBlur={formik.handleBlur}
              />
              <span className={styles.error}>
                {formik.errors.aauCoach && formik.touched.aauCoach && formik.errors.aauCoach}
              </span>

              <span>Coach Last Name</span>
              <input
                type="text"
                name="aauCoachLast"
                onChange={formik.handleChange}
                value={formik.values.aauCoachLast}
                onBlur={formik.handleBlur}
              />
              <span className={styles.error}>
                {formik.errors.aauCoachLast && formik.touched.aauCoachLast && formik.errors.aauCoachLast}
              </span>

              <span>Phone</span>
              <input
                type="text"
                name="aauCoachInfo"
                onChange={formik.handleChange}
                value={formik.values.aauCoachInfo}
                onBlur={formik.handleBlur}
              />
              <span className={styles.error}>
                {formik.errors.aauCoachInfo && formik.touched.aauCoachInfo && formik.errors.aauCoachInfo}
              </span>

              <span>Email</span>
              <input
                type="text"
                name="aauCoachEmail"
                onChange={formik.handleChange}
                value={formik.values.aauCoachEmail}
                onBlur={formik.handleBlur}
              />
              <span className={styles.error}>
                {formik.errors.aauCoachEmail && formik.touched.aauCoachEmail && formik.errors.aauCoachEmail}
              </span>
            </div>
          </div>
          <div className={styles.btns}>
            <button onClick={close}>Cancel</button>
            {collection === 'aau-suggestion' && (
              <button className={styles.btns} type="button" onClick={handleApprove}>
                Approve
              </button>
            )}
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}
