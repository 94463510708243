import React from "react";
import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// firebase
import { db } from "../../../firebase";

export default function EditModal({ data, setOpen, reloadData }) {
  const close = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      title: data.title || "",
      description: data.description || "",
      reward: data.reward || "",
      assignedEditor: data.assignedEditor || "",
      progress: data.progress || "Not Started",
    },
    validate: (values) => {
      const errors = {};
      if (!values.title) errors.title = "Bounty title is required";
      if (!values.description) errors.description = "Description is required";
      if (!values.reward) errors.reward = "Reward is required";
      //if (!values.assignedEditor) errors.assignedEditor = "Assigned editor is required";
      return errors;
    },
    onSubmit: async (values) => {
      const { title, description, reward, assignedEditor, progress } = values;

      try {
        // Update document in Firestore
        await db.collection("bounties").doc(data.id).update({
          title,
          description,
          reward,
          assignedEditor,
          progress,
          updatedAt: new Date().toISOString(),
        });

        toast.success("Bounty updated successfully!", { theme: "colored" });
        reloadData();
        close();
      } catch (error) {
        console.error("Error updating bounty: ", error);
        toast.error("Error updating bounty", { theme: "colored" });
      }
    },
  });

  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close} className={styles.closeButton}>
          <MdClose />
        </button>
        <form onSubmit={formik.handleSubmit} className="w-100">
          <div className={styles.content}>
            <h3>Edit Bounty</h3>
            <hr />
            <div className={styles.inputs}>
              {/* Bounty Title */}
              <span>Bounty Title</span>
              <input
                type="text"
                name="title"
                placeholder="Enter bounty title"
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              <span className={styles.error}>
                {formik.errors.title && formik.touched.title && formik.errors.title}
              </span>

              {/* Description */}
              <span>Description</span>
              <textarea
                name="description"
                placeholder="Enter bounty description"
                rows={4}
                onChange={formik.handleChange}
                value={formik.values.description}
              ></textarea>
              <span className={styles.error}>
                {formik.errors.description &&
                  formik.touched.description &&
                  formik.errors.description}
              </span>

              {/* Reward */}
              <span>Reward</span>
              <input
                type="text"
                name="reward"
                placeholder="Enter reward (e.g., $500)"
                onChange={formik.handleChange}
                value={formik.values.reward}
              />
              <span className={styles.error}>
                {formik.errors.reward && formik.touched.reward && formik.errors.reward}
              </span>

              {/* Assigned Editor */}
              <span>Assigned Editor</span>
              <input
                type="text"
                name="assignedEditor"
                placeholder="Enter editor name"
                onChange={formik.handleChange}
                value={formik.values.assignedEditor}
              />
              <span className={styles.error}>
                {formik.errors.assignedEditor &&
                  formik.touched.assignedEditor &&
                  formik.errors.assignedEditor}
              </span>

              {/* Progress */}
              <span>Progress</span>
              <select
                name="progress"
                onChange={formik.handleChange}
                value={formik.values.progress}
              >
                <option value="Not Started">Not Started</option>
                <option value="In Progress">In Progress</option>
                <option value="Completed">Completed</option>
              </select>
            </div>
          </div>
          <div className={styles.btns}>
            <button type="button" onClick={close}>
              Cancel
            </button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}
