import React, { useState, useEffect } from "react";
import styles from "./BountyPage.module.scss";
import Header from "../../header/header";
import { MdModeEdit, MdRestoreFromTrash } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import AddModal from "./addModal";
import EditModal from "./editModal";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { CustomSpinner } from "../../shared/Spinner";

export default function BountyPage() {
  const [loading, setLoading] = useState(false);
  const [bountyData, setBountyData] = useState([]);
  const [bountyRequestsData, setBountyRequestsData] = useState([]); // New state for bounty requests
  const [showAddModal, setShowAddModal] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [viewModalData, setViewModalData] = useState(null);
  const [descriptionModalData, setDescriptionModalData] = useState(null);

  useEffect(() => {
    fetchBounties();
    fetchBountyRequests(); // Fetch bounty requests
  }, []);

  const fetchBounties = async () => {
    setLoading(true);
    try {
      const response = await db.collection("bounties").get();
      const data = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedData = data.sort((a, b) => {
        const progressOrder = {
          "Not Started": 1,
          "In Progress": 2,
          Completed: 3,
        };

        if (progressOrder[a.progress] !== progressOrder[b.progress]) {
          return progressOrder[a.progress] - progressOrder[b.progress];
        }

        const dateA = new Date(a.createdAt || 0);
        const dateB = new Date(b.createdAt || 0);
        return dateA - dateB;
      });

      setBountyData(sortedData);
    } catch (error) {
      console.error("Error fetching bounties: ", error);
      toast.error("Error fetching bounties");
    } finally {
      setLoading(false);
    }
  };

  const fetchBountyRequests = async () => {
    try {
      const response = await db.collection("bounty-requests").get();
      const data = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBountyRequestsData(data);
    } catch (error) {
      console.error("Error fetching bounty requests: ", error);
      toast.error("Error fetching bounty requests");
    }
  };

  const handleDeleteRequest = async (id) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this request?"
    );
    if (!confirmation) return;
  
    try {
      await db.collection("bounty-requests").doc(id).delete();
      toast.success("Bounty request deleted successfully");
      fetchBountyRequests(); // Refresh the requests list
    } catch (error) {
      console.error("Error deleting bounty request: ", error);
      toast.error("Error deleting bounty request");
    }
  };
  

  

  const handleDeleteBounty = async (id) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this bounty?"
    );
    if (!confirmation) return;

    try {
      await db.collection("bounties").doc(id).delete();
      toast.success("Bounty deleted successfully");
      fetchBounties();
    } catch (error) {
      console.error("Error deleting bounties: ", error);
      toast.error("Error deleting bounty");
    }
  };

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        {/* Bounty Requests Table */}
        {bountyRequestsData.length > 0 && (
              <>
                <div className={styles.headerRow}>
                  <h4>Pending Bounty Requests</h4>
                </div>
                <div className={styles.tableContainer}>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th>Bounty</th>
                        <th>Requester</th>
                        <th>Date Requested</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bountyRequestsData
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => {
                          const dateA = new Date(
                            a.requestedAt?.seconds
                              ? a.requestedAt.seconds * 1000
                              : a.requestedAt || 0
                          );
                          const dateB = new Date(
                            b.requestedAt?.seconds
                              ? b.requestedAt.seconds * 1000
                              : b.requestedAt || 0
                          );
                          return dateB - dateA; // Newest to oldest
                        })
                        .map((request) => (
                          <tr key={request.id}>
                            <td>{request.bountyTitle || "--"}</td>
                            <td>{request.requestedBy || "Unknown"}</td>
                            <td>
                              {request.requestedAt
                                ? new Date(
                                    request.requestedAt.seconds
                                      ? request.requestedAt.seconds * 1000
                                      : request.requestedAt
                                  ).toLocaleDateString()
                                : "--"}
                            </td>
                            <td>
                            <MdRestoreFromTrash
                              className={`${styles.icon} ${styles.delete}`}
                              onClick={() => handleDeleteRequest(request.id)}
                              title="Delete Request"
                            />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <hr />
              </>
            )}



        {/* Bounties Table */}
        <div className={styles.headerRow}>
          <h4>Bounties</h4>
          <button
            className={styles.addButton}
            onClick={() => setShowAddModal(true)}
          >
            Add Bounty
          </button>
        </div>
        <p className={styles.subTitle}>
          Manage bounties by adding, editing, or deleting them
        </p>
        <hr />
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Bounty</th>
                <th>Description</th>
                <th>Reward</th>
                <th>Assigned Editor</th>
                <th>Progress</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {bountyData.map((bounty) => (
                <tr
                  key={bounty.id}
                  className={
                    bounty.progress === "Completed" ? styles.completedRow : ""
                  }
                >
                  <td>{bounty.title}</td>
                  <td>
                    {bounty.description.length > 100 ? (
                      <>
                        {bounty.description.slice(0, 100)}...
                        <span
                          className={styles.readMore}
                          onClick={() => setDescriptionModalData(bounty)}
                        >
                          Read more
                        </span>
                      </>
                    ) : (
                      bounty.description
                    )}
                  </td>
                  <td>{bounty.reward}</td>
                  <td>{bounty.assignedEditor || "Unassigned"}</td>
                  <td>{bounty.progress || "Not Started"}</td>
                  <td className={styles.actionIcons}>
                    <div>
                      <FaEye
                        onClick={() => setViewModalData(bounty)}
                        className={`${styles.icon} ${styles.view} ${
                          bounty.progress === "Completed" ? styles.completed : ""
                        }`}
                      />
                      <MdModeEdit
                        onClick={() => setEditModalData(bounty)}
                        className={`${styles.icon} ${styles.edit} ${
                          bounty.progress === "Completed" ? styles.completed : ""
                        }`}
                      />
                      <MdRestoreFromTrash
                        onClick={() => handleDeleteBounty(bounty.id)}
                        className={`${styles.icon} ${styles.delete} ${
                          bounty.progress === "Completed" ? styles.completed : ""
                        }`}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add Modal */}
      {showAddModal && (
        <AddModal setOpen={setShowAddModal} reloadData={fetchBounties} />
      )}

      {/* Edit Modal */}
      {editModalData && (
        <EditModal
          data={editModalData}
          setOpen={setEditModalData}
          reloadData={fetchBounties}
        />
      )}

      {/* View Full Details Modal */}
      {viewModalData && (
        <div className={styles.modalContainer}>
          <div className={styles.modalContent}>
            <h3>{viewModalData.title}</h3>
            <p>
              <strong>Description:</strong>
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
              {viewModalData.description}
            </p>
            <p>
              <strong>Reward:</strong> {viewModalData.reward}
            </p>
            <p>
              <strong>Assigned Editor:</strong>{" "}
              {viewModalData.assignedEditor || "Unassigned"}
            </p>
            <p>
              <strong>Progress:</strong> {viewModalData.progress}
            </p>
            <button onClick={() => setViewModalData(null)}>Close</button>
          </div>
        </div>
      )}

      {/* Read More Modal */}
      {descriptionModalData && (
        <div className={styles.modalContainer}>
          <div className={styles.modalContent}>
            <h3>Full Description</h3>
            <p style={{ whiteSpace: "pre-wrap" }}>
              {descriptionModalData.description}
            </p>
            <button onClick={() => setDescriptionModalData(null)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}
