import "react-big-calendar/lib/css/react-big-calendar.css";
import styles from "./Calendar.module.scss";
import Header from "../header/header";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import EditModal from "./editModal";
import AddEventModal from "./AddEventModal";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import "./custom.css";

const localizer = momentLocalizer(moment);

export default function CalendarHome() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState({});
  const [events, setEvents] = useState([]);
  const [weeklyEvents, setWeeklyEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "pi-calendar"));
      const eventsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        start: new Date(doc.data().startDateTime),
        end: new Date(doc.data().endDateTime),
      }));
      setEvents(eventsData);
      filterWeeklyEvents(eventsData);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const filterWeeklyEvents = (eventsData) => {
    const startOfWeek = moment().startOf("week");
    const endOfWeek = moment().endOf("week");

    const filtered = eventsData.filter((event) =>
      moment(event.start).isBetween(startOfWeek, endOfWeek, "day", "[]")
    );

    setWeeklyEvents(filtered);
  };

  useEffect(() => {
    filterWeeklyEvents(events);
  }, [events]);

  function MyEvent({ event }) {
    return (
      <>
        <span className={styles.eventBadge}></span>
        <span>{event.title}</span>
      </>
    );
  }

  const components = useMemo(() => ({
    event: MyEvent,
  }));

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.calendarContainer}>
        <div className={styles.title}>
          <h4>Calendar</h4>
          <button
            className={styles.addButton}
            onClick={() => setOpenAddModal(true)}
          >
            + Add Event
          </button>
        </div>
        <hr />
        <div className={styles.calendar}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            views={["month", "week", "day"]}
            components={components}
            onSelectEvent={(event) => {
              setOpenEditModal(true);
              setSelectedEdit(event);
            }}
          />
        </div>
      </div>

      
      <div className={styles.weeklyEventsContainer}>
      <div className={styles.title}>
        <h4>This Week's Events</h4></div>
        <table className={styles.eventTable}>
          <thead>
            <tr>
              <th>Event</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {weeklyEvents.length > 0 ? (
              weeklyEvents.map((event) => (
                <tr key={event.id}>
                  <td>{event.title}</td>
                  <td>{moment(event.start).format("MM/DD/YYYY ")}</td>
                  <td>{moment(event.end).format("MM/DD/YYYY")}</td>
                  <td>{event.location || "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No events this week.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    

      {openEditModal && (
        <EditModal
          setOpen={setOpenEditModal}
          item={selectedEdit}
          refreshEvents={fetchEvents}
        />
      )}

      {openAddModal && (
        <AddEventModal setOpen={setOpenAddModal} refreshEvents={fetchEvents} />
      )}
    </div>
  );
}
